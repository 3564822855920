.modal-overlay {
    position: fixed;
    top: var(--nav-height);
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    width: 100vw;
    height: 100vh;
    z-index: 4;
}

.add-wallet-address-modal-container {
  position: fixed;
  top: var(--nav-height);
  left: 0;
  top: 0;
  max-width: 100%;
  min-width: 333px;
  max-height: 100vh;
  display: none;
  z-index: 5;
  margin-left: 50%;
  margin-top: 50vh;
  transform: translate(-50%, -50%);
  font-size: var(--pc-fontsize);

  &.show {
    display: block;
  }

  .modal-header {
    font-size: 20px;
    justify-content: center;
    font-weight: 600;
    border-color: #a3483880;
    margin-top: 12px;
    padding: 0 12px;
  }

  .close {
    z-index: 103;
    right: 10px;
    cursor: pointer;
    position: absolute;
    color: #fff;
    font-size: 40px;
    line-height: 24px;
    color: gray;
    width: 40px;
    height: 40px;
    border: 2px solid #E6E8EC;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .modal-content {
    z-index: 1;
    width: 430px;
    max-width: 100%;
    height: auto;
    background-color: white;
    padding: 16px 18px;
    margin: auto;
    border: none;
    border-radius: 15px;
  }

  .modal-form {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin: 12px 0;
    padding: 24px 12px;

    .label.text-right {
      margin-bottom: 12px;
    }

    .input-group {
      display: flex;
      justify-content: space-between;
      // gap: 15px;

      .add-wallet-address-input {
        box-sizing: border-box;
        width: calc(50% - 6px);

        input {
          width: 100%;
        }
      }
    }

    .add-wallet-address-input {
      display: flex;
      margin-bottom: 12px;

      .label {
        min-width: 110px;
        padding-right: 12px;
      }

      input {
        flex: 1 1 auto;
        outline: none;
        border: 2px solid #E6E8EC;
        padding: 6px 12px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: var(--pc-fontsize);
      }
    }

    .add-wallet-address-btn {
      margin-top: 6px;
      background: #A28A00;
      color: white;
      padding: 0.375rem 0.75rem;
      align-self: center;
      width: 100%;
      border-radius: 40px;
      font-size: var(--pc-fontsize);
      height: 40px;

    }
  }

  .btn:hover,
  .btn.active,
  input:focus {
    transition: 0.3s;
  }

  .btn:hover svg,
  .btn.active svg {
    transition: 0.3s;
    fill: #ff6347;
  }

  .btn.active svg {
    transform: rotate(-180deg);
  }

  input:focus {
    outline: none;
  }
}

@media only screen and (max-width: 1080px) {
  .add-wallet-address-modal-container {
    font-size: var(--sp-fontsize);
    border-radius: unset;

    .modal-form {
      .add-wallet-address-input {
        flex-wrap: wrap;

        .label.text-right {
          text-align: left !important;
          margin: 12px 0;
        }

        input {
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .add-wallet-address-modal-container {
    font-size: var(--sp-fontsize);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .close {
      right: 15px;
    }

    .modal-content {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      width: 100%;
      box-sizing: border-box;
    }
  }
}

@media only screen and (max-width: 480px) {
  .add-wallet-address-modal-container {
    width: 100%;
    height: 100%;
    // min-height: 100vh;

    // display: flex;
    // justify-content: center;
    // align-items: flex-end;
    &.show {
      // position: relative;
      // display: flex;
      // align-items: flex-end;
      display: flex;
    }

    .close {
      top: unset;
      bottom: 424px;
    }

    .modal-content {
      margin: unset;
      margin-top: auto;
      border-radius: unset;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }
}
