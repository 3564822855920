@import "../../styles/helpers";

.container {
    padding-top: 20px;
    padding-bottom: 20px;
    .detailContainer {
        display: flex;
        flex-wrap: wrap;

        justify-content: space-between;
        @include m {
          flex-direction: column-reverse;
          align-items: center;
          justify-content: flex-end; } }
    @include s {
        padding-top: 0px; } }
.title {
    margin-right: auto;
    margin-bottom: 20px;
    @include body-1; }

.form {
    position: relative;
    width: 40%;
    min-width: 300px;
    // background-color: yellow
    margin: 10px 0px;
    div {
        // margin-bottom: 25px
        position: relative;
        div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between; } } }

.heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    word-break: break-all;
    font-family: Poppins;
    font-style: normal;
    h1 {
      font-weight: 500;
      // font-size: 48px
      line-height: 56px;
      overflow-wrap: break-word;
      word-wrap: break-word; }
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        /* Neutrals/4 */
        color: #777E91;
        flex: none;
        order: 1;
        flex-grow: 0; }
    @include m {
        margin-bottom: 18px; }
    @include m {
        font-size: 15px; } }

.submitt {
    border-radius: 25px;
    border: 2px solid $newyello;
    color:$newblack !important {}
    font-weight: 900 !important;
    background-color: $newyello;
    transition: 0.3s ease-in-out;
    font-size: 12px !important;
    &:hover {
        background-color: $newblack;
        color: $newyello !important;
        border: 2px solid $newblack; }

    @include m {
        margin-bottom: 13px; } }

.upload_button {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    width: 80px;
    height: 40px;
    border: 2px solid #E6E8EC;
    border-radius: 90px;
    flex: none;
    order: 1;
    flex-grow: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px; }

.uploadPic {
    width: 50%;
    height: 148px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    // background-color: blue
    div {
      margin-left: 10px;
      // background-color: red
      height: 100%;
      width: 60%;
      flex-wrap: wrap;
      display: flex;
      flex-direction: column;
      justify-content: space-around; }

    img {
        width: 30%;
        border-radius: 160px;
        margin-right: 20px; }
    button {
        width: 30%;
        border-radius: 5px;
        z-index: 2;
        margin-top: -2px;
        background-color: #3772FF;
        &:disabled, &:disabled &:hover {
            background: #044fff6e;
            cursor: not-allowed; } }
    label {
        &.disabled {
            // background: #044fff6e
            cursor: not-allowed; } }
    @include m {
        margin: 28px 0px; }
    @include s {
        width: 100%; } }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

/* Firefox */
input[type=number] {
    -moz-appearance: textfield; }
.errorMsg {
    width: 60%;
    //margin-left: auto
    color: #f00; }

.loaderBg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background: #0000002e;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center; }
.inputRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px;
    gap: 10px;
    width: 100%;
    height: 95px;
    flex: none;
    order: 0;
    flex-grow: 0;

    label {
        width: 71px;
        height: 12px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        text-transform: uppercase;
        color: #B1B5C4;
        flex: none;
        order: 0;
        flex-grow: 0;
 }        // background-color: yellow
    input {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 16px;
        // background-color: black
        gap: 10px;
        width: 100%;
        height: 48px;
        border: 2px solid #E6E8EC;
        border-radius: 12px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 1; } }
.terms {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 1px;
    width: 335px;
    // background-color: red
    // color: red
    height: 40px;
    flex: none;
    flex-grow: 0;
    input {
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      border: 3px solid #B9BABA;
      border-radius: 4px;
      flex: none;
      order: 0;
      flex-grow: 0; }
    p {
        font-family: Poppins;
        font-style: normal;
        overflow-wrap: break-word;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #B9BABA; } }

.profileCard {
    padding-left: 50px;

    @include m {
         padding-left: 12px; }

    @include s {
        padding-left: 0; }

    img {
        width: 98px;
        height: 98px;
        max-width: 264px;
        border-radius: 132px;
        object-fit: contain;

        @include m {
            width: 64px;
            height: 64px; } } }


