@import "../../../styles/helpers";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');

.container {
    padding: 0 140px;
    box-sizing: border-box;
    @include t {
        padding: 0 80px; }
    @include m {
        padding: 0 10px; }
    @include s {
        max-width: 100%; } }
.section {
    overflow: hidden;
    padding-bottom: 60px;
    background-color: #FFFFFF;
    background-image: linear-gradient(180deg, rgba(212, 222, 236, 0.3) 0%, rgba(217, 217, 217, 0) 100%);
    width: 100%;
    box-sizing: border-box;

    @include x {
        padding-bottom: 112px; }
    @include t {
        padding-top: 30px;
        padding-bottom: 40px; }
    @include m {
        padding-bottom: 64px; } }

.top {
    display: flex;
    align-items: center;
    margin-bottom: 54px;
    @include m {
        display: block;
        margin-bottom: 32px; } }

.stage {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #000;
    text-transform: capitalize;
    margin-top: 60px;

    @include m {
        font-size: 28px;
        display: flex;
        justify-content: center; } }


.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals5; }

.field {
    .dropdown {
        min-width: 256px;
        @include m {
            min-width: 100%; } } }

.box {
    margin-right: auto;
    text-align: center;
    width: 100%;
    @include m {
        margin: 0 0 16px; }
    .dropdown {
        min-width: auto;
        margin-left: -24px;
        @include m {
            display: inline-block; } } }

.wrapper {
    margin: 0 -16px;
    @include m {
        margin: 0 -4px; } }

.autoHeight {
    height: auto !important; }

.item {
    //padding: 20px 15px
    //border-radius: 25px
    background: $neutrals8;
    transition: box-shadow .2s;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0px 0px 14px 0px #00000040;
    @include m {
        width: 75vw; }
    @include t {
        width: 100%; }
    @include dark {
        background: $white; }//change here for card componenets
    &:hover {
        box-shadow: 0px 0px 14px 0px #16151540;
        // width: 260px
        // .avatar
        //     width: 165px
        //     +d
 }        //         width: 174px

    @include s {
        // margin-left: 7.5%;
        margin: auto;
        min-width: 85%;
        width: 88%;
        box-sizing: border-box; }
    img {
        width: 100%; }


    // @media only screen and (max-width: "390px")
 }    //     margin-left: 5.5%
.head {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    // padding-left: 12px
    padding-bottom: 12px;
    // border-bottom: 1px solid $neutrals6
    @include dark {
        border-color: $neutrals3; } }

.rating {
    // border: 2px solid black


    height: auto;

    padding: 4px 0px 4px 3px;
    border-radius: 50%;
    @include caption-bold-2;
    color: $neutrals8; }

.icon {
    margin-right: 4px;
    img {
        width: 14px; } }

.control {
    // border: 2px solid black
    display: flex;
    color: black;
    padding-left: 10px;
    font-size: 11px;
    p {

        text-align: center; } }

.button {
    svg {
        fill: $neutrals5;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $blue; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.body {
    text-align: center; }

.avatar {
    position: relative;
    width: 100%;
    margin: 0 auto 6px;
    transition: width .2s;
    & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px; }
    &:before {
        content: "";
        display: block;
        padding-bottom: 100%; } }

.reward {
    position: absolute;
    right: -4px;
    bottom: -4px; }

.nameandProfile {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-weight: 700;
    p {
        word-break: break-all;
        text-align: left; } }
.personalInfo {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center; }


.infoSection {
    display: flex;
    justify-content: space-between; }
.pricearea {
    display: flex;
    font-weight: 700; }

.details {
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    margin-right: 2px; }

.name {
    // border: 2px solid black
    margin-bottom: 2px;
    font-weight: 500;
    color: black; }
.nameDate {
    color: $secondary; }

.price {
    @include caption-2;
    color: $neutrals4;
    span {
        font-weight: 600;
        color: $neutrals2;
        @include dark {
            color: $neutrals6; } } }

.nodata {
    text-align: center;
    font-size: 18px;
    padding: 50px 0px; }

.creator {
    color: #828282;
    font-size: 13px;
    line-height: 14px;
    text-align: left; }
.infoCreator {
    color: #000000;
    font-size: 16px;
    line-height: 16.48px;
    text-align: left; }

.price {
    color: #000000;
    font-size: 18px;
    line-height: 18.54px;
    text-align: right; }
.priceMatic {
    color: #000000;
    font-size: 14px;
    line-height: 14.42px;
    text-align: right; }
.viewItem {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 16px;
    font-family: 'Questrial';
    background: #0E69FF;
    border-radius: 10px;
    width: 75px;
    height: 36px;
    padding: 10px 20px;
    text-transform: capitalize; }
