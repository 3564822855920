body {

    //.nav-content {
    header .header-content {

        //padding: 20px;
        //padding-top: 10px;
        box-sizing: border-box;

        .input-search {
            display: flex;
            position: relative;

            /*
            .search-btn {
                position: absolute;
                right: 0;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                //bottom: 10px;

                svg {
                    position: unset;
                    //fill: #777E90;
                    fill: var(--color-gray-white, var(--White, #FFF));
                }
            }
            */
        }

        //input {
        //    width: 100%;
        //    //margin-top: 60px;
        //    //margin-bottom: 12px;
        //    border: solid 2px;
        //    border-radius: 8px;
        //    padding: 10px 16px;
        //    //border: 2px solid var(--White, #FFF);
        //    //border-color: #E6E8EC;
        //    border-color: #FFF;
        //    background: rgba(110, 110, 110, 0.69);
        //    outline: none;
        //    //&::placeholder {
        //        //color: #777E90;
        //    //    color: var(--color-gray-white, var(--White, #FFF));
        //    //}
        //}

        .header-container-desktop input {
            &::placeholder {
                //color: #777E90;
                color: var(--color-gray-white, var(--White, #FFF));
            }
        }

        .nav-item {
            color: #777E90;
            font-size: 14px;
            padding: 12px 0;
            display: flex;
            align-items: center;
            max-width: 120px;
            justify-content: space-between;
            transition: 0.3s;
            cursor: pointer;
        }
    }

    //header {
    //    .header-container-mobile {
    //        .header-content {
    //            input {
    //                border: 2px solid var(--color-gray-500, #ADB5BD);
    //                background: rgba(110, 110, 110, 0.00);
    //            }
    //        }
    //    }
    //}

    header.light {
        //.nav-content {
        .header-content {

            //input {
            //    //background: red;
            //    border: 2px solid var(--color-gray-500, #ADB5BD);
            //    background: rgba(110, 110, 110, 0.00);
            //    &::placeholder {
            //        //color: var(--color-gray-white, var(--White, #FFF));
            //        //color: red;
            //        color: var(--color-gray-500, #ADB5BD);
            //    }
            //}

            .input-search {
                .search-btn {
                    svg {
                        fill: var(--color-gray-500);
                    }
                }
            }

            //--color-theme-primary

        }
        //.header-container-mobile {
        //    .header-content {
        //        input {
        //            //background: red;
        //            border: 2px solid var(--color-gray-500, #ADB5BD);
        //            background: rgba(110, 110, 110, 0.00);
        //            &::placeholder {
        //                //color: var(--color-gray-white, var(--White, #FFF));
        //                //color: red;
        //                color: var(--color-gray-500, #ADB5BD);
        //            }
        //        }
        //    }
        //}

    }
}
