@import "../../styles/helpers";

.auction {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include t {
        display: block; } }

.title {
    margin-right: auto;
    @include body-1; }

.link {
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: #1d1d1d;
    transition: all .2s;

    &.active {
        background: $gold;
        color: #fff; }
    &:not(:last-child) {
        margin-right: 8px; } }

.nav {
    display: flex;
    margin: 32px 0px;
    padding: 6px;
    border-radius: 4px;
    @include dark {
        border: 1px solid #ccc; }
    @include m {
        flex-wrap: wrap; }
    @include s {
        margin: 15px 0px;
        button {
            padding: 10px; } } }

.info {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    @include body-2;
    color: $neutrals4;
    a {
        text-decoration: underline;
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; }
        &:hover {
            text-decoration: none; } } }


.orderitem {
    border-radius: 4px;
    box-shadow: 0px 0px 3px #cccccc;
    border: 1px solid #cccccc;
    margin: 18px 0px;
    display: flex;
    flex-wrap: wrap;
    @include s {
        margin: 0px 0px 20px 0px; }
    .playerwrapper {
        width: 10%;
        min-width: 98px;
        height: 110px;
        @include s {
            margin: auto; }
        img {
            height: 100%; } }

    .itemdetails {
        padding-left: 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        @include m {
            font-size: 10px;
            padding-left: 1px;
            min-width: 270px;
            button, a {
                height: 30px;
                padding: 0px 10px;
                font-size: 10px; } }
        h4:first-of-type {
            min-width: 200px; }
        .itemdetailsrow {
            display: flex;
            justify-content: space-between;
            padding: 15px 10px;
            div {
                text-align: center; } }
        .itemdetailsrow:first-of-type {
            border-bottom: 2px solid #9999999a; } } }

.pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;

    margin-top: 48px;
    a {
        cursor: pointer; } }

// a
//     padding: 10px
//     border-radius: 5px
//     border: 1px solid #6c7ac9
//     color: #6c7ac9

.pagination__link {
    font-weight: bold; }

// .pagination__link__active a
//     color: #fff
//     background: #6c7ac9

.pagination__link__disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
    cursor: context-menu; }

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;

  margin-top: 48px;

  a {
    padding: 10px;
    border: 1px solid #000;
    color: #000;
    border-radius: 10px;
    cursor: pointer; } }

.pagination__link {
  font-weight: bold; }

.pagination__link__active a {
  color: #1d1d1d;
  border-color: $gold; }

.pagination__link__disabled a {
  color: #ccc;
  border: 1px solid #ccc;
  cursor: context-menu; }

.loaderContent {
    width: 100%;
    position: fixed;
    height: 100vh;
    background: #d5d5d5e8;
    z-index: 9999;
    top: 0;
    left: 0;
    // bottom: 0
    // right: 0
    display: flex;
    align-items: center;
    justify-content: center; }
