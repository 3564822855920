@import "../../styles/helpers";

.errorMsg {
    width: 60%;
    color: #f00;
    font-size: 70%; }

.aboutsection {
    @include whiteSection;
    text-align: center;
    @include x {
        padding: 20px 0; }
    @include m {
        padding: 14px 0; }

    h2 {
        //+font-inter
        //font-style: normal
        font-weight: 700;
        font-size: 58px;
        line-height: 76px;
        margin-bottom: 20px;
        text-align: center;
        color: #000000;
        @include m {
            font-size: 28px;
            line-height: 38px; } }
    p {
        //+font-inter
        //font-style: normal
        //font-weight: bolder
        //font-size: 18px
        //line-height: 37px
        text-align: center;
        color: #666666;
        margin-bottom: 20px;
        // width: 80%
        margin: auto;
        @include m {
            width: 100%;
            //font-size: 16px
 } }            //line-height: 32px

    .button {
        background: $newyello;
        border-radius: 25px;
        width: 208px;
        height: 52px;
        margin: 20px auto;
        color: $newblack;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;

        &:hover {
            background: $newblack;
            color: $newyello; } }

    .aboutImage {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: 20px 0px; }

    .container {
        box-sizing: border-box;
        padding-left: 40px;
        padding-right: 40px;
        //margin-top: 40px
        //+x
        //    margin-top: 80px
        //+m
        //    margin-top: 100px
        h4 {
 }            // color: $secondary
        h2 {
 } }            // color: $secondary

    .subhead {
        color: #000;
        width: 100%;
        margin: auto;
        font-size: 20px;
        text-align: left; } }


.greenSection {
    @include greenSection;
    color: #f5f5f5;
    // font-style: bold
    background: #000000;

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .leftContent {
            width: 60%;
            padding-right: 20px;
            @include m {
                width: 100%; } }
        .rightContent {
            width: 40%;
            @include m {
                width: 100%; }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain; } } }

    h4 {
        @include font-inter;
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 76px;
        margin-bottom: 20px;
        text-align: left;
        color: #f5f5f5;
        @include m {
            font-size: 28px;
            line-height: 38px; } }
    p {
        @include font-inter;
        font-style: normal;
        font-weight: bolder;
        font-size: 18px;
        line-height: 37px;
        text-align: left;
        margin-bottom: 20px;
        color: #666666;
        @include m {
            width: 100%;
            font-size: 16px;
            line-height: 32px; } }

    .button {
        border: 1px solid $newyello;
        border-radius: 25px;
        width: 208px;
        height: 52px;
        margin: 20px auto;
        color: $newyello;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 19px; } }


