@import "../../styles/helpers";

.card {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
    &:hover {
        .control {
            visibility: visible;
            opacity: 1; } }
    @include dark {
        // background: $neutrals8
        background: #394148; } }

.preview {
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: center;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    & > img {} }

.control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.35);
    visibility: hidden;
    border-radius: 16px;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: $neutrals8; } }

.category {
    position: absolute;
    top: 11px;
    left: 8px; }

.favorite {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background: $neutrals8;
    box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2);
    border-radius: 50%;
    svg {
        width: 20px;
        height: 20px;
        fill: $neutrals4;
        transition: fill .2s;
        transition: opacity .2s; }
    &:before {
        content: "";
        position: absolute;
        top: 51%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 18px;
        height: 18px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        opacity: 0;
        transition: opacity .2s; }
    &:hover {
        svg {
            fill: $neutrals2; } }
    &.active {
        svg {
            opacity: 0; }
        &:before {
            opacity: 1; } }
    @include dark {
        background: $neutrals2;
        &:hover {
            svg {
                fill: $neutrals8; } } } }

.button {
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%); }

.link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px 0;
    color: $neutrals2;
    @include dark {
        color: $neutrals8; } }

.body {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
.userPrice {
    display: flex;
    justify-content: space-between; }
.line {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    &:first-child {
        margin-bottom: 12px; }
    &:nth-child(2) {
        // margin-top: auto
 } }        // margin-bottom: 12px

.title {

    margin-right: auto;
    padding-top: 1px;
    flex-basis: 99%;
    overflow: scroll hidden;
    // height: 27px
    font-size: 13px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-weight: 600;
    word-wrap: break-word;
    // white-space: nowrap
    color: #1d1d1d;
    margin-bottom: 10px; }

.title::-webkit-scrollbar {
    display: none; }

.price {
    flex-shrink: 0;
    padding: 1px 4px;
    border-radius: 2px;
    // box-shadow: inset 0 0 0 1px $newyello
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: $newyello; }

.users {
    display: flex; }

.avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $neutrals8;
    @include dark {
        border-color: $neutrals2; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; }
    &:not(:first-child) {
        margin-left: -8px; } }

.counter {
    word-break: break-word;
    word-wrap: break-word;
    @include dark {
        color: #c8c8c8; } }

.foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $neutrals6;
    @include caption-2;
    color: $neutrals4;
    @include dark {
        border-color: $neutrals3; } }

.status {
    svg {
        margin: -2px 4px 0 0;
        fill: $neutrals4; }
    span {
        font-weight: 600;
        color: $neutrals3;
        @include dark {
            color: $neutrals8; } } }

.player-wrapper {
    position: relative;
    padding-top: 56.25%; }

.react-player {
    position: absolute;
    top: 0;
    left: 0; }

.indicator {
    // position: absolute
    // top: 0px
    // right: 0px
    // width: 36px
    // height: 39px

    margin-top: 5px;
    margin-bottom: 5px;
    // border: 2px solid black
    display: flex;
    justify-content: center;
    column-gap: 10px;
    z-index: 4;
    display: none;
    img {
        border-radius: 50%;
        // background-color: $newyello
        // padding: 3px
        width: 19px;
        height: 19px;
        object-fit: cover; }

    p {
        font-size: 13px; } }

.resaleBtn {
    //background: #EE1F78
    background: $color-gold;
    border-radius: 35px;
    padding: 7px 20px;
    font-weight: 500;
    color: $white;
    border: none;
    margin: auto;
    //min-width: 200px
    //max-width: 100%
    max-width: 150px;
    width: 100%;
    transition: 0.3s;
    line-height: 1.4;
    margin-top: auto;

    &:hover {
        background: $newblack; } }

.fractions {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    span {
        font-size: 16px;
        line-height: 16px;
        color: #555;
        text-transform: uppercase; }
    p {
        color: #000;
        font-size: 12px;
        font-weight: 700;
        padding: 1px 4px; } }

.thumbnailItem {
    width: calc(100% - 10px);
    aspect-ratio: 1;
    object-fit: contain; }

.itemInfor {
    margin-top: auto; }

