[class^="section"] {
    padding: 50px 0;
    @include x {
        padding: 10px; }
    @include m {
        padding-top:30px !important {} }
    @include s {
        padding-top: 10px !important; } }

.section-bg {
    background: $white;
    @include dark-common {
        background: $white; } }

.section-pb {
    padding-top: 0;
    @include d {
        padding-top: 0; }
    @include m {
        padding-top: 0; } }

.section-pt80 {
    padding-top: 20px;
    @include d {
        padding-top: 64px; }
    @include t {
        padding-top: 10px; }
    @include m {
        padding-top: 32px; } }
