@import "../../../styles/helpers";

.profileDropdownMenu {
    position: absolute;
    padding: 16px;
    margin-left: -60px;
    //margin-top: 20px
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 0px 4px);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px;
    border-radius: 24px;

    @include m {
        box-shadow: unset !important;
        filter: unset !important;
        margin-left: 0px !important;
        background: white !important;
        position: unset !important;
        padding: 0 !important; } }

//#profile-dropdown-menu
//    position: absolute
//    padding: 16px
//    margin-left: -60px
//    margin-top: 20px
//    background: rgb(255, 255, 255)
//    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 0px 4px)
//    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px
//    border-radius: 24px

//.cell
//    +s
//        padding: 7px 10px !important
//    a
//        +s
//            font-weight: 400 !important
