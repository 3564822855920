@import "../../styles/helpers";


.dropdown {
    position: relative;
    z-index: 3;
    &.active {
        z-index: 10;
        .head {
            .arrow {
                svg {
                    transform: rotate(180deg); } } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.head {
    position: relative;
    //height: 48px
    padding: 0 48px 0 16px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 500;
    //line-height: 48px
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: box-shadow .2s;
    @include dark {

        border: 1px solid $bordergrey; }
    &:hover {} }


.selection {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.arrow {
    position: absolute;
    top: 50%;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: none;
    transform: translateY(-50%);
    svg {
        fill: $neutrals4;
        transition: transform .2s; }
    @include dark {

        svg {
            fill: $neutrals8; } } }

.body {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    padding: 8px 0;
    border-radius: 12px;
    background: $neutrals8;
    box-shadow: inset 0 0 0 2px $neutrals6, 0 4px 12px rgba($neutrals2, .1);
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    @include dark {
        background: $neutrals1;
        box-shadow: inset 0 0 0 2px $neutrals3, 0 4px 12px rgba($neutrals1, .1); } }

.option {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    transition: color .2s;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:hover,
    &.selectioned {
        color: #766CCC; } }
