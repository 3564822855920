body {

    .modal-overlay {
        top: 0;
        left: 0;
        margin-left: 0;
    }

    .mobile-nav-container {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        max-width: 266px;
        background-color: white;
        //background-color: #FCFCFD;
        z-index: 5;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
        transform: translateX(100%);
        transition: .4s;
        //display: none;
    }

    .mobile-nav-container.show {
        transform: translateX(0%);
        //display: inline-block;
    }

    //.mobile-nav-item-wrap {
    //    background-color: #FCFCFD;
    //}

    .mobile-nav-content {

        padding: 20px;
        padding-top: 10px;
        box-sizing: border-box;

        .input-search {
            display: flex;
            position: relative;

            /*
            .search-btn {
              position: absolute;
              right: 0;
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              bottom: 10px;

              svg {
                position: unset;
                fill: #777E90;
              }
            }
            */
        }

        /*
        input {
            width: 100%;
            margin-top: 60px;
            margin-bottom: 12px;
            border: solid 2px;
            border-radius: 8px;
            padding: 10px 14px;
            border-color: #E6E8EC;
            &::placeholder {
              color: #777E90;;
            }
        }
        */

        .mobile-nav-item.dropdown-btn {
            margin: 10px 10px;
        }

        .mobile-nav-item {
            //color: #777E90;
            //color: $text-color-primary;
            //color: var(--text-color-primary);
            //color: var(--color-theme-primary);
            //color: rgb(0, 0, 255);
            //color: rgba(0, 0, 255, 0.75);
            //border: var(--color-theme-primary) 1px solid;
            //border: var(--color-gray-150) 1px solid;
            //outline: var(--color-gray-150) 1px solid;
            //transition: border-color 1s;
            //border-color: green;
            //background-color: var(--color-gray-100);
            //color: var(--color-gray-100);
            //color: var(--color-gray-600);
            //color: white;
            //background-color: var(--color-theme-primary);
            //border-radius: 24px;
            //font-size: 14px;
            //margin: 10px 10px;
            //margin: 8px 8px;
            //margin: 4px;
            //padding: 12px 0;
            //padding-top: 7px;
            //padding-bottom: 7px;
            //padding-left: 25px;
            //padding-right: 25px;
            //padding-top: 10px;
            //padding-bottom: 10px;
            padding: 12px 24px;
            display: flex;
            align-items: center;
            //max-width: 120px;
            width: 100%;
            justify-content: space-between;
            //transition: 0.3s;
            //transition: border-color 2s ease-out;
            //transition: border-color .75s, color .75s;
            //transition: outline-color .75s;
            cursor: pointer;
            //outline: var(--color-gray-150) 1px solid;
            //border: 1px solid #E9E9E9;
            //box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
            //box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14);
            //box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            //box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
            //background-color: white;
            //background-color: #FCFCFD;
            //background-color: var(--color-gray-100);
            //background-color: var(--color-blue-50);
            //font-family: 'DM Sans';
            font-family: 'Poppins';
            //text-shadow: inset 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
            //text-shadow: inset 0 0 20px rgba(198,28,39,0.8), 0 0 0 black;
            //text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
            //text-shadow: 0px 0px 3px rgba(0,0,0,0.5);
            //text-shadow: 0px 0px 1px rgb(0,0,0);
            //text-shadow: 0px 0px 1px rgb(0,0,0);
            //text-shadow: 1px 1px 1px rgb(0,0,0), 0px 0px 1px rgb(0,0,0);
            //text-shadow: 1px 1px 1px rgb(0,0,0), -1px -1px 1px rgb(0,0,0);
            //text-shadow: 1px 1px 0px rgb(0,0,0), -1px -1px 0px rgb(0,0,0);
            //text-shadow: 1px -1px 0px rgba(0,0,0,0.5);
            //text-shadow: 1px -1px 0px rgba(0,0,0,0.33), -1px 0px 0px rgba(0,0,0,0.33), 0px 1px 0px rgba(0,0,0,0.33);
            //text-shadow: 1px 1px 0px rgba(0,0,0,0.33), -1px -1px 0px rgba(0,0,0,0.33);

            //text-shadow: 1px -1px 0px rgba(0,0,0,0.25)
                //, -1px -1px 0px rgba(0,0,0,0.15)
                //, -1px 0px 0px rgba(0,0,0,0.15)
                //, 1px 1px 0px rgba(0,0,0,0.25)
                //, -1px 1px 0px rgba(0,0,0,0.15)
                //, 0px 1px 0px rgba(0,0,0,0.25)
                //;
            //background-clip: text;

            .dot {
                width: 15px;
                height: 15px;
            }
            &:hover {
                //border: var(--color-theme-primary) 1px solid;
                //border: var(--color-gray-500) 1px solid;
                //outline: var(--color-theme-primary) 1px solid;
                //transition: border-color .1s, color .1s;
                //color: rgb(0, 0, 255);
                color: var(--color-theme-primary);
                //transition: outline-color .1s;
                //transition: border-color 1s ease-in-out;
                //transition: border-color 2s ease-out;
                //transition: border-color .5s ease-in-out .5s;
                //transition: border-color 1s;
                //border-color: var(--color-theme-primary);
                //border-bottom: 2px solid transparent; /* <- here */
                //transition: border-bottom 1s;
            }
        }

        /*
        .mobile-nav-item:hover {
            transition: border-color 1s;
            border-color: var(--color-theme-primary);
        }
        */

        .dropdown-btn {
            background-color: unset;
            border-color: transparent;
            box-shadow: none;
          &.active {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .dropdown {

              max-height: 0;
              overflow: hidden;
              transition: 0.4s;
              //margin-left: 10px;
              margin: auto;

            &.active {
                max-height: 400px;
            }

            .mobile-nav-item {
                //font-weight: 400;
                //padding: 10px;
                //max-width: unset;
                //margin: 8px 0px;
            }

            .All {
                background: #FFF;
                border: var(--color-gray-200) 2px solid;
                &:hover {
                    border: var(--color-gray-300) 2px solid;
                }
                color: rgb(0, 0, 255);
            }

            .Mixed-Use {
                color: var(--color-gray-white, var(--color-white, #FFF));
                background: #DC7700;
                background: var(--Mixed-Use, #DC7700);
            }

            .Industrial {
                color: var(--color-gray-white, var(--color-white, #FFF));
                background: #DC7700;
                background: var(--Industrial, #DC7700);
            }

            .Hospitality {
                color: var(--color-gray-white, var(--color-white, #FFF));
                background: #DC7700;
                background: var(--Hospitality, #DC7700);
            }

            .Multi-Family {
                color: var(--color-gray-white, var(--color-white, #FFF));
                background: #DC7700;
                background: var(--Multi-Family, #DC7700);
            }

            .Office {
                color: var(--color-gray-white, var(--color-white, #FFF));
                background: #DC7700;
                background: var(--Office, #DC7700);
            }

            .Retail {
                color: var(--color-gray-white, var(--color-white, #FFF));
                background: #DC7700;
                background: var(--Retail, #DC7700);
            }
        }

        //.connect-wallet {
        //  margin-top: 12px;
        //  background-color: #a28a00;
        //}

        .connect-btn {
          margin-top: 50px;
          margin-bottom: 12px;
        }
    }
}
