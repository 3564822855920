@import "../../styles/helpers";

.section {
    padding: 18px;
    scroll-behavior: smooth;
    @include x {
        padding: 20px 0; }
    @include m {
        padding: 24px 0; } }

.orderDetailPage {
    //padding-top: 88px !important
    width: unset;

    .thumbnailImage {
        width: 100%;
        aspect-ratio: 1;
        height: auto;
        object-fit: contain; } }

.viewBtnGroup {
    display: flex;
    justify-content: center;
    align-items: center;

    .viewBtn {
        background-color: #A28A00;
        padding: 7px 15px;
        border-radius: 5px;
        font-weight: 400; } }

.bold {
  font-weight:bold!important {} }

.button {
  color: $newblack;
  border: 2px solid $newyello !important;
  background-color: $newyello;
  border-radius: 25px;
  &:hover {
    background-color: $newblack;
    color: $newyello;
    border: 2px solid $newblack !important; } }

.loaderContent {
    width: 100%;
    position: fixed;
    height: 100vh;
    background: #d5d5d5e8;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center; }

.orderList {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 30px;

    .orderListItem {
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        gap: 15px;
        background: #FCFCFD;
        border-radius: 15px;
        margin-bottom: 20px;
        cursor: pointer;

        &:hover {
            background: #F4F5F6; }

        .thumbnail {
            width: 67px;
            height: 67px;

            img {
                object-fit: contain;
                border-radius: 15px;
                //aspect-ratio: 1
                background: white; } }

        .info {
            display: flex;
            flex-directions: column;
            justify-content: center;
            .name {
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
                display: flex;
                align-items: center; }
            .price {
                font-size: 14px;
                font-weight: 500; }
            .time {
                font-size: 12px;
                color: #777E90; } }
        .viewIcon {
            margin-left: auto;
            display: flex;
            align-items: center;
            svg {
                height: 30px;
                width: 30px;
                fill: #777E90; } } } }

.orderInfor {
    color: #777E90;
    margin-bottom: 30px; }

.orderBillContainer {
    display: flex;
    justify-content: flex-end;

    .orderBill {
        max-width: 390px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 14px;

        .right {
            width: 60%; }

        .left {
            width: calc(40% - 40px);
            text-align: right; }

        .orderTotal {
            font-size: 20px;
            margin-top: 12px; } } }

.spaceBetween {
    display: flex;
    justify-content: space-between; }

.alignCenter {
    display: flex;
    align-items: center; }

.orderStatus {
    margin-right: 12px;
    //padding: 4px 8px
    padding: 8px 12px;
    border-radius: 30px;
    color: white;
    font-size: 18px;
    line-height: 20px; }

@media only screen and (max-width: 480px) {
    .orderitem {
        .itemdetails {
            justify-content: center;
            .itemdetailsrow {
                justify-content: center;
                bottom: 0;
                right: unset; }
            button {
                bottom: 0;
                right: unset; } } }

    .orderDetailPage {
        padding-top: 136px !important; } }
