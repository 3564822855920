
.container321{
font-size: 18px;
background-color: #000;
padding:0;
margin:0;
color:#fff;
}

.logo img{
width: 560px;
    margin-top: 4%;
}
.content{
font-size: 21px;
color:#fff;
text-align:center;
margin-top: 4%;
}
.content-top{
margin:2% 0 0 0;
}
.first-drop {height: 44.59px;    
	width: 301.97px;
    color: #F31B4F;
    font-size: 37.48px;
    letter-spacing: 0;
    line-height: 44.59px;
    margin-left: 48%;
    margin-top: -11%;
}

.dec-1 {
  height: 68.31px;    width: 199.13px;
    color: #FFFFFF;
    font-size: 57.52px;
    letter-spacing: 0;
    line-height: 89.31px;
    margin-left: 45%;
    margin-top: 6%;
}
sup {
font-size: 24px !important;
}
.content-bottom{
width:100%;
margin-left:10%;
float:left;
font-weight:normal;
}
.content-footer img{
    width: 137px;
	}
.content-footer{padding-right: 10%;
    margin-top: 2%;}

.policy{
text-align:center;
width:100%;
margin-top:1%;

}
.policyheading{
text-align:left;
margin-top:5%;
text-decoration:underline;
font-weight:bold;
}
.points{text-align:justify;}
 /* td{padding:12px 0;
text-align: left;
} */
.odd p {
    text-align: left;
}
