/*Responsive CSS*/

/* Dermot removed this "all" selector
* {
  margin: 0;
  padding: 0;
}
*/

ul.faq,
li.q,
li.a {
  list-style: none;
}

.justify1 {
  text-align: justify;
}

#container123 {
  /* width: 60%; */
  /* margin: 50px auto; */
  /* overflow: auto; */
  width: 100%;
  box-sizing: border-box;
  /* max-width: 1280px; */
  background: #fff;
  color: #000;
  /* margin: 40px auto; */
  padding: 80px 15%;
}

#container123 h1 {
  text-align: center;
  margin-bottom: 20px;
}

#container123 p {
  padding-bottom: 10px;
}

.tabs {
  /* float: left; */
  height: 60px;
}

.qandas {
  margin-top: 50px;
}

.title123 {
  height: 50px;
  background: #fff;
  color: #000;
  text-align: center;
  padding: 13px 36px;
  margin: 29px 14px;
  cursor: pointer;
  border-radius: 12px;
  float: left;
}

.faq a {
  color: #F31B4F;
  text-decoration: none;
}

.faq li {
  padding: 20px;
  color: #fff;
}

.faq li.q,
.faq div.q {
  background: #000;
  font-weight: bold;
  font-size: 120%;

  cursor: pointer;
}

.faq img {
  float: right;
  margin-top: 3px;
}

.faq li.a {
  display: none;
  color: #fff;
  font-size: 18px;
  width: 92%;
  text-align: justify;
}

.rotate {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

h4.faqText {
  margin-top: 12px;
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}

@media only screen and (max-width: 1340px) and (min-width: 1130px) {
  #container123 {
    width: 100%;
  }

  /* Dermot removed - conflict with title in checkout modal dialog.
    .title {
        height: 20px;
        background: #fff;
        color: #000;
        text-align: center;
        padding: 13px 26px;
        margin: 29px 10px;
        cursor: pointer;
        border-radius: 12px;
        float: left;
    }
    */
  .tabs {
    /* float: left; */
    margin: 2% 15%;
  }

  .qandas {
    width: 70%;
    margin-left: 14%;
  }
}

@media only screen and (max-width: 1129px) and (min-width: 450px) {
  .title123 {
    height: 20px;
    background: #fff;
    color: #000;
    text-align: center;
    padding: 13px 16px;
    margin: 7px 10px;
    cursor: pointer;
    border-radius: 12px;
    float: left;
    width: 70%;
  }

  .tabs {
    /* float: left; */
    margin: 2% 15%;
  }

  #container123 {
    width: 100%;
  }

  #container123>img {
    width: 100%;
  }
}

@media only screen and (max-width: 449px) {
  #container123 {
    width: 100%;
    margin: 0px auto;
    padding: 20px;
    padding-top: 120px;
  }

  .tabs {
    /* float: left;     */
    margin: 2% 15%;
    height: 230px;
  }

  #container123>img {
    width: 100%;
  }

  .title123 {
    height: 78px;
  }
}
