@import "../../styles/helpers";

.tandc {
    line-height: 40px;

    ul {
        list-style-type: disc;
        padding-left: 40px; }
    h1 {
        margin-bottom: 20px;
        margin-top: 12px; }
    h2 {
        margin-bottom: 12px;
        margin-top: 12px; }
    a {
        text-decoration: none;
        color: black; } }

.container {
    min-height: calc(100vh - 280px); }

.policyContent {
    margin-top: 80px;

    h2 {
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 0px;
        color: #23262F; }

    p {
        color: #777E90;
        font-size: 16px; } }

@media only screen and (max-width: 480px) {
    .policyContent {
        margin-top: 120px;

        h2 {
            font-size: 24px; } } }
