// common styles
@import "./reset";
@import "./helpers";
@import "./common";

// global classes
@import "./blocks/section";
//@import ./blocks/container
@import "./blocks/button";
@import "./blocks/title";
@import "./blocks/slick";
@import "./blocks/status";
@import "./blocks/web3modal.sass";

@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

.errorMsg {
    width: 60%;
    color: #f00;
    font-size: 80%;
    font-weight: 600; }

//body
html {
    //+filter-theme-primary
    --filter-theme-primary: $filterColorPrimary;
    //--filter-theme-primary: $filter-theme-primary
 }    //--bg-color: #405580

html, body {
    height: 100vh; }

body {
    margin: 0; }

#root {
    height: 100vh; }

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.outer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    background-color: #E9E9E9;
    height: 100vh;
    .form-control {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 16px;
        gap: 10px;
        height: 48px;
        border: 2px solid #E6E8EC;
        border-radius: 12px;
        &:focus {
          box-shadow: none; } }
    h3 {
        text-align: center;
        margin: 0;
        line-height: 1;
        padding-bottom: 20px; }
    h1 {
        text-align: center;
        margin: 0;
        line-height: 1;
        padding-bottom: 20px; }
    h2 {
        text-align: center;
        margin: 0;
        line-height: 1;
        padding-bottom: 20px;
        font-size: 30px;
        //font-family: Montserrat
        font-family: Rubik, sans-serif;
        color: #2b2b2b; }
    h3 {
        text-align: center;
        margin: 0;
        line-height: 1;
        padding-bottom: 20px;
        font-size: 20px;
        //font-family: Montserrat
        font-family: Rubik, sans-serif;
        color: #2b2b2b; } }

.inner {
    //width: 450px
    width: 100%;
    max-width: 600px;
    margin: auto;
    background: #ffffff;
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all 0.3s;
    h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 131%;
        color: #000000; } }

div.form-group label {
    color: #B1B5C3; }

.login-wrapper label {
    margin-bottom: 10px;
    display: inline-block; }


.outer.ResetPassword .inner {
    max-width: 550px; }

.filter-body-text-color {
    filter: invert(10%) sepia(15%) saturate(616%) hue-rotate(169deg) brightness(91%) contrast(88%); }

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell:first-child img
//    filter: invert(10%) sepia(15%) saturate(616%) hue-rotate(169deg) brightness(91%) contrast(88%)

/*
 * Fixes for anomalies
 */
.swal2-styled.swal2-default-outline {
    &:focus {
        box-shadow: none !important; } }

.gm-style iframe + div {
    border:none!important {} }


.sketchfab-embed-wrapper {
    width: 75%;
    height: 90vh;
    margin: auto;
    padding-top: 5vh;
    iframe {
        width: 100%;
        height: 100%; } }

::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    //color: #D9D9D9
    color: var(--color-gray-500);
    opacity: 1; }

hr {
    height: 1px;
    border: none;
    background-color: #E6E8EC;
    padding: 0px; }

body {
    &>iframe {
        display: none!important; } }

* {
    //outline: var(--outline-x)
    //outline: red 0px solid
    outline: $outline;
 }    //box-sizing: border-box

//body
//    main
//        padding-top: 80px
//        padding-bottom: 80px

body > div#root {
    display: flex;
    align-items: normal;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: stretch;
    height: 100vh;
 }    //overflow-x: hidden

body main {
    //padding-top: 84px
    //padding-top: $paddingTop
    padding-top: $main-padding-top;
    //padding-bottom: $paddingTop
    //font-size: $desktop-font-size
    flex: 1;
 }    //z-index: 1

body main section {
    //padding-top: 40px
    //padding: 40px 50px
 }    //padding: 75px 0px

body main.home {
    padding-top: 0px; }

main.home section h2 {
    padding: 50px 0px 50px 0px;
    margin: 0;
    margin: auto;
    text-align: center;
    font-family: "Roboto Serif";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: var(--color-gray-700); }

main.home section h3 {
    padding: 50px 0px 50px 0px;
    margin: 0;
    margin: auto;
    text-align: center;
    font-family: "Roboto Serif";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: var(--color-gray-700); }

//body main section.form > div
//    padding-top: 34px

.gold {
    color: var(--color-gold); }

.gold-font {
    color: var(--color-gold); }

.gold-background {
    background-color: var(--color-gold);
    color: #fff; }

.flex {
    display: flex; }

.table {
    display: table; }

.row {
    display: table-row; }

.cell {
    display: table-cell; }

span.nowrap {
    white-space: nowrap; }

h2, h3 {
    font-family: 'Roboto Serif';
    color: var(--color-gray-700, #495057);
    //text-align: center
    //font-family: 'Poppins'
    //font-style: normal
    //font-weight: 500
    //font-size: 40px
    //line-height: 60px
    //text-align: center
    //color: #000000
    //padding: 0px
    //margin: 0
    //margin-top: 10px
    //margin-bottom: 10px
    //font-size: 48px
    //font-style: normal
    //font-weight: 600
 }    //line-height: 120%

section.dark h2 {
    color: #fff; }

.flex-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap; }

//input[type="search"]::placeholder
//.filter-section div.search input::placeholder
//    color: #353945

.top-section {
    //width: 100%
    //max-width: $max-width
    //height: 600px
    left: 0px;
    top: 0px;
    //background-color: #151513
    //background-image: url(../assets/images/backgroundHero.png)
    //background-image: url(../assets/images/top-section-background.png)
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin: auto; }

main.edit-profile section.form {
    padding-top: 30px; }

main.home section.video-section {
    background: #000; }

main.home section.top-section div.Hero {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; }

.profile-box {
    padding: 2px;
    border: 2px solid #E6E8EC;
    border-radius: 18px;
    color: #fff;
    //font-family: 'DM Sans'
    font-style: normal;
    font-weight: 700;
    //font-size: 14px
 }    //line-height: 26px

.light {
    .profile-box {
        border: 2px solid var(--color-theme-primary); } }
//        color: #23262F
//        color: #5D4F00

.profile-box .table.button span {
    color: #FFD600;
    //color: var(--color-gray-800)
    //color: var(--color-theme-primary)
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px; }

.profile-box .table.button span.currency {
    padding-right: 10px; }

.light .profile-box .table.button span.currency {
    color: var(--color-theme-primary); }

.light .profile-box .table.button span {
    //color: #5D4F00
    //color: var(--color-theme-primary)
    color: var(--color-gray-900); }

.profile-box  .profile-image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: top;
        margin-right: 10px; }

.light .profile-box {
        color: #5D4F00; }


/*
 * profile-photo-box
 */
div.profile-photo-box {
    display: table;
    width: 100%; }

section.dark .card-container div.profile-photo-box {
    color: #fff; }

div.profile-photo-box div.title {}
//.mobile-user-header-container div.title
//  //+font-poppins
//  font-weight: 500
//  font-size: 18px
//  //font-size: 16px
//  //font-family: 'Poppins'
//  font-style: normal
//  line-height: 24px
//  //line-height: 24px
//  color: #23262F
//  //letter-spacing: -1px
//  //padding-left: 15px
//  //padding-bottom: 15px
//  //padding: 0px 0px 0px 0px

//#profile-dropdown-menu div.profile-photo-box div.title
//.mobile-user-header-container div.title
//    text-align: left

.profile-dropdown-menu .profile-photo-box .table .title {
    padding: 10px 0px 0px 0px;
    text-transform: capitalize;
    color: var(--color-theme-primary);
    font-size: 24px;
    font-weight: 600;
    word-wrap: break-word; }

//#profile-dropdown-menu .profile-photo-box .table .subtitle
//    word-wrap: break-word

div.profile-photo-box div.subtitle {}
//.mobile-user-header-container div.subtitle
//  font-size: 12px
//  line-height: 20px
//  font-family: 'Poppins'
//  font-style: normal
//  font-weight: 500
//  line-height: 16px
//  color: #23262F
//  padding: 5px 0px

//#profile-dropdown-menu div.profile-photo-box div.subtitle
//.mobile-user-header-container div.subtitle
.profile-photo-box div.subtitle {
    //--font-family: 'Poppins'
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: #777E91;
    word-wrap: break-word; }

div.profile-photo-box div.description {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #777E91; }

section.profile-main div.flex-container div.profile-badge div.profile-photo-box div.description {
    display: block;
    text-align: center;
    padding: 10px 0px; }

.profile-menu .table .row.heading .cell:first-child {
    width: 24px;
    text-align: center;
 }    //vertical-align: bottom

.profile-menu .table .row.heading .cell:first-child img {
    //width: 20px
    //height: 20px
    width: 30px;
    height: 30px;
    margin: auto; }

.search-box {
    padding: 10px 12px 10px 16px;
    gap: 18px;
    padding: 2px;
    border: 2px solid #E6E8EC;
    border-radius: 8px;
    display: flex; }

.search-box input {
    background-color: transparent;
    padding: 4px;
    margin: 0;
    border: 0;
    outline: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #23262F;
    outline: none; }

//.notifications
//    display: flex
//    flex-direction: row
//    flex-wrap: wrap
//    justify-content: space-between
//    align-items: center

header > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    //padding: 20px 0px
 }    //justify-content: space-around

//header .header-right nav > div
//    padding: 3px 10px
//    display: table

header .header-right nav div.cell a {
    padding: 3px 10px; }

//header .header-right nav div.cell a, .mobile-nav-container .mobile-nav-content nav button.mobile-nav-item
header .header-right nav div.cell a, .mobile-nav-container .mobile-nav-content nav button.primary-inverse {
    border-radius: 4px;
    //padding: 3px 10px
    //border: var(--color-theme-primary) 2px solid
    border: transparent 2px solid;
    color: var(--color-theme-primary);
    //background-color: var(--color-blue-25-A)
    background-color: var(--color-theme-primary);
    //opacity: 50%
    color: #fff; }

//header .header-right nav div.cell a:hover, .mobile-nav-container .mobile-nav-content nav button.primary-inverse:hover
//  //border: transparent 2px solid
//    border: var(--color-theme-primary) 2px solid
//  //background-color: var(--color-theme-primary)
//    background-color: var(--color-blue-25-A)
//  //color: #fff
//    color: var(--color-theme-primary)

header.dark .header-right nav div.cell a {
    background-color: rgba(0, 0, 0, 0.15);
    border: white 2px solid;
    border-radius: 3px; }

header .header-right nav > div div.cell {
    padding: 0px 8px; }

header .header-container-mobile {
    display: none; }

header .header-content.header-right {
    //padding: 30px
    //padding: 30px 30px 30px 0px
    padding: 30px 0px 30px 0px; }

header .header-content.header-right .mobile-nav {
    display: flex;
    justify-content: flex-end; }

header .header-content.header-right .mobile-nav-button {
    padding-right: 30px; }

header .header-content.header-right .mobile-nav-button img {
    width: 32px;
    height: 32px; }

header {
    //font-family: 'Poppins'
    //font-family: 'Source Sans Pro'
    @include font-source-sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    //line-height: 24px
    //text-align: center
    //max-width: var(--site-width)
    //border-bottom: #05257D 2px solid
 }    //border-bottom: transparent 2px solid

header div.header-container-desktop {
    margin: auto;
 }    //max-width: 1500px

header .logo-container {
    //padding: 30px
    //padding: 30px 0px 30px 30px
    padding: 20px 0px; }

header.dark {
    color: #fff;
 }    //background-color: #0A122A

body header.light {
    background-color: #fff; }

main.home header.light {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14);
    //border-bottom: #A28A00 2px solid
    //border-bottom: 2px solid var(--color-gray-400, #CED4DA)
 }    //background-color: #fff

//body > header.light svg-icon
//body main header.dark .svg-icon
header.dark .svg-icon {
    filter: invert(100%) sepia(3%) saturate(12%) hue-rotate(103deg) brightness(105%) contrast(105%); }

//body main header.light .svg-icon
header.light .svg-icon {
    filter: invert(23%) sepia(85%) saturate(1915%) hue-rotate(203deg) brightness(96%) contrast(100%); }

body header.light {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14);
    //border-bottom: 2px solid var(--color-gray-400, #CED4DA)
 }    //border-bottom: #A28A00 2px solid

body.search header.light {
    box-shadow: none;
    border-bottom: 1px solid var(--theme-border, #DEE2E6); }

header button {
    //padding: 10px
    white-space: nowrap;
    //background: rgba(255, 255, 255, 0.26)
    border-radius: 4px;
    vertical-align: text-bottom;
    //font-family: "DM Sans"
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px; }

header button.connect-wallet {
    //padding: 10px
    //padding: 6px 12px
    //padding: 8px 16px
    padding: 10px;
    //border: #fff 2px solid
    border: var(--color-theme-success) 2px solid;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    //line-height: 150%
    //line-height: 24px
    //color: var(--theme-primary, #00F)
 }    //font-family: Source Sans 3

header.dark button.connect-wallet {
    border: #fff 2px solid; }

//.header-container-desktop .input-box
//    background-color: red

.header-container-desktop .input-box input {
    width: 100%;
    max-width: 200px;
    //background-color: orange
 }    //display: none

//header.dark .header-container-desktop button
//  //background: rgba(255, 255, 255, 0.26)
//  //border-radius: 4px
//  //border: transparent 2px solid
//    background: #E7F1FF
//    color: rgba(0, 0, 255, 1)

header.light button {
    //border: #ddd 2px solid
    //color: #333
    //background: #E7F1FF
    //color: rgba(0, 0, 255, 1)
    //background-color: var(--color-gray-500)
 }    //color: #fff

//header.light .input-search
//    border: 2px solid var(--color-gray-500, #ADB5BD)
//    background: rgba(110, 110, 110, 0.00)

//header nav
//    outline: black 3px solid

header nav {
 }    //padding: 0px 10px

header nav > div {
    //margin: auto 10px
    //margin: 10px 10px
 }    //padding: 30px 30px

//header .mobile-nav-container nav
.mobile-nav-container nav {
    //outline: blue 3px solid
    //flex-flow: column wrap
    flex-direction: column; }

//header.dark a
//    color: #fff

//header.light a
//  //color: unset
//  //color: #777E91
//  //color: var(--color-theme-primary)
//  //font-size: 18px
//  //font-style: normal
//  //font-weight: 400
//  //line-height: 20px

header.light nav div {
    color: #777E91; }

header .header-container-desktop a {
    color: var(--color-theme-primary); }

header.dark .header-container-desktop a {
    color: #fff; }

header .header-container-mobile a {
    color: var(--color-theme-primary);
    //color: var(--color-gray-600)
    //&:hover
 }    //    color: var(--color-theme-primary)

//header .header-container-mobile a
//  //color: var(--color-theme-primary)

.clear-button {
    background-color: #fff;
    border: #E6E8EC 2px solid;
    border-radius: 30px;
    height: 30px;
    width: 30px;
    text-align: center;
    vertical-align: middle; }

.clear-button.input-box-button {
    height: 25px;
    width: 30px;
    margin-top: auto;
    margin-bottom: auto; }

section.form label {
    //font-weight: 700
    //font-size: 12px
    //line-height: 12px
    //text-transform: uppercase
    //color: #B1B5C4
    //padding-top: 20px
    display: block;
    color: var(--text-color-primary);
    //font-family: 'Source Sans 3'
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150% /* 24px */;
    text-align: left; }

.edit-profile section.form label {
    padding-bottom: 8px;
    color: var(--color-gray-600);
    font-size: 16px;
    font-weight: 400; }

section.form label a {
    color: var(--text-color-primary, #212529);
    text-decoration: underline; }

.input-box {
    //padding: 3px
    padding: 8px 12px;
    //padding: 8px 12px
    //border: 2px solid #E6E8EC
    //border-radius: 8px
    border-radius: 5px;
    //border: 2px solid var(--color-gray-400)
    background: #FFF;
    background: var(--color-gray-50); }

.editable .input-box {
    background: var(--color-gray-100);
    //box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1), inset 0px 0px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1); }

//main.edit-profile div.input-box
//    padding: 3px

//main.edit-profile .editable div.input-box
.editable div.input-box {
    border: 1px solid var(--color-gray-300); }

.editable div.input-box {
    //border: 0px
    //border: 2px solid transparent
    border: 1px solid var(--color-gray-300); }

//main.edit-profile .editable div.error-msg
.editable div.error-msg {
    //width: 60%
    color: #f00;
    //font-size: .9em
    font-size: 15px; }

.editble a:hover {
    text-decoration: underline; }

header a:hover {
    text-decoration: none; }

main.edit-profile .editable div.input-box select {
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0px 6px 0px;
    border: 0; }

//main.edit-profile.user-profile div.input-box
//main.edit-profile div.input-box
.editable div.input-box {
    //border: 0px
    //border: 2px solid transparent
    //border: 2px solid var(--color-gray-400)
    //border: 1px solid transparent
    border: 1px solid var(--color-gray-400);
    //padding: 8px 0px
    //padding: 16px 12px
    //padding: 12px 8px
    //outline: 2px solid var(--color-gray-400);
    color: var(--color-gray-800, #343A40);
    //font-family: 'Source Sans 3'
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    //margin: 7px
    //line-height: 150%
    line-height: 30px; }

//main.edit-profile.user-profile div.input-box
//    outline: 2px solid var(--color-gray-400);

.input-box input, .input-box select {
    background-color: transparent; }

.input-box input {
    //+font-poppins
    @include font-source-sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    outline: none; }

//main.edit-profile .input-box input, main.edit-profile .input-box select
//    //background-color: transparent
//    //padding: 7px
//    //padding: 8px 12px
//    margin: 0
//    border: 0
//    outline: none
//    //font-family: 'Poppins'
//    +font-poppins
//    font-style: normal
//    font-weight: 500
//    font-size: 16px
//    line-height: 24px
//    display: flex
//    align-items: center
//    //color: #23262F
//    color: var(--color-gray-700)
//    outline: none

.input-box select {
    width: 100%; }

.select-box > div {
    display: block;
    padding: 8px 0px;
    padding-left: 0px; }

.max-width {
    //border: blue 3px solid
    //max-width: 1280px
    //max-width: $maxWidth
 }    //margin: auto

main section div.max-width {
    max-width: 1080px;
    max-width: var(--site-width);
    margin: auto; }

.input-button-filled {
    display: inline-flex;
    background-color: #B9BABA;
    border-radius: 20px;
    height: 40px;
    width: 40px;
    margin: auto;
    text-align: center;
    vertical-align: middle; }


/*
 * .flex-container
 */

.flex-container fieldset {
    padding: 0px;
    margin: 0;
    border: 0; }

.flex-container fieldset legend {
    padding: 20px 0px;
    margin: 0; }

.flex-container fieldset {
    //div.flex.collections
    gap: 10px; }

.flex-container fieldset {
    //div.flex.collections
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #23262F; }

.flex-container fieldset {
    //div.flex.collections
    //    > div
    padding: 25px;
    background: #F4F5F6;
    border-radius: 16px;
    flex-basis: 25%; }

.flex-container  fieldset {
    //div.flex.collections
    //    div.circle
    margin-bottom: 10px;
    display: flex; }

//.inputBox
//    padding: 3px
//    border: 2px solid #E6E8EC
//    border-radius: 8px


.search-box {
    display: flex; }

.select-box > div {
    display: block;
    padding: 8px 0px; }

//.form .input-box
//    border-radius: 12px

.form a.gold {
    color: var(--color-gold);
    text-decoration: none; }

.clear-button {
    background-color: #fff;
    border: #E6E8EC 2px solid;
    border-radius: 30px;
    height: 30px;
    width: 30px;
    text-align: center;
    vertical-align: middle;
 }    //color: #23262F

//button.rounded-clear
//    width: 388px
//    height: 40px
//    width: 100%

.edit-profile .form .heading {
    padding-left: 60px;
    padding-top: 20px; }

.edit-profile .form .subtitle {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 30px;
    line-height: 1.5em; }

.edit-profile .form .subtitle p {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 1.5em; }

.profile-top button.rounded-clear {
    border: 0;
    border: 2px solid #777E91;
    padding: 12px 16px; }

.profile-top button.rounded-clear div.table div.cell:first-child {
    vertical-align: middle;
    padding-left: 10px; }

.profile-top button.rounded-clear div.table div.cell:nth-child(2) {
    padding-left: 12px; }

div.button-wrapper {
    //margin: 40px auto
    //margin: 40px 0
    margin: 40px 0 0 0;
 }    //margin-top: 40px auto


/*
 * Map
 */
#google-maps-wrapper {
    display: block;
    height: calc(100vh - 200px);
    height: 100vh;
    flex-basis: 50%; }

/*
 * .filter-section
 */

#filter-section-wrapper {
    border-bottom: 1px solid var(--theme-border, #DEE2E6); }

.filter-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 30px;
    row-gap: 30px;
    justify-content: space-evenly; }

//.filter-section > div
//    flex-grow: 1
//  //max-width: 300px

//.filter-section div.search
.filter-section div.input-search {
    border-radius: 5px;
    padding: 8px 16px;
    //margin-bottom: 19px
    //border: 2px solid #CED4DA
    border: 2px solid var(--color-gray-400); }

//.filter-section div#select-property-type
//  //margin-top: 19px

//input[type="search"]
//.filter-section div.search input
.filter-section div.input-search input {
    //font-family: 'Poppins'
    font-style: normal;
    //font-weight: 400
    font-size: 32px;
    //color: #353945
    //padding: 10px 0px
    //padding: 8px 16px
    border: 0;
    outline: none;
    //color: var(--color-gray-700, #495057)
    //font-family: 'Source Sans 3'
    font-size: 20px;
    font-weight: 300;
    //line-height: 32px
    line-height: 150%;
    //letter-spacing: -0.01em
    //border-radius: 5px
    //border: 2px solid var(--color-gray-500, #CED4DA)
    //background: var(--color-gray-white, #FFF)
    width: 100%; }

.filter-section div.title {
    color: var(--color-gray-900, #212529);
    //font-family: 'Source Sans 3'
    //font-family: $sourceSansFont
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 150% /* 37.5px */;
    vertical-align: middle;
    white-space: nowrap;
    text-align: left; }

.filter-section div input[type="search"] {
    background-color: none; }

//.filter-section div input[type="search"]::placeholder
.filter-section div.search input::placeholder {
    color: var(--color-gray-700, #495057);
    //font-family: 'Source Sans 3'
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 150% /* 30px */; }

.filter-section div label {
    color: var(--body-text-body-color, #212529);
    //font-family: 'Source Sans 3'
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; }

.filter-section div .select .badge {
    //color: var(--color-gray-white, var(--color-white, #FFF))
    //color: var(--color-theme-primary)
    //color: var(--color-gray-600)
    color: #fff;
    //border: var(--color-theme-primary) 1px solid
    //border: var(--color-gray-600) 1px solid
    //border: #fff 2px solid
    //outline: var(--color-gray-300) 1px solid
    text-align: center;
    //font-family: 'Source Sans 3'
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    min-width: 22px;
    //height: 28px
    padding: 6px;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    flex-shrink: 0;
    //border-radius: 999px
    //border-radius: 50%
    border-radius: 18px;
    //background: var(--indigo-700, #3D0A91)
    //background: transparent
    //background: #fff
    background: var(--color-theme-primary);
    margin-left: 8px;
    margin-right: 8px;
    //box-shadow: inset -1px -1px 3px 2px rgba(0, 0, 0, 0.25)
    //box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.25)
 }    //box-shadow: inset 0px 0px 5px 3px rgba(255, 255, 255, 0.75)

.filter-section div .select {
    border-radius: 5px;
    //border: 1px solid var(--color-gray-600, #6C757D)
    border: 1px solid var(--color-theme-primary);
    background: var(--color-gray-100, #F8F9FA);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14);
    display: flex;
    //width: 200px
    width: 100%;
    //padding: 8px 50px 8px 16px
    //padding-top: 8px
    //padding-bottom: 8px
    padding: 8px 0px;
    line-height: 32px;
    //justify-content: center
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    //color: var(--color-gray-600, #6C757D)
    color: var(--color-theme-primary);
    //font-family: 'Source Sans 3'
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    //line-height: 150% /* 30px */
    -webkit-appearance: none;
    -moz-appearance: none;
    //background-image: url(../assets/images/icons/icon-search-input-down-arrow.svg)
    //background-repeat: no-repeat
    //background-position-x: calc(100% - 16px)
    //background-position-y: 12px
    outline: none; }

.filter-section div select::placeholder {
    border-radius: 5px;
    border: 1px solid var(--color-gray-600, #6C757D);
    background: var(--color-gray-100, #F8F9FA);
    display: flex;
    width: 200px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; }

.filter-section div select option {
    color: var(--color-gray-600, #6C757D);
    //font-family: 'Source Sans 3'
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150% /* 30px */; }


/*
 * .search-results
 */

.search-results {
    background-color: rgb(248, 249, 250);
    //padding-left: 15px
 }    //padding-right: 15px

.search-results .assets-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 10px;
    padding-left: 15px;
    padding-right: 15px; }

.search-results .assets-container .asset-container {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-radius: 10px;
    border: 1px solid var(--color-gray-400, #CED4DA);
    background: var(--color-white, #FFF);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14);
    //max-width: 435px
    flex-basis: 335px; }

.profile-main .search-results .assets-container .asset-container {
    max-width: 300px; }

//.search-results .assets-container .asset-container div.item div.item-top
//    padding: 8px 16px

.search-results .assets-container .asset-container div.item div.item-top button {
    border-radius: 4px;
    //font-family: 'Source Sans 3'
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    //line-height: 100%
    padding: 2px 8px;
    vertical-align: top; }

.search-results .assets-container h4.no-results {
    width: 335px;
    text-transform: uppercase;
    //font-family: 'DM Sans'
    //font-family: Montserrat
    //font-family: 'Roboto Slab'
    font-family: 'Roboto'; }

.search-results .assets-container .asset-container .preview-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 335px;
    height: 180px; }

//.search-results .assets-container .asset-container .closing-date
.assets-container .asset-container .small-details {
    //display: inline-block
    color: #000;
    color: var(--color-gray-700);
    text-align: center;
    //font-family: 'Source Sans 3'
    font-size: 14px;
    font-style: normal;
    font-weight: 400; }

.assets-container .asset-container .small-details span.label {
    font-weight: 600;
    //line-height: 150%
    //line-height: 24px
    //padding-bottom: 10px
    font-size: 15px; }

//.search-results .assets-container .asset-container .closing-date span
.assets-container .asset-container .closing-date {}

.search-results .assets-container .asset-container .thumbnail {
    width: 100%;
    max-width: 435px;
    min-width: 300px;
    max-height: 150px;
    //border-radius: inherit
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }

//.profile-main .search-results .assets-container .asset-container .thumbnail
//    max-height: 169px

.search-results .filter-buttons {
    text-align: end;
    padding-bottom: 50px; }

.search-results .filter-buttons button {
    padding: 6px 12px;
    border-radius: 16px;
    color: #fff;
    color: #777E91;
    background-color: var(--color-gold);
    background-color: transparent;
    margin: auto 2px; }

.search-results .filter-buttons button:hover {
    outline: var(--color-gold) 2px solid; }

.search-results .filter-buttons button.on:hover {
    outline: none; }

.search-results .filter-buttons button.on {
    padding: 6px 12px;
    border-radius: 16px;
    color: #777E91;
    color: #fff;
    background-color: var(--color-gold); }

.card-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    background-color: var(--color-pricipal); }
//          --color-pricipal: #{$color-negro}

.card-container > div {
    background: #FFFFFF;
    //border-radius: 10px
    margin-bottom: 30px;
    padding: 15px;
    padding: 0px;
    padding-top: 5px;
 }    //box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)

.profile .card-container > div {
    width: auto; }

.home .card-container > div {
    max-width: 300px;
    width: 100%; }

.card-container > div.first {
    margin-top: 0px; }

.home section {
    background-image: linear-gradient(180deg, rgba(212, 222, 236, 0.3) 0%, rgba(217, 217, 217, 0) 100%); }

section.dark {
    //background: linear-gradient(180deg, #000000 0%, #2C466B 100%)
    //background: linear-gradient(180deg, #000000 0%, #2C466B 100%)
    background-image: url(../assets/images/backgrounds/city-sky.jpg);
    opacity: 0.95; }

.dark .item {
    //background: #000
    background: #263143;
 }    //border-radius: 10px

.dark .item {
    color: #fff; }

//main section div.container
//    padding-top: 88px!important
//    padding-top: 18px !important

main section div.max-width, footer div.max-width {
    //max-width: 1080px
    //max-width: var(--site-width)
    max-width: $maxWidth;
    margin: auto; }

main section.top-section div.max-width {
    max-width: $headerMaxWidth;
    margin: auto; }

main.home section.top-section div.Hero {
    //max-width: 1130px
    //max-width: $maxWidth
    max-width: var(--site-width); }

main section div.max-width h1 {
    //font-family: 'Poppins'
    //font-style: normal
    //font-weight: 500
    //font-size: 48px
    //line-height: 56px
    //letter-spacing: -0.02em
    //color: #23262F
    color: var(--color-gray-700, #495057);
    //text-align: center
    font-family: 'Roboto Serif';
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    //line-height: 120%
    line-height: 50px; }

main section div.max-width h3 {
    //font-family: 'Poppins'
    font-family: 'Roboto Serif';
    font-style: normal;
    font-weight: 600;
    //font-size: 16px
    //line-height: 24px
    //display: flex
    //align-items: center
    //color: #777E91
    color: var(--color-gray-700); }

main section div.max-width div.subtitle {
    //font-family: 'Poppins'
    //font-style: normal
    //font-weight: 400
    //font-size: 16px
    line-height: 22px;
    //color: #777E91
    color: var(--color-gray-600);
 }    //padding: 10px 0px 20px 0px

main section div.max-width div.subtitle a {
    //color: #000
    color: var(--color-theme-primary); }

main section div.max-width div.subtitle a:hover {
    text-decoration: underline; }

//main section div.max-width p
//  //color: var(--color-gray-600)
//  //font-size: .8em

section.dark .card-container > div {
    background: #000; }

//.card-container > div img
//    padding: 0px 50px

/*
 * .searchResults
 */
.filter-buttons {
    text-align: end;
    padding-bottom: 50px; }

.filter-buttons button {
    padding: 6px 12px;
    border-radius: 16px;
    color: #fff;
    color: #777E91;
    background-color: var(--color-gold);
    background-color: transparent;
    margin: auto 2px;
    &:hover {
        outline: $color-gold 2px solid;
 } }        //outline: var(--color-gold) 2px solid

.filter-buttons button.on:hover {
    outline: none; }

.filter-futtons button.on {
    padding: 6px 12px;
    border-radius: 16px;
    color: #777E91;
    color: #fff;
    background-color: var(--color-gold); }

#categories-dropdown-menu {
    position: absolute;
    margin-top: 10px;
    background-color: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 0px 4px);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px;
    display: block;
    z-index: 9;
    border: 1px solid var(--components-list-group-border, rgba(0, 0, 0, 0.13));
    border-radius: 4px; }


#notifications-dropdown-menu div.flex div button.gold.rounded {
    padding: 12px 16px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FCFCFD; }

//.search-results .card-container img
//    padding: 0px 18px

//section.my-assets .card-container > div img.item
//    padding: 0px 5px

/*
 * .watchInfoBox
 */

div.watch-info-box {
    display: table;
    //width: 100%
 }    //margin: 10px auto

section.dark .card-container div.watch-info-box {
    color: #fff; }

div.watch-info-box > div {
    display: table-row; }

div.watch-info-box > div > div {
    display: table-cell;
    padding: 2px 0px; }

div.watch-info-box > div > div.watch-title {
    text-align: left;
    color: var(--color-gray-800, #343A40);
    font-family: 'Roboto Slab';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

div.watch-info-box > div > div.watch-subtitle {
    text-align: left;
    color: var(--color-gray-600);
    //font-family: 'Source Sans 3'
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    line-height: 21px; }


div.item-description {
    color: var(--color-gray-800);
    //font-family: 'Source Sans 3'
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    //overflow-y: scroll
    //max-height: 240px
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }

/*
 * priceBox
 */

div.price-box {
    display: table;
    width: 100%;
    margin: 10px auto; }

section.dark .card-container div.price-box {
    color: #fff; }

//div.price-box > div
//    display: table-row

//<<<<<<< HEAD
div.price-box > div > div {
    display: table-cell;
    padding: 3px 0px; }
//=======
//div.price-box > div > div
//    display: table-cell
//  //padding: 3px 0px
//>>>>>>> 9445f88df4c32dfa434e732f50475008e11d55d1

div.price-box div.price {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px; }

//div.price-box > div > div.price-dollars
//    color: #000

section.dark .card-container div.price-box div.price-dollars {
    color: #fff; }

div.price-box div.price-crypto {
    color: #FFD600; }

.button-container {
    display: flex;
    justify-content: space-evenly; }

//.button-container .button-wrapper button
//    padding: 20px 50px

//.button-wrapper
//    margin: 40px auto

main.edit-profile section.form .nav {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between */
    /* align-content: space-around */
    gap: 10px;
    //margin: 15px 0px
    margin: 0px;
    //padding: 6px 25px
    padding: 0px;
    border-radius: 4px;
    @include dark {
        border: 1px solid #ccc; }
    @include m {
        flex-wrap: wrap; }
    @include s {
        margin: 15px 0px;
        button {
            padding: 10px; } } }


.become-investor {
    //border-width: 2px solid
    //border-color: rgba(0, 0, 255, 1)
    //border: rgba(0, 0, 255, 1) 2px solid
    border: var(--color-theme-primary) 2px solid;
    //border: rgba(255, 255, 255, 1) 2px solid

    //background-color: var(--color-gray-200)
    //background-color: var(--color-gray-150)
    //background-color: rgba(255, 255, 255, 1)
    background-color: var(--color-theme-primary);

    //color: rgba(0, 0, 255, 1)
    //color: var(--color-gray-700)
    //color: var(--color-gray-600)
    //color: var(--color-theme-primary)
    color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    //font-family: 'Source Sans 3'
    //font-family: 'DM Sans'
    font-family: 'Poppins';
    padding: 16px;
    //background-color: blue
    font-weight: 500;
    font-size: 18px;
    width: 100%;
    white-space: nowrap; }

header.dark button.become-investor {
    border: #fff 2px solid; }

//.become-investor:hover
//    border: rgba(255, 255, 255, 1) 2px solid
//    background-color: var(--color-theme-primary)
//  //background-color: var(--color-gray-200)
//  //background-color: var(--color-gray-150)
//  //color: rgba(0, 0, 255, 1)
//  //color: var(--color-gray-700)
//  //color: var(--color-gray-600)
//    color: rgba(255, 255, 255, 1)

.header-container-mobile .become-investor {
    border: var(--color-theme-primary) 2px solid; }

.header-container-desktop .become-investor {
    //padding: 8px 16px
    padding: 6px 14px;
    padding: 10px; }

button.primary-inverse {
    padding: 16px;
    //border-width: 2px solid
    //border-color: rgba(0, 0, 255, 1)
    //border: rgba(0, 0, 255, 1) 2px solid
    //background-color: rgba(255, 255, 255, 1)
    //background-color: var(--color-gray-200)
    background-color: var(--color-gray-150);
    border-bottom: var(--color-gray-400) 1px solid;
    //border-top: var(--color-gray-200) 1px solid
    //border-top: rgba(0,0,0,.05) 1px solid
    border-top: transparent 1px solid;
    //color: rgba(0, 0, 255, 1)
    //color: var(--color-gray-700)
    color: var(--color-gray-600);
    border-radius: 5px;
    font-family: 'Source Sans 3';
    padding: 16px;
    //background-color: blue
    //font-family: 'DM Sans'
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 20px; }

button.primary-inverse:hover {
    background-color: var(--color-gray-200);
    //color: var(--color-gray-800)
    //color: rgba(0, 0, 255, 1)
    color: #025BC9;
    color: var(--color-theme-primary); }

button.primary-inverse img {
    //filter: invert(51%) sepia(6%) saturate(675%) hue-rotate(167deg) brightness(86%) contrast(88%)
    filter: var(--filter-gray-150); }

button.primary-inverse:hover img {
    //filter: invert(19%) sepia(23%) saturate(301%) hue-rotate(169deg) brightness(94%) contrast(89%);
    //filter: var(--filter-gray-200)
    filter: var(--filter-theme-primary); }

button.primary {
    padding: 16px;
    //background-color: rgba(0, 0, 255, 1)
    background-color: var(--color-theme-primary);
    border-radius: 5px;
    color: #FFF;
    font-family: 'Source Sans 3';
    padding: 16px;
    //background-color: blue
    //font-family: 'DM Sans'
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 20px; }

button.theme-primary {
    display: inline-flex;
    //padding: 8px 16px
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background-color: var(--color-theme-primary);
    color: var(--color-gray-white);
    //font-family: 'Source Sans 3'
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
 }    //line-height: 150%

button.theme-primary:disabled {
    background-color: var(--color-gray-300);
    color: var(--color-gray-white);
    cursor: not-allowed; }

button.theme-cancel {
    display: inline-flex;
    //padding: 8px 16px
    //padding: 4px 8px
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    //background: var(--color-theme-cancel)
    background: var(--color-gray-200);
    //border: var(--color-theme-cancel) 2px solid
    color: var(--color-dark-gray);
    //font-family: 'Source Sans 3'
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
 }    //line-height: 150%

button.theme-success {
    display: inline-flex;
    //padding: 8px 16px
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: var(--color-theme-success, #198754);
    color: var(--color-gray-white, var(--color-white, #FFF));
    //font-family: 'Source Sans 3'
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
 }    //line-height: 150%

button.theme-alert {
    display: inline-flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: var(--color-theme-alert);
    color: var(--color-white, #FFF);
    //font-family: 'Source Sans 3'
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
 }    //line-height: 150%

button.theme-warning {
    display: inline-flex;
    //padding: 8px 16px
    //padding: 4px 8px
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: var(--color-theme-warning, #198754);
    color: var(--color-gray-white, var(--color-white, #FFF));
    //font-family: 'Source Sans 3'
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
 }    //line-height: 150%

//section.form button.medium
button.medium {
    //padding: 8px 4px
    padding: 6px 12px;
    //font-family: 'DM Sans'
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 15px;
 }    //background: none

button.small {
    //padding: 8px 4px
    padding: 4px 8px;
    padding: 3px 10px;
    //font-family: 'DM Sans'
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 13px; }

button.large {
    padding: 16px;
    //font-family: 'DM Sans'
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 20px;
    padding-left: 32px;
    padding-right: 32px; }

button.link {
    padding: 16px;
    //font-family: 'DM Sans'
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 20px;
    text-decoration: underline; }

button.notification {
    background-color: var(--color-gray-100);
    border: var(--color-gray-150) 2px solid;
    border-radius: 8px; }

button.notification .notifications-button {
    padding: 16px;
    display: flex; }

//button.notification .notifications-button .MuiCheckbox-root input.checkbox
//button.notification .notifications-button .MuiCheckbox-root input[type="checkbox"]
//button.notification .notifications-button .MuiCheckbox-root input[type=checkbox]
button.notification .notifications-button .MuiCheckbox-root svg {
    fill: var(--color-gray-500); }
//    border: var(--color-gray-500) 2px solid
//  //border: red 2px solid

button.notification:hover {
    background-color: var(--color-gray-150);
    border: var(--color-gray-200) 2px solid; }

//button.large, input[type="cancel"].large
button.extra-large {
    padding: 20px 50px;
    //color: var(--color-gold)
    background-color: white;
    color: black;
    //width: 100%
    //height: 50px
    //border-radius: 90px
    //border: none
    //border: 2px solid #E6E8EC
    color: #000;
    //margin-top: 10px
    margin: 5px auto;
    font-size: 16px;
    font-weight: bold;
    //&:hover
    //  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
    //  //box-shadow: 0px 0px 3px rgba(33, 33, 33, 0.33)
    //  //box-shadow: 0px 0px 3px rgba(0, 0, 0, 1.0)
 }    //  //border: 2px solid #aaa

button.clear {
    //border: 2px solid red
    border: 2px solid transparent;
    border: 2px solid #E6E8EC;
    //border-radius: 90px
    //border: 2px solid #E6E8EC
    margin: 5px auto;
    &:hover {
        //border: 2px solid #ccc
        border: 2px solid #E6E8EC; } }


button.full {
    width: 100%; }

//button.gold.large
//    padding: 20px 50px
//    background-color: var(--color-gold)

button.gold {
    background-color: var(--color-gold);
    color: #fff;
    border: 2px solid var(--color-gold);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF; }

button.white {
    //background-color: var(--color-white)
    background-color: #fff; }

button.grey {
    background-color: var(--color-grey);
    color: #fff; }

button.rounded {
    border-radius: 5px;
    //margin: 5px auto
    margin: auto;
 }    //padding: 15px 25px

//body .mobile-nav-content .mobile-nav-item.product-category.rounded
body .mobile-nav-content a.product-category {
    padding: 0px 10px;
 }    //margin: 0px 5px

//.mobile-nav-item.Hospitality
body .mobile-nav-content .mobile-nav-item.product-category.rounded:hover {
    color: white; }

button.circular {
    border-radius: 40px;
    //padding: 15px 25px
 }    //margin: 5px auto

//button.gilded
.gilded {
    border: 2px solid #E6E8EC;
    &:hover {
        //border: 2px solid #E6E8EC
        border: 2px solid var(--color-gold); } }

button.rounded-clear {
    padding: 12px 16px;
    gap: 12px;
    border: 2px solid var(--color-gray-500);
    border-radius: 90px;
    background-color: transparent;
    padding: 5px 10px;
    vertical-align: middle;
 }    //color: #23262F

button.rounded.clear {
    color: #23262F; }

button.stretch {
    width: 100%; }

button.light {
    background-color: #fff;
    color: #23262F; }

input[type="submit"] {
    //font-family: 'DM Sans'
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px; }

input[type="checkbox"] {
    border: 1px solid #B9BABA;
    border-radius: 4px; }


//main.edit-profile section.form button
//    padding: 6px 12px
//    border-radius: 14px
//    //background: none
//    font-size: 14px
//    //line-height: 1.14286
//    //color: #1d1d1d
//    font-family: 'DM Sans'
//    font-style: normal
//    font-weight: 700
//    line-height: 16px
//    //color: #777E91

main.edit-profile section.form button.primary {
    background-color: var(--color-theme-primary);
    color: white;
    padding: 16px;
    border-radius: 5px;
    font-family: 'Source Sans 3';
    font-weight: 400;
    font-size: 20px; }

main.edit-profile section.form button.active {
    background-color: var(--color-theme-primary);
    color: white; }

button.primary:disabled {
    background: #777E91;
    cursor: not-allowed; }

section.form button {
    //font-family: 'DM Sans'
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    //font-size: 14px
    //line-height: 16px
    text-align: center;
 }    //color: #23262F

button.light {
    background-color: #fff;
    color: #23262F; }

button, input[type="submit"] {
    font-family: 'Poppins';
    border: 0px;
    //border-radius: 5px
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    //line-height: 15px
    text-align: center;
 }    //color: #FFFFFF

button.curved, input[type="submit"].curved {
    border-radius: 5px; }

.home button.gold {
    padding: 20px 50px;
    background-color: var(--color-gold); }

//.property-information button.theme-primary
button.theme-primary {
    border-radius: 4px;
    //border: 1px solid var(--color-theme-primary, #00F)
    background: var(--color-theme-primary, #00F);
    color: var(--color-gray-white, var(--color-white, #FFF)); }

.category {
    //background: #FCFCFD
    //background: #fff
    //border-radius: 16px
    flex-basis: 100%;
 }    //padding: 5px 10px

.category:hover {
    background-color: #F4F5F6; }

//.category div.table
//    padding: 5px 0px

.category div.subcategories {
    display: none;
    border-radius: 16px;
    margin-left: 100px;
    //padding: 5px 100px 5px 10px
    padding: 5px 10px; }

.category:hover div.subcategories {
    display: block;
    //background-color: red
    //border-radius: 16px
    //width: 100%
    //padding: 5px 100px 5px 10px
 }    //margin-left: 100px

.category div.subcategories div.subcategory {
    padding: 5px 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    //color: #5D6475
    color: #141416;
 }    //padding-right: 100px

.category div.subcategories div.table div.cell {
    padding: 8px 16px;
    border-radius: 16px;
    padding-right: 100px; }

.category div.subcategories div.table div.cell:hover {
    background-color: #F4F5F6; }

.category div.subcategories div.subcategory:hover {
    background-color: #F4F5F6; }

.category div.table div.row div.cell {
    white-space: nowrap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #141416;
    text-align: left;
    border-bottom: 1px solid var(--components-list-group-border, rgba(0, 0, 0, 0.13)); }

.category:last-child div.table div.row div.cell {
    border-bottom: 0px; }

.category div.table div.row div.cell.dot {
    //padding: 8px 16px 0px 16px
    padding: 8px 16px;
    //padding-left: 100px
    width: 1%; }

.category div.table div.row div.cell.checkbox {
    //padding: 8px 16px 0px 16px
    padding: 8px 16px;
    //padding: 0px 10px
    width: 1%; }

.dark nav > div .category div.cell {
    color: inherit;
    color: #5D6475;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px; }

span.dot {
    height: 12px;
    width: 12px;
    //background-color: var(--color-gold)
    border-radius: 50%;
    display: inline-block; }


/*
 * Profile Menu
 */
//.profile-menu
//    border: orange 3px solid

.profile-menu div.table {
    margin-bottom: 8px; }

.profile-menu div.table div.row {
    cursor: pointer; }

//.profile-menu div.table div.row:hover div.cell
//    background-color: #ddd

.profile-menu div.table div.row div.cell:nth-child(2) {
    white-space: nowrap;
    color: #777E90;
    //font-family: 'Poppins'
    font-style: normal;
    //font-weight: 400
    //font-weight: 400
    //font-size: 12px
    //line-height: 16px
    //color: #777E91
    color: var(--color-gray-600);
    //padding: 10px
    //padding: 8px 10px
    padding: 3px 4px 3px 8px; }

//.profile-menu div.table div.row.heading div.cell:nth-child(2)
//    padding-bottom: 6px

.mobile-nav-content nav div a {
    text-decoration: none;
    display: grid; }

.mobile-nav-content nav div a:hover {
    text-decoration: none; }

.mobile-nav-content nav div a button {
    font-size: 18px;
    font-weight: 500;
    padding: 8px 16px; }

.mobile-nav-content nav div a button.large {
    padding: 16px; }

.mobile-nav-content nav div a button.become-investor {
    font-size: 18px; }

.mobile-nav-content nav div button.connect-wallet {
    padding: 18px;
    font-size: 18px;
    border-radius: 5px; }

.mobile-nav-content nav div.search-btn svg {
    fill: var(--color-gray-600); }

.mobile-nav-content nav div.input-box.input-search:hover, .mobile-nav-content nav div.input-box.input-search:focus-within {
    border-color: var(--color-theme-primary); }

.mobile-nav-content nav div.input-box.input-search:hover svg {
    fill: var(--color-theme-primary); }

.mobile-nav-content nav div.input-box.input-search:focus-within svg {
    fill: var(--color-theme-primary); }

.profile-menu div.table div.row div.cell:nth-child(2) a button {
    //color: #777E91
    //color: var(--color-gray-600)
    //transition: color .75s
    //font-weight: unset
    font-size: 16px;
    font-weight: 500;
    //padding: 4px 16px
    //padding: 6px 12px
    padding: 8px 14px;
    //border-radius: 4px
    //background-color: var(--color-gray-150)
    //border-color: var(--color-gray-150)
    //border: 1px solid var(--color-gray-150)
 }    //transition: 0.3s;

.profile-menu div.table div.row div.cell:nth-child(2) a:hover button {
    //color: var(--color-gray-800)
    //color: #025BC9
    //color: var(--color-theme-primary)
    //transition: border-color .1s, color .1s
    //transition: color .1s, background-color .5s;
    //color: rgb(0, 0, 255)
    //background-color: var(--color-gray-200)
    //background-color: var(--color-blue-50)
    //border: 1px solid var(--color-theme-primary)
 }    //box-shadow: 0 0 10px 0px rgba(128, 128, 128, 0.236);

.profile-menu div.table div.row.heading div.cell:nth-child(2) {
    //font-family: 'DM Sans'
    font-weight: 700;
    //font-size: 14px
 }    //line-height: 16px

.profile-menu div.table div.row div.cell:nth-child(1) {
    vertical-align: middle; }
.profile-menu div.table div.row div.cell:nth-child(3) {
    vertical-align: middle; }

//#profile-dropdown-menu
//.header-container-desktop #profile-dropdown-menu
#profile-dropdown-menu {
    position: fixed;
    padding: 12px 8px;
    //margin-left: -60px
    //margin-top: 20px
    //background: rgb(255, 255, 255)
    background-color: #fff;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    //filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 0px 4px)
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    //box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px
    background: #FCFCFD;
    border-radius: 24px;
    display: none;
    color: darkgrey;
    z-index: 5;
    margin-top: 20px;
    animation: showNotification .4s forwards;
    &:before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 32px;
        height: 13px;
        transform: translateX(-50%);
        background: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A") no-repeat 100% 50% / 100% auto;
        @include m {
            display: none; } } }

@keyframes showNotification {
    0% {
        opacity: 0;
        transform: scale(.9); }
    100% {
        opacity: 1;
        transform: scale(1); } }

//.header-container-desktop #profile-dropdown-menu
#profile-dropdown-menu {
    margin-top: 20px; }

.header-container-desktop .header-right nav div {
    margin: auto 10px auto 0px; }

//.header-container-desktop .header-right nav div:first-child
//    margin-left: 20px

//.header-container-desktop .header-right nav div:last-child
//    margin-right: 20px

// Test Media query in SASS:
//@media (orientation: portrait)
//    .container
//        max-width: 576px


/*
 * Responsiveness
 */

main.item-main section#item-section {
    padding-top: 50px;
    padding-bottom: 50px; }

main.item-main > section .item-container > div {
    padding-top: 25px;
    padding-bottom: 25px; }

main.item-main section.property-information ol {
    list-style-type: circle; }

main.item-main section.property-information ol {
    color: var(--color-gray-700);
    //font-size: 18px
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    //padding: 10px 0px
 }    //margin: 0px
//    line-height: 150%

main.item-main section.property-information ol li {
    //margin: 5px auto
    margin-top: 5px;
    margin-bottom: 5px; }

main.item-main section.property-information ol li.ql-indent-1 {
    margin-left: 40px; }

main.home section.top-section {
    padding: 0px;
    //background: #0A122A
    color: #fff; }

//main.home section.top-section div.hero-item
//    flex-basis: 50%

//main > section
//        padding: 0px 50px


@media (orientation: portrait) {
    //main.home
    //    section.top-section
    //        padding: 0px 50px
    main {
        > section {
            padding: 0px 50px; } }

    .short-name {
        display: block; }

    .long-name {
        display: none; } }

@media (orientation: landscape) {

    .short-name {
        display: none; }

    .long-name {
        display: block; } }


// This CSS layout works
//body
//    > header
//        > div
//            padding: 0px 50px

main.item-main section.profile-top {
    padding-left: 0px;
    padding-right: 0px; }

/*
 * User Profile
 */
section.profile-top {
    //background-image: url(../assets/images/profile-background.png)
    background-image: url(../assets/images/houses/5202-charnwood-crescen-1-6362046-1681919487889.jpeg);
    height: 300px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }

section.profile-top div.button-box {
    max-width: var(--site-width);
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: flex-end;
    align-items: flex-end; }

section.profile-main div.flex-container {
    gap: 40px;
    justify-content: space-around;
    align-items: stretch;
    align-content: stretch;
    margin-top: 40px; }

main.item-main div.flex-container div.profile-badge .table .cell.title {
    font-weight: 600;
    //font-size: 1.5em
    font-size: 24px;
    display: block;
    text-align: center;
    text-transform: capitalize;
    padding: 10px 0px 0px;
    text-transform: capitalize;
    color: var(--color-theme-primary); }

main.item-main div.flex-container div.profile-badge .table .cell.subtitle {
    max-width: 200px;
    white-space: wrap;
    word-wrap: break-word;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 15px;
    margin: auto;
    padding: 10px 0px;
    padding-top: 5px;
    color: var(--color-gray-600);
    font-eight: 400; }

section.profile-main div.flex-container div.profile-badge {
    flex-basis: 20%;
    margin-right: 0px;
    margin-bottom: 50px; }

section.profile-main div.flex-container div.profile-badge > div {
    flex-basis: 18%;
    margin-right: 0px;
    margin-top: -160px;
    background-color: #FCFCFD;
    border-radius: 10px;
    padding: 30px;
    border: 1px solid #E6E8EC;
    border-radius: 16px; }

section.profile-main div.flex-container div.profile-badge img.avatar {
    width: 160px;
    border-radius: 80px;
    margin: auto;
    text-align: center;
    vertical-align: middle; }

section.profile-main div.flex-container div.profile-badge div.butons-box {
    vertical-align: middle;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    justify-content: center;
    gap: 10px; }

section.profile-main div.flex-container div.profile-badge div.social-box {
    vertical-align: middle;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    justify-content: center;
    gap: 30px;
    padding-top: 40px;
    padding-bottom:  40px; }

section.profile-main div.flex-container div.profile-badge div.social-box img {
    vertical-align: middle;
    width: 25px;
    height: 25px; }

section.profile-main div.flex-container div.profile-badge hr {
    height: 1px;
    border: none;
    background-color: #E6E8EC;
    margin-top: 0px;
    padding: 0px;
    margin-bottom: 40px; }

section.profile-main div.profile-badge .description {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #777E91;
    text-align: center; }


// SweetAlert Pop-up Modal Dialog - "custom-popup"

.swal2-container.swal2-center > .swal2-popup.custom-popup {
    padding: 33px;
    border-radius: 15px;
    background-color: #FCFCFD;
    max-width: 450px; }

.swal2-container.swal2-center > .swal2-popup.custom-popup.custom-popup-iframe {
    padding: 0px; }

.swal2-container.swal2-center > .swal2-popup.custom-popup.custom-popup-iframe .swal2-html-container {
    margin: 0; }

.swal2-container.swal2-center > .swal2-popup.custom-popup h2.swal2-title {
    text-align: left;
    padding: 0px; }

// SweetAlert Pop-up Modal Dialog - "custom-popup-checkout"

.swal2-container.swal2-center > .swal2-popup.custom-popup-checkout .swal2-html-container {
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #23262F; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-checkout .swal2-html-container strong {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #23262F; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-checkout .swal2-html-container div.table {
    padding-top: 32px; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-checkout .swal2-html-container div.table > div:first-child div {
    border-bottom: #E6E8EC 1px solid; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-checkout .swal2-html-container div.table > div.row div.cell {
    padding: 12px 0px; }



// SweetAlert Pop-up Modal Dialog - "custom-popup-add-email"

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-email {
    width: auto; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-email .swal2-actions {
    width: 100%;
    margin: 16px auto; }

//.swal2-container.swal2-center > .swal2-popup.custom-popup-add-email .swal2-actions button
//    padding: 16px 0px

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-email .swal2-actions button:hover {
    background: var(--color-gold) !important; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-email h2.swal2-title {
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #23262F;
    max-width: 350px;
    //padding: .8em 1em 0
    padding: 0px;
    padding-bottom: 16px; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-email label.swal2-input-label {
    text-align: left;
    margin-left: 0px; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-email input.swal2-input {
    border: 2px solid #E6E8EC;
    border-radius: 12px;
    outline: none;
    margin: 8px 0; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-email input.swal2-input:focus {
    box-shadow: none; }


// SweetAlert Pop-up Modal Dialog - "custom-popup-select-wallet"

.swal2-container.swal2-center > .swal2-popup.custom-popup-select-wallet {
    padding: 33px;
    border-radius: 15px;
    background-color: #FCFCFD; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-select-wallet .swal2-html-container {
    padding-top: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #23262F; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-select-wallet .swal2-actions {
    //background-color: blue
    //padding-left: 15px
    //padding-right: 15px
    &:first-child {
        border: 2px solid red; } }

.swal2-container.swal2-center > .swal2-popup.custom-popup-select-wallet .swal2-actions button {
    padding: 33px;
    background-color: #fff;
    border: 1px solid #E9E9E9;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-left: 15px;
    margin-right: 15px;
    &:hover {
        background-color: transparent;
        background: transparent; } }

//.swal2-container.swal2-center > .swal2-popup.custom-popup .swal2-actions button:hover
//    background: transparent !important

//.swal2-container.swal2-center > .swal2-popup.custom-popup-select-wallet .swal2-actions button.swal2-confirm
.swal2-container.swal2-center > .swal2-popup.custom-popup-select-wallet .swal2-actions button.swal2-cancel {
    //background-image: url(../assets/images/icons/metamask.svg)
    //background-position: center
    //background-repeat: no-repeat
    background: transparent url(../assets/images/icons/metamask-icon.svg) no-repeat center;
    &:hover {
        //background-color: transparent !important
        //background: transparent !important
        background: transparent url(../assets/images/icons/metamask-icon.svg) no-repeat center !important; } }

//.swal2-container.swal2-center > .swal2-popup.custom-popup .swal2-actions button.swal2-confirm:hover
//    background-color: transparent
//    background: transparent

.swal2-container.swal2-center > .swal2-popup.custom-popup-select-wallet .swal2-actions button.swal2-deny {
    background: transparent url(../assets/images/icons/wallet-connect.svg) no-repeat center;
    //background-image: url(../assets/images/icons/wallet-connect.svg)
    //background-position: center
    //background-repeat: no-repeat
    &:hover {
        background: transparent url(../assets/images/icons/wallet-connect.svg) no-repeat center !important; } }

//.swal2-container.swal2-center > .swal2-popup.custom-popup-select-wallet .swal2-actions button.swal2-cancel
.swal2-container.swal2-center > .swal2-popup.custom-popup-select-wallet .swal2-actions button.swal2-confirm {
    //background-image: url(../assets/images/icons/bitsplit.svg)
    //background-image: url(../assets/images/logos/bitsplit-dark.svg)
    //background-image: url(/images/logos/realsplit-logo-dark-277x69.jpg)
    background-image: url(../assets/images/logos/realsplit-logo-dark.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 200px; }

//.swal2-container.swal2-center > .swal2-popup.custom-popup-select-wallet .swal2-actions button.swal2-cancel:hover
.swal2-container.swal2-center > .swal2-popup.custom-popup-select-wallet .swal2-actions button.swal2-confirm:hover {
    //background: transparent url(../assets/images/icons/bitsplit.svg) no-repeat center !important
    background: transparent url(../assets/images/logos/realsplit-logo-dark.svg) no-repeat center !important;
 }    //background: transparent url(../assets/images/icons/metamask-icon.svg) no-repeat center !important

// custom-popup-add-address
.swal2-container.swal2-center > .swal2-popup.custom-popup h2.swal2-title {
    padding-bottom: 16px;
    color: #23262F; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address h2.swal2-title {
    padding-bottom: 16px; }

//.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address label.swal2-input-label
//    margin: 16px auto

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-actions {
    width: 100%;
    margin: 16px auto; }

//.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-actions button:hover
.swal2-container.swal2-center > .swal2-popup.custom-popup .swal2-actions button:hover {
    background: var(--color-gold) !important; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container {
    margin: 0; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .title {
    margin: 16px auto; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.flex {
    flex-wrap: wrap; }

//.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.table > div.row > div.cell
.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.flex > div {
    padding-top: 6px;
    padding-bottom: 6px;
    flex-basis: 100%;
    margin-left: 6px; }

//.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.table > div.row > div.cell div.input
.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.flex > div div.input {
    //width: 100%
    border-radius: 12px;
    //border: 1px solid blue
    //background-color: red
    background-color: #fff;
    //margin: 6px 0px
    //padding: 6px 0px
    padding-left: 12px;
    padding-right: 12px;
    border: 2px solid #E6E8EC; }

.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.flex > div input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important; }

//.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.flex > div input:-internal-autofill-selected
//.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.flex > div input:focus
//.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.flex > div input:-internal-autofill-selected:focus
//    box-shadow: none
//    outline: none
//    background-color: transparent

//.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.flex > div input:-webkit-autofill:hover
//    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important
//    box-shadow: none

//.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.flex > div input:-webkit-autofill:focus
//    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important
//    box-shadow: none

//.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.table > div.row > div.cell input
.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-html-container div.flex > div input {
    outline: none;
    width: 100%;
    //padding: 12px 16px
    //padding: 12px 0px
    //margin: 0px 10px
    margin: 9px 0px;
    //margin-left: 6px
    font-size: 1.5rem;
    //gap: 10px
    //width: 384px
    //height: 48px
    //border: 2px solid #E6E8EC
    //border-radius: 12px
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #777E91; }

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm.primary {
    background-color: var(--color-theme-primary);
    padding: 20px 30px;
    font-size: 18px;
    line-height: 24px; }

div:where(.swal2-container) h2:where(.swal2-title) {
    font-size: 18px; }

//.swal2-container.swal2-center > .swal2-popup.custom-popup-add-address .swal2-actions .swal2-cancel:hover
.swal2-container.swal2-center > .swal2-popup.custom-popup .swal2-actions .swal2-cancel:hover {
    //background: var(--color-gold) !important
    background: #fff !important; }

// Overide :hover on the modal dialog confirm button
.swal2-container .swal2-confirm:hover, .swal2-container .swal2-cancel:hover {
    background: var(--color-theme-primary) !important; }


// edit-profile

//main.edit-profile section.form div.max-width form.max-width div.heading img
//main.edit-profile section.form div.max-width div.heading img
//    +filter-theme-primary

main.edit-profile .flex-container .flex.address-input-container .flex-item {
    flex-basis: 100%;
    padding-top: 6px;
    padding-bottom: 6px; }

main.edit-profile .flex-container .flex.address-input-container .flex-item .input {
    border-radius: 12px;
    background-color: #fff;
    padding-left: 12px;
    padding-right: 12px;
    border: 2px solid #E6E8EC; }

main.edit-profile .flex-container .flex.address-input-container .flex-item .input input[type="text"] {
    border: 0px;
    outline: none;
    width: 100%;
    margin: 9px 0px;
    font-size: 1.5rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #777E91; }

//main.edit-profile section.form
//  //padding-bottom: 250px
//  //padding: 0px 0px 250px 0px
//  //padding-bottom: 250px

//main.edit-profile section.form
//    padding-top: 40px

//main.edit-profile section.form .max-width svg
main.edit-profile section.form .max-width .cart-page .container .cart-main .left .cart-item .delete-item-btn {
    fill: var(--color-gray-500); }

main.edit-profile section.form .max-width .icon-button {
    //filter: var(--filter-theme-primary)
    fill: var(--color-theme-primary); }

main.edit-profile section.form .max-width div.card-container {
    display: flex;
    gap: 20px; }

main.edit-profile section.form .max-width div.card-container > div {
    flex-basis: 45%; }

main.edit-profile section.form .button-container {
    text-align: center;
    margin-right: auto;
    margin-left: 0px;
    align-items: center;
    justify-content: left; }


//main.edit-profile section.form .form-heading
main section.form .form-heading {
    //padding: 10px
    //background: #EBEBEB
    //border-radius: 6px
    //font-family: 'Poppins'
    //font-style: normal
    //font-weight: 700
    //font-size: 14px
    //line-height: 12px
    //text-transform: uppercase
    //color: #000000
    //margin: auto
    //text-align: center
    color: var(--text-color-primary, #212529);
    font-family: Roboto Slab;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; }

main section.form .form-heading button {
    color: var(--color-gray-white, var(--color-white, #FFF));
    //font-family: 'Source Sans 3'
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    //line-height: 20px
    vertical-align: bottom; }

main section.form .form-heading button div.text {
    //vertical-align: bottom
    line-height: 160%;
 }    //vertical-align: middle

main.edit-profile {
    background-color: var(--color-gray-100, #F8F9FA); }

//main.edit-profile section.form form .form-section
//main.edit-profile section.form form .flex-container
main.edit-profile section.form .flex-container {
    background-color: #fff;
    margin: 60px 0px;
    //padding: 60px 109px
    //padding: $padding-top $padding-left
    //flex-direction: column
    //align-items: flex-end
    //gap: 30px
    gap: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 110px -60px rgba(132, 128, 191, 0.40); }


main.edit-profile section.form .table-container {
    //font-family: Lato
    //border-collapse: collapse
    width: 100%;
    text-align: left;
    border-radius: 20px;
 }    //overflow: scroll

main.edit-profile section.form .table-container table {
    border-radius: 20px;
    border-spacing: 0 20px;
    border-collapse: unset; }

main.edit-profile section.form .max-width div.card-container > div {
    //background-color: var(--color-gray-50)
    //background-color: var(--color-gray-50)
    //background-color: #F4F5F6;
    background-color: transparent;
    //margin: 8px
    border-radius: 15px;
    //min-width: 400px
    flex-grow: 1;
    //max-width: 650px
    width: 100%;
    cursor: pointer;
    padding: 0px 10px; }

main.edit-profile section.form .max-width div.card-container > div:hover {
    //outline: var(--color-theme-primary) 1px solid
    //outline: var(--color-gray-300) 3px solid
    //box-shadow: 0px 0px 3px var(--color-gray-600)
    //box-shadow: 0px 3px 31px 0px rgba(0, 0, 0, 0.15)
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14); }

main.edit-profile section.form .max-width div.card-container > div div.table {
    //background-color: var(--color-gray-50)
    //background-color: var(--color-gray-150)
    //background-color: #F4F5F6;
    background-color: transparent;
    width: 100%;
    //width: auto
    //padding: 0px 10px
    //padding-right: 30px
    margin-top: 20px;
    margin-bottom: 20px; }

//main.edit-profile section.form .table-container table th, main.edit-profile section.form .max-width div.card-container > div div.label
//main.edit-profile section.form .table-container table th, main.edit-profile section.form .max-width div.card-container > div div.table div.row div.cell:first-child
main.edit-profile section.form .table-container table th, main.edit-profile section.form .max-width div.card-container > div div.table div.row div.cell div.label {
    font-style: normal;
    color: var(--color-gray-900, #212529);
    font-family: 'Source Sans 3';
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    padding: 13px 30px;
    //background: var(--color-gray-200, #E9ECEF)
    //background: rgba(255, 255, 255, var(--color-gray-200))
    background: rgba(255, 255, 255, .85); }

main.edit-profile section.form .table-container table th {
    background: var(--color-gray-200);
    border-bottom: var(--color-gray-500) 1px solid; }

main.edit-profile section.form .max-width div.card-container > div div.table div.row div.cell:first-child div.label {
    white-space: nowrap; }

main.edit-profile section.form .max-width div.card-container > div div.table div.row div.cell:last-child {
    text-align: right;
    width: auto; }

main.edit-profile section.form .max-width div.card-container > div div.table div.row div.cell:first-child {
    width: 1%; }

main.edit-profile section.form .max-width div.card-container > div div.table div.row div.cell div.label {
    margin: 8px;
    border-radius: 8px;
 }    //white-space: nowrap

main.edit-profile section.form .max-width div.card-container > div div.table div.row div.cell div.value {
    margin: 8px;
    border-radius: 8px;
    //white-space: nowrap
    background: rgba(255, 255, 255, .85);
    width: fit-content;
    text-align: right;
    margin-right: 0;
    margin-left: auto; }

//main.edit-profile section.form .table-container table th.start
main.edit-profile section.form .table-container table th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px; }

//main.edit-profile section.form .table-container table th.end
main.edit-profile section.form .table-container table th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; }

main.edit-profile section.form .table-container table td {
    //font-size: 16px
    //font-weight: 600
    //font-family: 'Poppins'
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #141416;
    color: #1D1D1D;
    padding: 20px 30px;
    border-bottom: 1px solid var(--color-gray-400, #CED4DA);
    vertical-align: middle;
    background: #F4F5F6;
 }    //background: var(--color-gray-150)

main.edit-profile section.form .table-container table td:first-child {
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px; }

main.edit-profile section.form .table-container table td:last-child {
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px; }

main.edit-profile section.form .table-container table td span {
    color: $gold; }

main.edit-profile section.form .table-container table td.start {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px; }

main.edit-profile section.form .table-container table td.end {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; }

main.edit-profile section.form .table-container table td.end img {
    max-width: 25px;
    height: 25px;
    margin-right: 5px; }

main.edit-profile section.form .table-container table td.total {
    font-weight: 700; }


//main.edit-profile section.form .flex-container
//    justify-content: start
//  //gap: 2em
//  //gap: 20px
//    padding-top: 20px
//  //max-width: 808px
//main.edit-profile section.form form .flex-container
main.edit-profile section.form .table-container a {
    color: var(--color-theme-primary); }

main.edit-profile section.form .flex-container > div {
    //flex-basis: 50%
    flex-grow: 0; }

main.edit-profile.user-profile section.form .flex-container > div {
    flex: 1 1 calc(50% - 1em); }

main.edit-profile section.form .flex-container.editable > div {
    flex: 1 1 calc(50% - 1em); }

main.edit-profile section.form .flex-container .profile-photo {
    border: blue 0px solid;
    flex-basis: 33%;
 }    //margin-left: 50px

main.edit-profile section.form .button-container {
    text-align: center;
    margin-right: auto;
    margin-left: auto; }

main.edit-profile section.form .button-container .button-wrapper {
    text-align: center;
    //margin-left: auto
    //margin-right: auto
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px; }

main.edit-profile section.form .button-container .button-wrapper input[type="submit"] {
    padding: 20px 50px;
    border-radius: 5px; }

main.edit-profile section.form .button-container .button-wrapper input[type="cancel"] {
    color: red; }

main.edit-profile section.form .flex-container .input-box {
    display: flex;
    flex-wrap: nowrap; }

main.edit-profile section.form .flex-container .input-box:focus-within {
    //border-color: var(--color-theme-primary)
    //border-color: red
    //border: 2px solid var(--color-gray-300)
    border-color: var(--color-gray-600);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25); }

main.edit-profile section.form .flex-container .input-box img {
    vertical-align: middle;
    margin: auto 5px; }

main.edit-profile section.form .flex-container .input-box input {
    width: 100%; }

main.edit-profile section.form .flex-container label span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: initial;
    letter-spacing: -0.01em;
    color: #B9BABA;
    vertical-align: middle; }

main.edit-profile section.form .flex-container label span.required {
    color: #f00;
    font-weight: 700;
    font-size: 150%; }

main.edit-profile section.form .flex-container div > p {
    margin:  0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #777E91;
 }    //padding-top: 4px

.flex.address-input-container {
    width: 100%;
    flex-wrap: wrap;
    gap: 6px; }

.flex.address-input-container .flex-item {
    text-align: left;
    flex-basis: 100%; }


// item-main

//main.item-main
//    outline: red 3px solid

//main.item-main section.form
//    outline: green 3px solid

//main.item-main section.form div.max-width
//    outline: yellow 3px solid

//main.item-main section.form div.max-width div.item-container
//    outline: blue 3px solid

main.item-main section.form div.max-width div.item-container div.asset-intro {
    color: var(--color-gray-white);
    font-family: 'Open Sans Light';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 5px 0px 20px 0px; }

main.item-main section.form div.max-width div.item-container div.item-details {
    flex-grow: 1;
    //flex-basis: 50%
    //flex-basis: calc(50% - 20px)
 }    //outline: pink 3px solid

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table
//    outline: orange 2px dotted

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell
//    outline: brown 2px dotted

main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper {
    border-radius: 10px;
    border: 1px solid var(--color-gray-300, #DEE2E6);
    background: var(--color-white, #FFF);
    width: 100%;
    //max-width: 525px
    //margin-right: 0px
    //margin-left: auto
    margin: auto;
    max-width: 525px; }

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table
main section div.max-width div.item-container div.table.item-details-table {
    width: 100%; }

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell
main section div.max-width div.item-container div.table.item-details-table div.row div.cell {
    color: var(--color-gray-800);
    //font-family: 'Source Sans 3'
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    //line-height: 150%
 }    //padding: 15px 0px

main section div.max-width div.item-container div.table.item-details-table div.row div.cell.label {
    font-size: 18px; }

main section.profile-main div.max-width div.item-container div.table.item-details-table div.row div.cell {
    font-size: 17px;
    line-height: 18px;
    padding: 3px 0px; }

main section div.max-width div.item-container div.table.item-details-table div.row div.cell:nth-child(2) {
    color: var(--color-gray-600);
    color: #777; }

main section.profile-main div.max-width div.item-container div.table.item-details-table div.row div.cell:nth-child(2) {
    font-size: 14px; }

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell
main section div.max-width div.item-container div.table.item-details-table div.row div.cell {
    //padding: 15px 10px
    border-bottom: 1px solid #D9D9D9;
    text-transform: uppercase; }

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row:last-child div.cell
main section div.max-width div.item-container div.table.item-details-table div.row:last-child div.cell {
    border-bottom: 0px; }

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell:first-child
//  //padding: 0px 20px
//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell.icon img
//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell.icon
main section div.max-width div.item-container div.table.item-details-table div.row div.cell.icon {
    padding: 10px 15px;
    text-align: center;
    vertical-align: middle; }

main section.profile-main div.max-width div.item-container div.table.item-details-table div.row div.cell.icon {
    display: none; }

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell.label
main section div.max-width div.item-container div.table.item-details-table div.row div.cell.label {
    padding: 10px 15px;
    vertical-align: middle;
    line-height: 22px; }

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell.label > div:nth-child(2)
main section div.max-width div.item-container div.table.item-details-table div.row div.cell.label > div:nth-child(2) {
    font-size: .66em;
    line-height: 13px;
    //margin: 4px 0px
    //margin-top: -3px
    margin-top: 3px;
    text-transform: none; }

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell.bold
main section div.max-width div.item-container div.table.item-details-table div.row div.cell.bold {
    vertical-align: middle; }

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell:last-child
main section div.max-width div.item-container div.table.item-details-table div.row div.cell:last-child {
    padding: 0px 20px 0px 0px;
    text-align: right;
    vertical-align: middle; }

main section.profile-main div.max-width div.item-container div.table.item-details-table div.row div.cell:last-child {
    padding: 0px;
    //padding-left: 20px
    font-weight: 600; }

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell:last-child div.item
//    vertical-align: middle

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell.icon img
main section div.max-width div.item-container div.table.item-details-table div.row div.cell.icon img {
    max-height: 30px;
    //max-width: 30px
    //width: 30px
 }    //height: 30px

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell:last-child div.item button
main section div.max-width div.item-container div.table.item-details-table div.row div.cell:last-child div.item button {
    padding: 3px 10px;
    border-radius: 5px;
    //font-family: 'Source Sans 3'
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    vertical-align: top; }

//main.item-main section.form div.max-width div.item-container div.item-details div.item-details-table-wrapper div.table.item-details-table div.row div.cell.bold:last-child
main section div.max-width div.item-container div.table.item-details-table div.row div.cell.bold:last-child {
    font-weight: 700;
    color: var(--color-gray-800); }


// item-acceptance

main.item-acceptance {
    padding-top: 84px; }

main.item-acceptance section.form div.max-width div.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start; }

main.item-acceptance section.form div.max-width div.flex div.table.thank-you {
    background: #FFD700;
    border: 2px solid #A28A00;
    padding: 15px;
    color: #5D4F00;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #5D4F00; }

main.item-acceptance section.form div.max-width div.flex div.table.thank-you strong {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px; }

main.item-acceptance section.form div.max-width div.flex div.table.creator {
    padding-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px; }

main.item-acceptance section.form div.max-width div.flex div.table.creator div.cell.text span {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px; }

main.item-acceptance section.form div.max-width div.flex div.product-name {
    padding: 20px 0px;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #6B6666; }

main.item-acceptance section.form div.max-width div.flex div.description {
    //font-weight: 400
    //font-size: 12px
    //line-height: 18px
    color: #000;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px; }

main.item-acceptance section.form div.max-width div.flex div.description p {
    color: #000;
    margin: 14px 0px; }

main.item-acceptance section.form div.max-width div.flex div.description p a {
    color: var(--color-gold);
    color: #A28A00;
    font-weight: 700;
    text-decoration: none; }

main.item-acceptance section.form div.max-width div.flex div.table.dates {
    width:  100%;
    padding-bottom: 20px; }

main.item-acceptance section.form div.max-width div.flex div.table.dates div.cell {
    color: #000;
    padding: 8px 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px; }

main.item-acceptance section.form div.max-width div.flex div.table.dates div.cell.date {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4E4E4E;
    text-align: right; }


// section.form.your-profile

//main section.form.your-profile
.text-box {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #23262F;
    padding: 10px 0px;
    padding-left: 10px;
    border: 2px solid transparent; }

//main section.form.your-profile
.address-box {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    //color: #23262F
    color: #777E91;
    padding: 10px 0px;
    //padding-left: 10px
    border: 2px solid transparent;
    text-align: left; }

.address-box.gold-font {
    color: var(--color-gold); }

.address-box.selected {
    //color: #777E91
    color: #23262F; }

//main section.form.your-profile
.address-box label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    //color: #23262F
    padding: 10px 0px;
    //padding-left: 10px
    border: 2px solid transparent;
    text-transform: unset; }

//main section.form.your-profile
.address-box label {
    //color: #23262F
    line-height: 24px; }

//main section.form.your-profile
.address-box > * {
    display: inline-block; }

//main section.form.your-profile
.address-box > div:first-child {
    padding-right: 10px; }

// Temporary - remove the slide-in menus
//body .mobile-user-header-container
//    display: none
//body .mobile-nav-container
//    display: none

// Hero Section
.hero-container {
    //max-width: 48%
    //padding-top: 80px
    //padding-bottom: 80px
    //padding: 40px 0px
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    //padding: 0 80px
 }    //margin: auto

//.hero-container div:nth-child(2)
//  //margin: 0px 20px

.hero-heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    //font-size: 64px
    line-height: 131%;
    text-transform: uppercase;
    color: #FFFFFF;
 }    //padding: 10px 0px

.hero-title {
    font-family: 'Roboto Serif';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 58px;
    //text-transform: uppercase
    letter-spacing: 0em;
    //color: #000000
    //margin-top: 40px
    //margin-bottom: 24px
    text-align: left; }

.hero-title .subtitle {
    font-style: italic;
    font-weight: 500;
 }    //white-space: nowrap

.hero-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
 }    //color: #000000

//nav div.logo-image img
//    vertical-align: bottom
//    max-height: 43px
//    max-width: 154px

main.home > section div.container div.item {
    background: #F7F7F7; }

//main.home > section div.container div.slick-slider div.item div.item-top
//main > section div.container div.item div.item-top
main > section div#latest-arrivals-container.container div.item div.item-top {
    padding: 8px 16px;
    //background: #F7F7F7
    border-bottom: var(--color-gray-200) 1px solid; }

//main.home > section div.container div.slick-slider div.item div.item-top button
//main > section div.container div.item div.item-top button
main > section div#latest-arrivals-container.container div.item div.item-top button {
    //font-family: 'Source Sans 3'
    border-radius: 4px;
    //font-family: 'Source Sans 3'
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    //line-height: 100%
    padding: 4px 8px; }

main > section div.container div.item div.item-top button {
    //padding: 3px 6px
    //padding: 4px 8px
    //padding: 5px 10px
    padding: 2px 8px;
    border-radius: 4px;
    vertical-align: top; }

main.home > section div.container div.item div.item-button {
    text-align: right; }

main.home > section div.container div.item div.item-button button {
    padding: 6px 12px;
    border-radius: 4px;
    //font-family: Source Sans 3
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; }


//
// Property Type Colors
//

//
// Office
//
.item.Office button.flag {
    color: var(--color-gray-white, var(--color-white, #FFF));
    background: var(--Office); }

main.home > section div.container div.item.Office {
    border-bottom: 6px solid var(--Office, #DC7700); }

main.home > section div.container div.item.Office button {
    color: var(--color-gray-white, var(--color-white, #FFF));
    background: var(--Office); }

span.dot.Office, button.Office, label.Office {
    background-color: var(--Office);
    color: #FFF; }

//
// Retail
//
.item.Retail button.flag {
    color: var(--color-gray-white, var(--color-white, #FFF));
    background: var(--Retail); }

main.home > section div.container div.item.Retail {
    border-bottom: 6px solid var(--Retail, #DC7700); }

main.home > section div.container div.item.Retail button {
    color: var(--color-gray-white, var(--color-white, #FFF));
    background: var(--Retail); }

span.dot.Retail, button.Retail, label.Retail {
    background-color: var(--Retail);
    color: #FFF; }

//
// Hospitality
//
.item.Hospitality button.flag {
    color: var(--color-gray-white, var(--color-white, #FFF));
    background: var(--Hospitality); }

main.home > section div.container div.item.Hospitality {
    border-bottom: 6px solid var(--Hospitality, #DC7700); }

main.home > section div.container div.item.Hospitality button {
    color: var(--color-gray-white, var(--color-white, #FFF));
    background: var(--Hospitality); }


span.dot.Hospitality, button.Hospitality, label.Hospitality {
    background-color: var(--Hospitality);
    color: #FFF; }

//
// Industrial
//
.item.Industrial button.flag {
    color: var(--color-gray-white, var(--color-white, #FFF));
    background: var(--Industrial, #DC7700); }

main.home > section div.container div.item.Industrial {
    border-bottom: 6px solid var(--Industrial, #DC7700); }

main.home > section div.container div.item.Industrial button {
    color: var(--color-gray-white, var(--color-white, #FFF));
    background: var(--Industrial, #DC7700); }

span.dot.Industrial, button.Industrial, label.Industrial {
    background-color: var(--Industrial);
    color: #FFF; }

//
// Mixed-Use
//
.item.Mixed-Use button.flag {
    color: var(--color-gray-white, var(--color-white, #FFF));
    background: var(--Mixed-Use); }

main.home > section div.container div.item.Mixed-Use {
    border-bottom: 6px solid var(--Mixed-Use, #2B79C6); }

main.home > section div.container div.item.Mixed-Use button {
    color: var(--color-gray-white, var(--color-white, #FFF));
    background: var(--Mixed-Use, #DC7700); }

span.dot.Mixed-Use, button.Mixed-Use, label.Mixed-Use {
    background-color: var(--Mixed-Use);
    color: #FFF; }


//
// Multi-Family
//
.item.Multi-Family button.flag {
    color: var(--color-gray-white, var(--color-white, #FFF));
    background: var(--Multi-Family); }

main.home > section div.container div.item.Multi-Family {
    border-bottom: 6px solid var(--Multi-Family, #2B79C6); }

main.home > section div.container div.item.Multi-Family button {
    color: var(--color-gray-white, var(--color-white, #FFF));
    background: var(--Multi-Family, #DC7700); }

span.dot.Multi-Family, button.Multi-Family, label.Multi-Family {
    background-color: var(--Multi-Family);
    color: #FFF; }

.filter-section #select-property-type #categories-dropdown-menu label {
    color: #fff;
    user-select: none; }

//
// Commercial - not currently being used as a top level category
//
//div.item.Commercial button
//    color: var(--color-gray-white, var(--color-white, #FFF))
//    background: var(--Commercial, #DC7700)
//main.home > section div.container div.slick-slider div.item.Commercial button
//    color: var(--color-gray-white, var(--color-white, #FFF))
//    background: var(--Commercial, #DC7700)
//main.home > section div.container div.slick-slider div.item.Commercial
//    border-bottom: 6px solid var(--Commercial, #2B79C6)
//span.dot.Commercial, button.Commercial
//    background-color: var(--Commercial)
//    color: #FFF

//
// Experimenting with Category and Subcategory naming
//
//span.dot.Commercial-Office
//    background-color: var(--Commercial-Office)
//    color: #FFF
//span.dot.Commercial-Retail
//    background-color: var(--Commercial-Retail)
//    color: #FFF


// background: var(--color-gray-100, #F8F9FA)

main.item-main section.property-information {
    //padding-bottom: 350px
    padding-bottom: 80px;
 }    //background: var(--color-gray-100, #F8F9FA)

main.item-main section.property-information div.item-images div.item-images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; }

main.item-main section.property-information div.item-images div.item-image {
    flex-basis: calc(50% - 10px); }

main.item-main section.property-information div.max-width {
    background-color: #fff; }

main.item-main section.property-information div.max-width div.property-information {
    max-width: 880px;
    margin: auto;
    //padding: 70px 0px
    //padding: 30px 0px 70px 0px
    //padding: 20px 0px
    padding: 50px 0px 100px 0px;
    color: var(--color-gray-700); }

main.item-main section.property-information div.max-width div.property-information strong {
    font-weight: 600; }

main.item-main section.property-information div.max-width div.property-information h2 {
    color: #000;
    font-family: 'Roboto Slab';
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-align: left;
    padding: 25px 0px;
    margin: 0px; }

main.item-main section.property-information div.max-width div.property-information h3 {
    //color: var(--color-gray-800, #343A40)
    color: #000;
    font-family: 'Roboto Slab';
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    padding: 25px 0px;
    margin: 0px; }

main.item-main section.property-information div.max-width div.property-information h4 {
    color: var(--color-gray-800, #343A40);
    font-family: 'Roboto Slab';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    //padding: 0px
    padding: 20px 0px;
    margin: 0px; }

main.item-main section.property-information div.max-width div.property-information p {
    //color: var(--color-gray-700)
    //font-family: 'Source Sans 3'
    //font-size: 18px
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    //padding: 10px 0px
    text-align: center;
    //padding: 50px 100px
    padding: 25px 100px 50px 100px;
    margin: 0px; }

main.item-main section.property-information div.max-width div.property-information .about-how-it-works div > div {
    padding: 50px;
    text-align: center; }
//    border-left: var(--color-theme-primary) 5px solid
//    border-top-left-radius: 10px
//    border-top-right-radius: 10px
//    border-right: var(--color-theme-primary) 5px solid
//    border-bottom-left-radius: 15px
//    border-bottom-right-radius: 15px
//    border-color: rgba(2, 91, 201, 0.75)

main.item-main section.property-information div.max-width div.property-information ul {
    margin-top: 2px;
    margin-bottom: 30px;
    list-style: none;
    padding: 0px; }

main.item-main section.property-information div.max-width div.property-information ul li {
    color: var(--color-gray-700);
    //font-family: 'Source Sans 3'
    //font-size: 18px
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0px;
    //padding: 10px 0px
    //padding: 50px 50px
    padding: 50px 50px;
    text-align: center; }

// financials-table
.financials-table div.cell:last-child {
    text-align: right; }

.table.financials-table .cell {
    padding: 8px 0px; }

#start-investing-today-wrapper .container > div, #team-wrapper .container > div {
    flex-basis: 30%;
    flex-grow: 1;
    min-width: 250px; }

#start-investing-today-wrapper .container > div h3 {
    font-family: 'Roboto Slab';
    text-align: center;
    padding: 20px 0px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: auto;
    vertical-align: middle;
    display: inline-block; }

#start-investing-today-wrapper .container > div p {
    text-align: center;
    color: var(--color-gray-700, #495057);
    font-family: 'Source Sans 3';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; }

footer div.social-icons img {
    padding: 10px;
    filter: $filter-white;
    //+filter-theme-primary
    //filter: var(--filter-theme-primary)
    //filter: invert(100%) sepia(3%) saturate(12%) hue-rotate(103deg) brightness(105%) contrast(105%)
 }    //+filter-theme-primary

// Team section
#team-wrapper .container.team-container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: space-evenly; }

#team-wrapper .container.team-container .team-item {
    //display: inline-block
    margin: auto;
    //border: 1px solid var(--color-gray-500)
 }    //box-shadow: 0px 3px 31px 0px rgba(0, 0, 0, 0.15)

#team-wrapper .container.team-container .team-item div.title {
    //padding: 40px
    padding: 20px 40px 40px 40px; }

#team-wrapper .container.team-container .team-item div.title h3 {
    //font-family: "Source Sans 3"
    font-family: 'Roboto Slab';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
    line-height: 30px; }

#latest-arrivals-wrapper {
    background-image: url(../assets/images/backgrounds/city-scape.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-bottom: 50px;
    //border: green 3px solid
    //background-image: url(../assets/images/backgrounds/city-sky.jpg)
    overflow: hidden; }

//.cart-page .cart-main
//    border: green 3px solid

.cart-page .subtitle {
    padding-top: 15px;
    padding-bottom: 15px; }

//.cart-page .cart-main button.primary-inverse.medium.rounded
.cart-page .cart-main button.small {
    margin: 0px;
 }    //font-size: 13px

.cart-page .cart-main .flex-container {
    //border: green 3px solid
    justify-content: flex-start;
    column-gap: 10px;
    //margin-top: 10px
 }    //margin-bottom: 20px

.cart-page .cart-main .flex-container small {
    margin-top: -5px;
    display: inline-block;
    margin-bottom: 10px; }

//.cart-page .cart-main .flex-container.quick-amounts .quick-amounts
.cart-page .cart-main.flex-container .quick-amounts {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-tems: top;
    font-size: 14px;
    line-height: 14px;
    //margin: 10px 0px
    margin: 10px 0px;
    padding: 0px 10px;
    gap: 10px; }

.cart-page .cart-main.flex-container .quick-amounts small {
    color: #777E91; }

.cart-page .cart-main.flex-container .quick-amounts button {
    margin-bottom: 0px;
    //margin-top: 10px
    //position: relative
 }    //margin-bottom: 10px

//.cart-page .cart-main .flex-container.quick-amounts button small
//    position: absolute
//    display: inline-block
//    top: 25px
//  //left: 50%
//    right: 0

.cart-page .cart-main.flex-container .title {
    vertical-align: middle;
    display: inline-block;
    //font-family: 'DM Sans'
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 13px;
    //line-height: 13px
    word-wrap: break-word; }

main.edit-profile section.form div.max-width div.cart-page div.cart-main.flex-container button {
    fill: var(--color-gray-500); }

main.edit-profile section.form div.max-width div.cart-page div.cart-main.flex-container button:disabled {
    background-color: var(--color-gray-300);
    cursor: not-allowed; }

main.edit-profile section.form .max-width .cart-page .cart-main.flex-container button:hover {
    fill: var(--color-theme-primary); }

.cart-page .cart-main.flex-container button {
    display: flex;
    flex-direction: column;
 }    //padding: 5px 10px

.cart-page .cart-main.flex-container button div {
    font-weight: 500; }

.cart-page .cart-main.flex-container button small {
    font-size: 10px;
    line-height: 10px;
    margin: auto; }

.cart-page .cart-main .cart-item:not(:first-child) {
    //outline: blue 3px solid
    margin-top: 20px; }

.cart-page .cart-main .cart-item .item-info .item-name {
    font-size: 24px; }

.cart-page .cart-main .cart-item .item-info .item-name a {
    color: var(--color-gray-800); }

.cart-page .cart-main .cart-item .item-info .item-name a:visited {
    color: var(--color-gray-900); }

//.cart-page .cart-main .cart-item .cart-item-center
//  padding-left: 13px

.cart-page .cart-main .cart-item .cart-item-center.item-info {
    font-size: 20px;
 }    //padding-left: 13px

//.cart-page .container .cart-main .left .cart-item .item-info
.cart-page .cart-main .cart-item .cart-item-center.item-info {
    //padding: 8px 0px
    //padding-bottom: 10px
    padding-left: 30px; }

.cart-page .cart-main .cart-item .item-info {
    padding-bottom: 10px; }

.cart-page .cart-main .cart-item input::placeholder {
    font-size: 18px; }

.cart-page .cart-main .cart-item div.row div.cell input {
    width: 80px;
    font-size: 18px;
    text-align: right;
    border: var(--color-gray-200) 2px solid;
    border-radius: 3px;
    //padding: 5px 10px
    padding: 5px;
    outline: none; }

.cart-page .cart-main .cart-item div.row div.cell input.alert {
    border: red 2px solid; }

//.cart-page .cart-main .cart-item div.row div.cell div.cell.item-date.alert
.cart-page .cart-main .cart-item div.row div.cell div.alert {
    color: red; }

//#latest-arrivals-container
//  //background-image: url(../assets/images/backgrounds/city-sky.jpg)
//  //background-image: url(/images/backgrounds/city-sky.jpg)

#latest-arrivals-container .assets-container .asset-container {
    align-content: stretch;
    flex-basis: 400px; }

#what-is-realsplit-container figcaption > div {
    font-size: 16px;
    font-weight: 600; }

//#what-is-realsplit-container #sample-past-deals
//    border: green 2px solid

#what-is-realsplit-container #sample-past-deals div.table div.cell figure {
    padding: 25px 0px; }

input[type="range"] {
    background-color: var(--color-theme-primary);
    color: var(--color-theme-primary);
    accent-color: var(--color-theme-primary); }

.text-shadow {
    text-shadow: -1px -1px 0px rgba(0,0,0,0.15), -1px 0px 0px rgba(0,0,0,0.15); }

output {
    position: absolute;
    //background-image: -moz-linear-gradient(top, #444444, #999999)
    //background-image: -o-linear-gradient(top, #444444, #999999)
    //background-image: -webkit-gradient(linear, left top, left bottom, from(#444444), to(#999999))
    //background-image: -webkit-linear-gradient(top, #444444, #999999)
    background-color: var(--color-theme-primary);
    //width: 40px
    width: auto;
    white-space: nowrap;
    //height: 30px
    height: auto;
    font-size: 16px;
    line-height: 18px;
    padding: 3px 11px;
    text-align: center;
    color: white;
    border-radius: 20px;
    display: inline-block;
    //font: bold 15px/30px Georgia
    //bottom: 125%
    top: -26px;
    left: 0;
    margin-left: -1%;
    z-index: 1; }

output:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid var(--color-theme-primary);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px; }

//main.about h1
main.about section div.max-width h1 {
    font-family: "Roboto Serif";
    font-size: 48px;
    font-weight: 700;
    color: var(--color-theme-dark-blue);
    padding: 15px 0px 25px 0px; }

//main.about h2
main.item-main.about section.property-information div.max-width .property-information h2 {
    font-family: "Roboto Serif";
    font-size: 40px;
    font-weight: 600;
    //color: var(--color-theme-dark-blue)
    //padding: 20px 0px 5px 0px
    color: var(--color-gray-700);
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;

    //margin: 0
    //margin: auto
    //text-align: center
    //font-style: normal
 }    //line-height: 120%

//main.about h3
main.item-main.about section.property-information div.max-width .property-information h3 {
    font-family: "Roboto Serif";
    font-size: 28px;
    font-weight: 500;
    //padding: 10px 0px 10px 0px
    //padding: 15px 0px 0px 0px
    padding-left: 50px;
    padding-right: 50px;
    //color: var(--color-theme-dark-blue)
    color: var(--color-gray-700);
    text-align: center;
    //color: var(--color-gray-700)
    //margin: 0
    //margin: auto
    //text-align: center
    //font-style: normal
    //font-weight: 600
 }    //line-height: 120%

main.about p {
    padding: 5px 0px 5px 0px; }

main.item-main.about section.property-information div.max-width .property-information p a {
    color: #075BC5; }

main.item-main.about section.property-information div.max-width .property-information p a:visited {
    color: #075BC5; }

main.item-main.about section.property-information div.max-width .property-information p a:hover {
    text-decoration: underline; }

main.item-main.about section.property-information div.max-width .property-information ul {
    margin-bottom: 0px; }

main.item-main.about section.property-information div.max-width .property-information ul li {
    //padding: 5px 0px 5px 0px
    padding: 25px 50px; }

main.item-main.about section.property-information div.max-width .property-information .vision-mission {
    background-color: var(--color-theme-primary);
    border-radius: 10px;
    color: #fff;
    padding: 20px 50px 20px 50px;
    //margin: 50px 50px
    margin: 0px 50px 50px 50px;
    box-shadow: var(--color-gray-500) 0px 5px 20px 0px; }

main.item-main.about section.property-information div.max-width .property-information .vision-mission h3 {
    color: #fff;
    border-bottom: white 2px solid;
    font-size: 1.6em; }

main.item-main.about section.property-information div.max-width .property-information .vision-mission p {
    color: #fff;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    font-size: 1.3em;
    font-weight: 500;
    font-style: italic; }

main.item-main.about section.property-information div.max-width .property-information .vision-mission p span {
    font-size: 0.8em;
    font-weight: 400;
    font-style: normal; }

main.item-main section.property-information div.max-width div.property-information .about-section2 h3 {
 }    //padding-bottom: 10px

main.item-main section.property-information div.max-width div.property-information .about-section2 p {
    //padding: 10px 100px 25px 100px
    padding: 25px 25px;
    font-size: 1.2em;
    //color: var(--color-theme-primary)
    color: rgba(2, 91, 201, 0.8); }

//main.item-main section.property-information div.max-width div.property-information .about-section2 p:nth-child(2)
main.item-main section.property-information div.max-width div.property-information .about-section2 p {
    //padding: 10px 100px 25px 100px
    padding: 25px 50px;
    font-size: 1.2em;
    //color: var(--color-theme-primary)
    color: rgba(2, 91, 201, 0.8); }

main.item-main section.property-information div.max-width div.property-information .about-section {
    padding-top: 50px;
    margin-top: 150px;
    border-left: var(--color-theme-primary) 5px solid;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-right: var(--color-theme-primary) 5px solid;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: var(--color-gray-50);
    border-top: rgba(2, 91, 201, 0.25) 1px solid;
    //border-bottom: rgba(2, 91, 201, 0.25) 2px solid
    border-bottom: rgba(2, 91, 201, 0.25) 2px solid;
    padding-bottom: 50px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 48px 48px 0px;
    border-color: rgba(2, 91, 201, 0.25); }

main.item-main section.property-information div.max-width div.property-information .about-section h3 {
    padding-bottom: 10px; }

main.item-main section.property-information div.max-width div.property-information .about-section p {
    padding: 10px 100px 25px 100px; }

main.item-main section.property-information div.max-width div.property-information .about-section .about-section-circle {
    border: rgba(2, 91, 201, 0.5) 1px solid;
    border-radius: 100px;
    box-shadow: var(--color-gray-400) 0px 3px 8px 0px;
    margin: auto;
    text-align: center;
    display: inline-block;
    margin-top: -100px;
    padding: 25px;
    background-color: #fff; }

main.item-main section.property-information div.max-width div.property-information .about-section .about-section-circle img {
    height: 100px;
    max-width: 100px;
    filter: brightness(0) saturate(100%) invert(22%) sepia(62%) saturate(3018%) hue-rotate(203deg) brightness(97%) contrast(99%); }

main.item-main section.property-information div.max-width div.property-information .about-section2 {
    //padding-top: 50px
    margin-top: 100px;
 }    //margin-top: 50px

main.item-main section.property-information div.max-width div.property-information .about-section2 p {
    padding: 25px 50px; }

main.item-main section.property-information div.max-width div.property-information .about-section div.about-content > div {
    padding: 10px 100px 25px 100px;
    text-align: center; }

main.item-main section.property-information div.max-width div.property-information .about-section.about-service-offerings ul li {
    display: inline;
 }    //list-style:

.about-asset-management-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 25px 25px 25px;
    row-gap: 25px;
    justify-content: space-around; }

.about-asset-management-container > div {
    flex-basis: 200px;
    text-align: center;
    font-size: .9em;
    //border-top: var(--color-theme-primary) 1px solid
    border-left: rgba(2, 91, 201, 0.25) 1px solid;
    //border-right: var(--color-theme-primary) 1px solid
    border-right: rgba(2, 91, 201, 0.25) 1px solid;
    border-top: var(--color-theme-primary) 5px solid;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    //border-right: var(--color-theme-primary) 4px solid
    border-bottom: rgba(2, 91, 201, 0.75) 5px solid;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 15px 25px;
    border-color: rgba(2, 91, 201, 0.75);
    //color: rgba(2, 91, 201, 0.9)
    box-shadow: var(--color-gray-400) 0px 5px 10px 0px; }

// Referral Link

.refer-wrapper {
    background: #FCFCFD;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.refer-wrapper h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
 }    //margin-bottom: 15px

.refer-wrapper p {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #8A8A8A;
 }    //margin: 20px auto

.refer-wrapper p label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    color: #8A8A8A; }

//.refer-wrapper .refer-copy
//    width: 100%
//    height: 50px
//  //border: 1px solid #000000
//  //margin-bottom: 20px
//    display: flex

//.refer-wrapper .refer-copy input
//  //border: none
//    outline: none
//  //width: 80%
//    width: 100%
//    background: #ffffff
//  //height: 48px
//  //+Lato
//  ///font-weight: 600
//  //font-size: 24px
//    text-align: left
//  //color: #3F3C3C
//    padding: 10px 20px
//    font-family: 'Poppins';
//    font-style: normal;
//    font-weight: 700;
//    font-size: 14px;
//    line-height: 24px;
//    display: flex;
//    align-items: center;
//    color: #263143;
//  //+m
//  //    width: 60%

.refer-wrapper .refer-copy button {
    //+Lato
    //width: 20%
    //background: #DB3A78
    //height: 48px
    //padding: 8px
    //text-align: center
    //text-transform: uppercase
    //color: #FFFFFF
    //font-weight: 700
    //font-size: 18px
    //&:hover
    //    background: #000000
    //+m
 }    //    width: 40%

.share-with {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }

.share-with hr {
    // border: 1px solid #9F9F9F
    width: 300px;
    height: 1px;
    background: #9F9F9F; }

.share-with p {
    font-weight: 600;
    font-size: 18px;
    //line-height: 34px
    text-align: center;
    color: #8A8A8A; }

.refer-social {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //padding: 40px
    //gap: 2vw
    gap: 3vw; }

.refer-social .icon {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-right: 30px
    //background: #FFF6C1
 }    //background: var(--color-blue-50)

.refer-social .icon:last-child {
    margin-right: 0px; }

.refer-social img {
    //width: 100%
    //width: 20px
    //height: 100%
    //height: 20px
    width: 32px;
    object-fit: contain;
    margin: auto;
    //filter: invert(10%) sepia(15%) saturate(616%) hue-rotate(169deg) brightness(91%) contrast(88%)
    filter: invert(23%) sepia(85%) saturate(1915%) hue-rotate(203deg) brightness(96%) contrast(100%); }

@import "./media-queries";
