//$color-gold: #A28A00
//@root
//    --color-gold: #A28A00

// common styles
body {
    // min-width: 375px
    //background: $neutrals8
    background: $white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    //+Inter
    //+Poppins
    @include font-source-sans;
    font-size: 16px;
    //line-height: (24/14)
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $neutrals2;
    //overflow-x: hidden
    @include dark-body {
        background: $neutrals1;
        color: $neutrals8; } }

a {
    text-decoration: none; }

a:visited {
    color: var(--color-theme-primary); }

a:hover {
    text-decoration: underline; }

svg,
img {
    vertical-align: middle; }

.desktop {
    &-hide {
        @include d {
            display: none !important; } }
    &-show {
        display: none !important;
        @include d {
            display: block !important; } }
    &-text-right {
        @include d {
            text-align: right; } } }

.tablet {
    &-hide {
        @include t {
            display: none !important; } }
    &-show {
        display: none !important;
        @include t {
            display: block !important; } } }

.mobile {
    &-hide {
        @include m {
            display: none !important; } }
    &-show {
        display: none !important;
        @include m {
            display: block !important; } } }

//.gdpr-popup-expand-heading
//    //color: $gold !important
//    color: $color-primary !important

//.gdpr-popup-btn-container .gdpr-popup-btn
//  //border-radius: 25px
//  //border: 2px solid #000 !important
//  //color:#1d1d1d !important
//  //background-color:$white !important
//  //margin-bottom: 10px
//  //+s
//  //    width: calc( 50% - 7px)
//  //border: var(--color-theme-primary) 2px solid
//    background-color: var(--color-theme-primary)
//    color: rgba(255, 255, 255, 1)
//    border-radius: 5px
//    font-family: 'DM Sans'
//    padding: 16px
//    font-weight: 700
//    font-size: 18px
//  //width: 100%

//.gdpr-popup-btn-container .gdpr-popup-btn:hover
//    background-color: var(--color-theme-primary)
//    color: rgba(255, 255, 255, 1)
//    border-radius: 5px
//    font-family: 'DM Sans'
//    padding: 16px
//    font-weight: 700
//    font-size: 18px

//.gdpr-popup-container .gdpr-popup-heading
//    margin: 0px 0px 20px 0px
//    font-size: 1.5em

//.gdpr-popup-container .gdpr-popup-text
//    margin: 20px 0px

//.gdpr-popup-position-top-right
//    border-left: none !important

//.dark-mode
//    .gdpr-popup-container
//        background-color: $white !important
//    .gdpr-popup-heading, .gdpr-popup-text, .gdpr-popup-save-pref-label, .gdpr-popup-table
//        color: #1d1d1d !important
//    .gdpr-popup-expand-info
//        background-color: $gold
//        border-radius: 50%

//.gdpr-popup-btn-green
//.gdpr-popup-btn-container .gdpr-popup-btn-green
//    //background-color: $gold !important
//    //color: white !important
//    background-color: var(--color-theme-primary)
//    color: rgba(255, 255, 255, 1)
//    border-radius: 5px
//    font-family: 'DM Sans'
//    padding: 16px
//    font-weight: 700
//    font-size: 18px

//.gdpr-popup-container .gdpr-popup-save-pref-label
//    margin: .5em
//    font-size: 1em

//.gdpr-popup-container .gdpr-popup-save-pref-checkbox
//    height: 1.5em
//    width: 1.5em

//.gdpr-popup-container .gdpr-popup-checkbox
//    height: 1.5em
//    width: 1.5em

//.gdpr-popup-container .gdpr-popup-btn-container
//  //margin: 20px 0px
//    margin-top: 20px
//    margin-bottom: 0px

//.gdpr-popup-container
//    max-height: 420px !important

//body > .gdpr-popup-blurred-background
//    backdrop-filter: none

//.gdpr-popup-save-pref-checkbox
//    &:checked
//        color: $secondary !important

.walletconnect-qrcode__base {
    padding: 65px !important;
    // +m
 }    //     padding: 30px !important

.walletconnect-modal__mobile__toggle {
    a {
        color: $neutrals1 !important; } }
p {
    word-break: break-word; }
// .pagination
//     a
//         border: 1px solid #ccc


.dFlexAic {
    display: flex;
    align-items: center; }


.amount-curreny-container {
    display: inline-flex;
    padding: 30px;
    background: #F4F4F4;
    border-radius: 10px;
    width: fit-content;
    margin: 20px 20px 20px 0;
    @include m {
        width: 100%; }
    @include d {
        width: 100%; }
    .currency {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        img {
            max-width: 36px;
            height: auto;
            width: 100%; } }
    .amount {
        h4 {
            font-size: 30px;
            font-weight: 600;
            line-height: 45px;
            letter-spacing: -0.02em;
            margin: 0; }
        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.03em;
            margin: 0; } } }

.table-heading {
    h3 {
        font-family: Lato;
        font-size: 20px;
        font-weight: 700;
        line-height: 34px;
        margin-bottom: 20px; } }
