@import "../../styles/helpers";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');

.top {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 80px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid $neutrals6;
    @include t {
        display: block;
        margin-bottom: 32px;
        padding-bottom: 0;
        border: none; }
    @include m {
        margin-bottom: 24px; }
    @include dark {
        border-color: $neutrals3; } }


// img
//     max-width: 500px

.title {
    margin-right: auto;
    @include body-1;
    @include t {
        display: none; } }

.blackDooted {
    @include s {
        max-width: 45px; } }


@media only screen and (max-width: 700px) {
    .title {
        display: block !important; } }

.breadcrumb {
    span {
        font-weight: bold; } }

.section {
    padding: 96px 0;
    @include x {
        padding: 80px 0; }
    @include m {
        padding: 64px 0; } }

.container {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.bg {
    position: relative;
    flex-grow: 1;
    margin-right: 96px;
    @include x {
        margin-right: 64px; }
    @include d {
        margin-right: 32px; }
    @include t {
        margin: 0 0 32px; } }

.details {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    min-width: 334px;
    flex-basis: 40%;
    @include d {
        width: 336px; }
    @include t {
        width: 100%; } }

.productTitle {
    font-weight: 700; }

.preview {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        border-radius: 8px;
        // box-shadow: 1px 0px 8px rgba(0, 0, 0, .2)
        max-width: 500px;
        @include m {
            width: 100% !important;
            max-height: 400px;
            object-fit: scale-down; } }
    @include m {
        align-items: center; } }

.categories {
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0 0 -8px; }

.category {
    margin: 8px 0 0 8px; }

.options {
    position: absolute;
    top: auto;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%); }

.title {
    // margin-bottom: 8px
    font-weight: bold; }

.cost {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px; }

.price {
    color: "#333333";
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    &:not(:last-child) {
        margin-right: 8px; } }

.assetName {
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    color: #6b6666;
    margin-bottom: 20px; }

.counter {
    @include button-1;
    color: $white;
    margin-bottom: 10px;
    span {
        font-weight: 500;
        color: $neutrals1;
        @include dark {
            color: $white; } }
    .editionIcon {
        width: 15px;
        height: 15px;
        display: inline-block;
        background: linear-gradient(to top, #326eed, #e1225d);
        border-radius: 100%;
        margin-right: 5px; } }
.badge {
    font-weight: 500;
    border: 2px solid $newblack;
    @include dark {
        background: transparent;
        color: $newblack; } }

.categoryContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    // margin-bottom: 15px
    margin: 15px 0px;
    justify-content: space-between;
    flex-wrap: wrap; }



.inCatagory {
    flex-basis: 98%;
    margin-top: 12px;
    // border: 2px solid $yellow
    column-gap: 14px;
    display: flex;
    // flex-wrap: wrap
    @include m {
        flex-basis: 92%;
        justify-content: space-between;
        min-width: 300px; } }

.shortTitle {
    color: #666666;
    font-size: 20px;
    font-weight: 500; }
.categoriesAlign {
    display: flex;
    flex-wrap: wrap;
    width: 80%; }
.subDescription {
    color: #666;
    word-break: break-all;
    margin-bottom: 10px;
    @include dark {
        color: #666; } }

.info, .infoDesc {
    margin-bottom: 20px;
    word-break: break-word;
    @include body-2;
    // color: $neutrals4
    a {
        text-decoration: underline;
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; }
        &:hover {
            text-decoration: none; } } }

.infoDesc {
    color: white;
    a {
        color: $secondary !important;
        @include dark {
            color: $secondary !important; } } }

.nav {
    display: flex;
    margin-bottom: 32px;
    padding: 6px;
    border-bottom: 2px solid #A3A3A3;
    padding-left: 0px;
    @include m {
        flex-wrap: wrap;
        button {
            padding: 10px;
            @include s {
                padding: 5px; } } } }

.link {
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    text-transform: uppercase;
    padding-left: 0px;
    @include button-2;

    font-weight: bold;
    transition: all .2s;
    &:hover {
        color: $neutrals2;
        @include dark {
            color: $neutrals2; } }
    &.active {
        color: black;
        @include dark {
            background: none;
            color: black; } }
    &:not(:last-child) {
        margin-right: 8px; }
    @include dark {
        color: #666666; } }

.users {
    margin-bottom: auto; }

.control {
    margin-top: 40px;
    @include m {
        margin-top: 32px; } }

.checkout {
    display: flex;
    position: fixed;
    z-index: 33;
    // background: #00000044
    background: #fffffff;
    min-width: 320px;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;

    @include dark {
        background: #ffffff60; }
    .checkoutInner {
        display: flex;
        width: 47%;
        min-width: 300px;
        border-radius: 5px;
        background: #fff;
        border: 2px solid #000000;
        // height: 65%
        color: #141416;
        flex-direction: column;
        @include t {
            width: 80%; }
        @include dark {
            background: #ffffff;
            box-shadow: 0px 0px 10px #ffffffa9; }
        h2 {
            padding: 10px 0px;
            width: 100%;
            text-align: center;
            background: #141416;
            color: #ffffff;
            border-bottom: 1px solid #888; }
        .checkoutDetail {
            margin: 5px auto;
            // margin-top: 20px
            width: 80%;
            > div {
                padding: 15px 0px;
                border-bottom: 1px solid #aaa; }
            > img {
                width: 80px;
                height: 80px;
                border-radius: 5px; }
            .boldText {
                @include font-lato;
                font-weight: 600;
                font-size: 16px;
                line-height: 34px;
                /* identical to box height, or 132% */
                color: #2F2F2F; } } } }

.checkoutTag {
    padding: 30px 50px;
    @include m {
        padding: 30px 10px; } }
.requiredEditionsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .requiredEditionsLabel {
        margin-right: 5px; }

    .requiredEditionInput {
        width: 100px;
        border-radius: 4px;
        padding: 8px 5px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        margin-right: 5px; } }

.productImages {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    .productImageHolder {
        cursor: pointer;
        margin-bottom: 10px;
        width: 92px;
        padding-right: 8px;
        &:not(:first-child) {
            padding: 0px 8px; }
        &:not(:last-child) {
            border-right: 2px solid $secondary; }
        img {
            width: 100%;
            height: 70px;
            object-fit: cover;
            border-radius: 0px; } } }

.bidHistory {
    border: 1px solid #ccc;
    border-radius: 15px;
    text-align: center;
    padding: 0px; }


.buttonFullWidth {
    width: 40%;
    padding: 13px;
    // border: 3px solid $light-pink
    // border: 2px solid $newyello
    border-image-slice: 1 !important;
    border-width: 2px;
    transition: 0.6s;
    // border-image-source: linear-gradient(to top, #326eed, #e1225d)
    border-radius: 25px!important;
    transition: 0.3s ease-in-out;
    background: $newyello;
    span {
        // color: $light-pink
        color: #fff;

        text-transform: uppercase;
        font-weight: 900; }
    &:hover {
        // background: $light-pink
        background: $newblack;

        span {
          color: #fff; } }
    @include s {
        width: 100%; } }

.readMore {
    cursor: pointer;
    color: $secondary; }

.rdt_TableBody, .rdt_TableHeadRow {
    @include dark {
        background-color: #000;
        color: #fff; } }

.lightbox_image_wrap {
    display: flex;
    justify-content: center;
    width: 85%;
    min-width: 300;
    @include a {
        width: 100%;
        height: auto; }
    img {
        max-height: 600px;
        object-fit: scale-down; } }


.checkoutPlayer {
    width: 400px;
    margin: 0 auto; }
.resaleBtn {
    background: $secondary;
    padding: 10px 25px;
    color: $white;
    border: none;
    // margin: auto
    width: 200px; }

.closeBox {
    color: $white;
    padding: 12px;
    position: absolute;
    top: 0;
    font-size: 20px;
    right: 0;
    cursor: pointer; }
.loaderContent {
    width: 100%;
    position: fixed;
    height: 100vh;
    background: #d5d5d5e8;
    z-index: 9999;
    top: 0;
    left: 0;
    // bottom: 0
    // right: 0
    display: flex;
    align-items: center;
    justify-content: center; }

input[type='number'] {
   &::-webkit-inner-spin-button,&::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; } }
.fractionText {
    color: #6A6666;
    font-size: 15px;
    font-weight: 500; }
.fractionCount {
    width: 100%;
    display: inline-flex;
    span {
        font-size: 18px;
        color: #6a6666; }
    p {
        margin-left: 5px;
        font-size: 18px;
        font-weight: 700; } }
