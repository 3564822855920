@import "../../styles/helpers";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');

.searchPage {
 }    //padding-top: 88px

.form {
 }    //border: black 3px solid

.maxWidth {
    //border: blue 3px solid
    //max-width: 1280px
    max-width: $maxWidth;
    margin: auto;
    //padding-top: 34px
    //+s
 }    //    padding-top: 64px

.inputButtonFilled {
    display: inline-flex;
    background-color: #B9BABA;
    border-radius: 20px;
    height: 40px;
    width: 40px;
    margin: auto;
    text-align: center;
    vertical-align: middle; }

.flexContainer {
    fieldset {
        padding: 0px; margin: 0; border: 0; } }

.flexContainer {
    fieldset {
        legend {
            padding: 20px 0px; margin: 0; } } }

.flexContainer {
    fieldset {
        //div.flex.collections
        gap: 10px; } }

.flexContainer {
    fieldset {
        //div.flex.collections
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #23262F; } }

.flexContainer {
    fieldset {
        //div.flex.collections
        //    > div
        padding: 25px;
        background: #F4F5F6;
        border-radius: 16px;
        flex-basis: 25%; } }

.flexContainer {
    fieldset {
        //div.flex.collections
        //    div.circle
        margin-bottom: 10px;
        display: flex; } }

//.inputBox
//    padding: 3px
//    border: 2px solid #E6E8EC
//    border-radius: 8px

.inputBox {
    input {
        background-color: transparent;
        padding: 7px;
        margin: 0;
        border: 0;
        outline: none;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #23262F;
        outline: none; } }

.searchBox {
    display: flex; }

.selectBox {
    > div {}
    display: block;
    padding: 8px 0px; }

.form {
    .inputBox {
        border-radius: 12px; } }

.form {
    a.gold {
        color: var(--color-gold);
        text-decoration: none; } }

.clearButton {
    background-color: #fff;
    border: #E6E8EC 2px solid;
    border-radius: 30px;
    height: 30px; width: 30px;
    text-align: center;
    vertical-align: middle; }

button.rounded-clear {
    padding: 12px 16px;
    gap: 12px;
    border: 2px solid #E6E8EC;
    border-radius: 90px;
    background-color: transparent;
    padding: 5px 10px;
    vertical-align: middle; }


section.searchResults {
    .filterButtons {}
    text-align: end;
    padding-bottom: 50px; }

section.searchResults {
    .filterButtons {
        button {} }
    padding: 6px 12px;
    border-radius: 16px;
    color: #fff;
    color: #777E91;
    background-color: var(--color-gold);
    background-color: transparent;
    margin: auto 2px; }

section.searchResults {
    .filterButtons {
        button:hover {} }
    outline: var(--color-gold) 2px solid; }

section.searchResults {
    .filterButtons {
        button.on:hover {} }
    outline: none; }

section.searchResults {
    .filterButtons {
        button.on {} }
    padding: 6px 12px;
    border-radius: 16px;
    color: #777E91;
    color: #fff;
    background-color: var(--color-gold); }



.cardContainer {
    > div {}
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
    margin-bottom: 30px; }

.profile {
    .cardContainer {
        > div {} }
    width: auto; }

.home {
    .cardContainer {
        > div {} }
    max-width: 300px;
    width: 100%; }

.cardContainer {
    > div.first {}
    margin-top: 0px; }

section.dark {
    .cardContainer {
        > div {} }
    background: #000; }

.cardContainer {
    > div {
        img {} }
    padding: 0px 50px; }

/*
 * .searchResults
 */
.filterButtons {
    text-align: end;
    padding-bottom: 50px; }

.filterButtons {
    button {
        padding: 6px 12px;
        border-radius: 16px;
        color: #fff;
        color: #777E91;
        background-color: var(--color-gold);
        background-color: transparent;
        margin: auto 2px;
        &:hover {
            outline: $color-gold 2px solid;
 } } }            //outline: var(--color-gold) 2px solid

.filterButtons button.on:hover {
    outline: none; }

.filterButtons button.on {
    padding: 6px 12px;
    border-radius: 16px;
    color: #777E91;
    color: #fff;
    background-color: var(--color-gold); }


#notifications-dropdown-menu {
    div.flex {
        div {
            button.gold.rounded {} } }
    padding: 12px 16px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FCFCFD; }

section.searchResults {
    .cardContainer {
        img {} }
    padding: 0px 18px; }

/*
 * .watchInfoBox
 */

div.watchInfoBox {
    display: table;
    width: 100%;
    margin: 10px auto; }

section.dark {
    .card-container {
        div.watchInfoBox {} }
    color: #fff; }

div.watchInfoBox {
    > div {}
    display: table-row; }

div.watchInfoBox {
    > div {
        > div {} }
    display: table-cell;
    padding: 2px 0px; }

div.watchInfoBox {
    > div {
        > div.watchTitle {} }
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    font-family: 'Poppins';
    font-style: normal;
    text-align: left; }

div.watchInfoBox {
    > div {
        > div.watchSubtitle {} }
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: left; }

/*
 * priceBox
 */

div.priceBox {
    display: table;
    width: 100%;
    margin: 10px auto; }

section.dark {
    .cardContainer {
        div.priceBox {} }
    color: #fff; }

div.priceBox {
    > div {}
    display: table-row; }

div.priceBox {
    > div {
        > div {} }
    display: table-cell;
    padding: 3px 0px; }

div.priceBox {
    > div {
        > div.price {} }
    font-weight: 600;
    font-size: 18px;
    line-height: 27px; }

div.priceBox {
    > div {
        > div.price-dollars {} }
    color: #000; }

section.dark {
    .cardContainer {
        div.priceBox {
            > div {
                > div.price-dollars {} } } }
    color: #fff; }

div.priceBox {
    > div {
        > div.price-crypto {} }
    color: #FFD600; }


.buttonContainer {
    display: flex;
    justify-content: space-evenly; }

.buttonContainer {
    .buttonWrapper {
        button {} }
    padding: 20px 50px; }

.buttonWrapper {
    margin: 40px auto; }


.top {
    display: flex;
    align-items: center;
    padding-bottom: 32px;
    @include t {
        display: block;
        margin-bottom: 32px;
        padding-bottom: 0;
        border: none; }
    @include m {
        margin-bottom: 24px; }
    //+dark
    border-color: $neutrals3; }

.title {
    margin-right: auto;
    @include body-1;
    @include t {
        display: none; } }

.search {
    display: block;
    position: relative;
    flex-shrink: 0;
    width: 320px;
    margin-left: 30px;
    margin-right: 20px;
    @include t {
        width: 100%;
        margin: 0; }
    @include m {
        display: block;
        margin: 0; }
    h3 {
        color: black;
        font-size: 28px;
        margin-bottom: 5px; } }
.search_text {
    display: none;
    @include m {
        display: block; } }
.input {
    width: 100%;
    height: 48px;
    padding: 0 48px 0 14px;
    border-radius: 12px;
    background: none;
    border: 1px solid #AEAEAE;
    @include font-inter;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    //+dark
    border-color: #aeaeae;
    color: black;
    @include placeholder {
        color: $neutrals4; }
    &:focus {
        border-color: $neutrals4; } }

.result {
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: $blue;
    transition: background .2s;
    svg {
        fill: $neutrals8; }
    &:hover {
        background: darken($blue, 10); } }

.sorting {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    @include t {
        display: block; }
    @include m {
        margin-bottom: 32px; }
    .dropdown {
        width: 256px;
        margin-right: auto;
        @include t {
            width: 100%;
            margin: 0 0 32px; } } }

.nav {
    display: flex;
    @include m {
        margin: 0 -32px;
        overflow: auto;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none; }
        &:before,
        &:after {
            content: "";
            flex-shrink: 0;
            width: 32px;
            height: 1px; } } }

.link {
    flex-shrink: 0;
    margin: 0 6px;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    @include m {
        margin: 0; }
    &:hover {
        color: $neutrals3;
        //+dark
        color: $neutrals6; }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        //+dark
        background: $neutrals8;
        color: $neutrals2; }
    &:not(:last-child) {
        @include m {
            margin-right: 12px; } } }

.row {
    display: flex;
    @include t {
        display: block; } }

.wrapper {
    flex-grow: 1;
    padding-bottom: 20px;
    height: auto;
    overflow: hidden;
    position: relative;

    .list {
        height: auto;
        overflow: hidden;
        margin-bottom: 40px;
        background-color: #e6e6e6;
        //+dark
        background-color: white;
        @include m {
            padding: 14px; } }
    @include t {
        padding-left: 0;
        padding-right: 0; } }

.filters {
    flex-shrink: 0;
    width: 256px;
    margin-right: 10px;
    @include t {
        width: 100%;
        margin-bottom: 32px; } }

.range {
    margin-bottom: 24px; }

.scale {
    display: flex;
    justify-content: space-between;
    font-weight: 500; }

.group {
    margin-bottom: 24px;
    padding: 24px 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: $bordergrey;
    //+dark
    border-color: $bordergrey; }

.item {
    &:not(:last-child) {
        margin-bottom: 24px; } }

.label {
    font-weight: 700;
    margin-bottom: 12px;
    @include hairline-2;
    color: $neutrals1;
    //+dark
    color: $darktext; }

.reset {
    display: inline-flex;
    align-items: center;
    @include button-2;
    cursor: pointer;
    transition: color .2s;
    svg {
        margin-right: 8px;
        fill: $neutrals2;
        transition: fill .2s;
        //+dark
        fill: $neutrals8; }
    &:hover {
        color: #766CCC;
        svg {
            fill: $blue; } } }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: 12px 12px;
    padding: 12px !important;
    box-shadow: unset !important;

    margin-bottom: 16px;
    padding-bottom: 20px;
    padding-top: 20px;
    @include m {
        display: block;
 } }        // margin: 0 0 36px 0

.card {
    flex: 0 0 calc(33.333% - 20px);
    max-width: calc(33.333% - 20px);
    max-height: 380px;
    margin: 4px 9px 23px;
    padding: 15px;
    // box-shadow: 0px 0px 3px 3px rgba(37, 47, 63, 0.12)
    border-radius: 25px;
    transition: transform .1s;
    background: #1e2429;
    &:hover {
        border: 1px solid $newblack; }
    @include r(879) {
        flex: 0 0 calc(50% - 32px); }
    @include t {
        max-width: calc(50% - 32px); }
    @include m {
        max-width: 100%;
        margin: 4px 4px;
        &:not(:last-child) {
            margin-bottom: 32px; } }
    //+dark
    box-shadow: 0px 0px 14px 0px #00000040;
    background: white; }

.btns {
    margin-top: 32px;
    text-align: center; }

.filterRangeContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    margin: 10px 0px; }

.maxmin {
    text-align: left;
    width: 120px;
    // border: 2px solid yellow
    padding: 10px;
    border-radius: 5px;
    background: $neutrals8;
    // color: $neutrals1
    ::placeholder {
        color: $placeholdertext; }
    @include t {
        width: 100%; }
    //+dark
    background: transparent;
    // box-shadow: inset 0 0 0 2px #353945
    border: 1px solid $bordergrey;
    color: $neutrals8; }

.rangeByInput {
    @include m {
        flex: 1 1 auto; } }

margin {
    text-align: center;
    padding: 5px;
    width: 44%;
    border-radius: 12px;
    background: none;
    border: 2px solid $neutrals6;
    @include font-inter;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    //+dark
    border-color: $neutrals3;
    color: $neutrals8;
    @include placeholder {
        color: $neutrals4; }
    &:focus {
        border-color: $neutrals4; } }

.reset_done_btn_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center; }


.done {
    @include button-2;
    color: $black;
    &:hover {
        color: $blue; } }

.filter_btn {
    @include font-inter;
    position: fixed;
    bottom: 53px;
    width: 156px;
    margin-left: -78px;
    left: 50%;
    z-index: 10;
    font-weight: 600;
    background-color: #EE1F78;
    border-radius: 35px;
    color: $white;
    padding: 10px;
    letter-spacing: 1px;
    font-size: 14px;
    &:hover {
        color: #ffffff;
        background: #000000; } }


.filter_drawer_wrap {
    padding: 10px 20px;
    height: 100vh;
    //+dark
    background-color: $neutrals1;
    position: fixed; }

.filter_drawer_wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    z-index: 999;
    @include d {
 }        // flex-grow: 0
    @include m {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        align-items: stretch;
        height: 100vh;
        margin: 0;
        padding: 66px 32px 40px;
        box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.2);
        background: $neutrals8;
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        //+dark
        background: white;
        &.active {
            visibility: visible;
            opacity: 1; } }
    .button {
        display: none;
        @include m {
            display: inline-flex;
            margin-top: 16px; } } }

.nav {
    display: flex;
    margin-right: auto;
    border-left: 2px solid $neutrals6;
    @include d {
 }        // display: none
    @include m {
        display: flex;
        flex-direction: column;
        margin: 0 0 40px;
        border: none; }
    //+dark
    border-color: $neutrals3; }

.loaderContent {
    width: 100%;
    position: fixed;
    height: 100vh;
    background: #d5d5d5e8;
    z-index: 9999;
    top: 0;
    left: 0;
    // bottom: 0
    // right: 0
    display: flex;
    align-items: center;
    justify-content: center; }

.filterClose {
    position: absolute;
    top: 20px;
    right: 35px;
    font-size: 30px;
    font-weight: normal;
    cursor: pointer;
    z-index: 999; }

.toolTip {
    position: relative;
    display: inline;
    &:hover {
        .toolTipContent {
            display: block; } }
    .toolTipContent {
        display: none;
        position: absolute;
        width: 180px;
        background: #000000eb;
        left: 20px;
        top: -5px;
        z-index: 9;
        border-radius: 6px;
        color: white;
        padding: 10px;
        text-transform: none;
        font-weight: normal;
        line-height: 15px;
        &::after {
            position: absolute;
            content: '';
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-right: 6px solid #000000eb;
            left: -5px;
            top: 8px; } } }

.thumbnailImage {
    width: 100%;
    max-width: 250px;
    overflow: hidden;
    //aspect-ratio: 1
    //object-fit: contain
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    //+s
 }    //    max-width: 100%

.dropdowBtn {
    display: flex;
    align-items: center;
    user-select: none;
    padding: 5px 5px;
    cursor: pointer; }

.dropdownIcon {
    background-color: #fff;
    border: #E6E8EC 2px solid;
    border-radius: 30px;
    height: 30px;
    width: 30px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    img {
        margin: unset;
        user-select: none; } }

.dropdownOptions {
    background-color: rgb(252, 252, 253);
    border-radius: 12px;
    border: 2px solid #E6E8EC;
    color: gray;
    max-height: 0;
    box-sizing: border-box;
    visibility: hidden;
    padding: 0;
    margin: 0;
    text-transform: none;

    label {
        padding: 8px 0px;
        color: #23262F;
        padding-left: 10px;
        border-radius: 8px;
        cursor: pointer;

        .dropdowBtn {
            text-transform: none; }

        &.dropdownOption {
            padding: 12px 0px;
            color: #23262F;
            padding-left: 10px;
            border-radius: 8px;
            cursor: pointer;
            text-transform: none;
            font-size: 13px;
            font-weight: 500; } } }

input {
    &:checked {
        + .dropdownOptions {
            margin-top: 10px;
            padding: 5px;
            transition: 0.4s;
            visibility: visible;
            max-height: 300px; } } }

label {
    .dropdowBtn {
        text-transform: none;
        font-size: 14px;
        font-weight: 500; } }

.assetsContainer {
    border: 0px solid blue;
    flex-basis: 73%;
    //margin-left: 25px // now using gap instead
    //+m
 }    //    margin-left: 0px

.filterContainer {
    border: 0px solid blue;
    flex-basis: 24%;
    flex-grow: 1; }

//.searchMain
//    padding-top: 88px
//    +m
//        padding-top: 120px

.assetsList {
    border: 0px solid blue;
    flex-basis: 75%;
    //margin-left: 25px
    @include m {
        flex-basis: 100%;
        margin-left: 0px; } }

.assetsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--color-pricipal);
    gap: 10px;
    justify-content: space-between;
    margin-top: 20px; }

.assetContainer {
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    padding: 0px;
    padding-top: 5px;
    margin-bottom: 30px;

    @include s {
        width: 90%; }

    .price {
        font-size: 14px; } }

.noAsset {
    padding: 6px 12px;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    color: rgb(102, 102, 102);
    border: var(--color-gray-400) 2px solid; }

.filterLine {
    height: 1px;
    border: none;
    background-color: rgb(230, 232, 236);
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0px; }

.resetBtn {
    padding: 5px 10px;
    border: 0px;

    img {
        width: 20px;
        height: 20px;
        vertical-align: bottom; }

    .resetText {
        vertical-align: middle;
        padding-left: 10px; } }



@media only screen and (max-width: 480px) {
    .searchPage {
        padding-top: 116px;

        .assetsContainer {
            width: 100%;
            margin-top: 20px;
            flex: 1 1 auto;
            margin-left: 0;
            padding: 12px; }

        .thumbnailImage {
            max-width: unset; } } }
