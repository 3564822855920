@import "./helpers";

.pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    list-style: none;
    margin-top: 48px;
    margin-bottom: 24px;

    @include s {
        gap: 5px; }

    a {
        padding: 10px;
        border: 2px solid #E6E8EC;
        color: #000;
        font-weight: 500;
        border-radius: 10px;
        cursor: pointer;
        border-radius: 30px;

        &.pagination__link__previous {
            padding: 10px 25px;
            position: relative;

            @include s {
                width: 40px;
                height: 40px;
                padding: 0;
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &::before {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    background: green;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    content: "<";
                    background: white; } } }

        &.pagination__link__next {
            padding: 10px 25px;
            position: relative;

            @include s {
                width: 40px;
                height: 40px;
                padding: 0;
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &::before {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    content: ">";
                    background: white; } } } }

    li {
        display: flex;
        align-items: center; } }

.pagination__link {
    font-weight: bold; }

.pagination_page {
    a {
        box-sizing: border-box;
        min-width: 40px;
        height: 40px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px; } }

.pagination__link__active a {
    color: #A28A00;
    border-color: #A28A00;
    cursor: pointer; }

.pagination__link__disabled a {
    color: #ccc;
    border: 2px solid #ccc;
    cursor: context-menu; }
