@import "../../styles/helpers";
.checkout {
    width: 500px;
    margin: auto;
    position: relative;
    @include m {
        width: 100%; }
    h2 {
        color: #231F20; }
    .addCard {
        padding: 10px;
        border-image-slice: 1 !important;
        border-width: 2px;
        transition: 0.6s;
        border-radius: 25px !important;
        transition: 0.3s ease-in-out;
        background: #A28A00;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 900;
        position: absolute;
        top: 0;
        right: 0;
        &:hover {
            background: #231F20;
            color: #ffffff; } } }


.formGroup {
    width: 100%;
    display: block;
    margin: 10px 0px;

    label {
        display: block;
        color: #231F20; }
    .formControl {
        width: 100%;
        padding: 13px;
        border-radius: 6px;
        background: #f7f7f7;
        border: 1px solid #ddd; }

    .formBtn {
        width: 100%;
        padding: 13px;
        // border: 2px solid #A28A00;
        border-image-slice: 1 !important;
        border-width: 2px;
        transition: 0.6s;
        border-radius: 25px !important;
        transition: 0.3s ease-in-out;
        background: #A28A00;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 900;
        margin-top: 15px;
        &:hover {
            background: #231F20;
            color: #ffffff; } } }



.addCardModal {
    width: 500px;
    background: #ffffff;
    min-height: 200px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 6px;
    color: #231F20;
    position: relative;
    @include m {
        width: 100%; }
    h2 {
        text-align: center; }

    .close {
        position: absolute;
        top: 5px;
        right: 10px;
        color: #231F20;
        font-size: 20px; } }

.modalDialog {
    overflow: auto;
    height: 100vh; }
.row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .col {
        width: calc(100% /2 - 10px);
        @include m {
            width: 100%; } } }
.error {
    color: #ff0000 !important;
    display: block;
    text-align: left; }


