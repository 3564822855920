.cart-page {

    //padding-top: 92px;
    //font-size: 14px;

    .mobile {
      display: none;
    }

    .d-flex {
      display: flex;
    }

    .btn {
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 7px 0px rgba(128, 128, 128, 0.236);
      }
    }

    .cart-item-right {
      display: flex;
      //margin-left: auto;
      //margin-right: 12px;

      span {
        font-size: 20px;
        //padding-right: 36px;
        white-space: nowrap;
      }
    }

    .container {
    
        //padding: 40px 0;

        .back-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 162px;
          gap: 10px;
          padding: 8px 16px;
          border-radius: 35px;
          border: 2px solid #E6E8EC;
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 7px 0px rgba(128, 128, 128, 0.236);
          }

          img {
            padding-bottom: 1px;
            vertical-align: top;
          }

          p {
            font-size: 14px;
            font-weight: 600;
          }
      }

      .cart-title {
          font-size: 48px;
          font-weight: 500;
          line-height: 56px;
      }

      .cart-description {
          color: #777E91;
          margin-bottom: 24px;
      }

      .cart-main {
          display: flex;
          gap: 20px;

          .left {
              flex: 1;
              width: 60%;

              hr {
                margin-bottom: 24px;
              }

              .item-type {
                  font-size: 16px;
                  margin: 12px 0;

                  b {
                    font-weight: 600;
                  }

                  p {
                    color: #919191;
                  }
              }


              .cart-item {
                  display: flex;
                  align-items: center;
                  //gap: 15px;
                  row-gap: 8px;
                  padding: 18px 16px;
                  //padding: 10px 16px;
                  //margin-bottom: 20px;
                  border-radius: 15px;
                  //background-color: #FCFCFD;
                  background-color: var(--color-gray-50);
                  transition: 0.3s;
                  flex-wrap: wrap;
                  //justify-content: space-between;
                  justify-content: flex-start;

                  &:hover {
                    box-shadow: 0 0 10px 0px rgba(128, 128, 128, 0.236);
                    background-color: var(--color-gray-100);
                  }

                  .item-img {
                      height: 64px;
                      width: 64px;
                      border-radius: 15px;
                      overflow: hidden;

                      img {
                        width: 100%;
                        height: auto;
                        vertical-align: top;
                        object-fit: contain;
                      }
                  }

                  .item-info {
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      //padding: 8px 0px;

                      //.item-name {
                      //  font-size: 16px;
                      //  font-weight: 500;
                      //}

                      .item-price {
                        font-weight: 500;
                      }

                      .item-date {
                        font-size: 12px;
                        color: #777E91;
                      }
                  }

                  .delete-item-btn {
                      display: flex;
                      align-items: center;
                      justify-self: flex-end;
                      //margin-left: auto;
                      justify-content: space-between;
                      cursor: pointer;
                      fill: var(--color-gray-500);

                      span {
                        font-size: 20px;
                      }

                      svg {
                        height: 20px;
                        width: 20px;
                      }

                      &:hover {
                        box-shadow: unset;

                        svg {
                            transition: 0.3s;
                            //fill: #A28A00;
                            fill: var(--color-theme-primary);
                        }
                      }

                      img {
                        height: 30px;
                        width: 30px;
                      }
                    }
                }
            }

            .right {
                width: 40%;
                max-width: 363px;

                .cart-info {
                    padding: 10px 20px;
                    background-color: #FCFCFD;
                    background-color: var(--color-gray-50);
                    border-radius: 15px;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    // position: sticky;
                    // top: 100px;

                    .summary-text {
                      font-size: 24px;
                      font-weight: 600;
                    }

                    .cart-note {
                      color: #777E91;
                    }

                    .order-total {
                      font-size: 20px;
                      display: flex;
                      justify-content: space-between;

                      p {
                        font-weight: 500;
                      }

                      b {
                        font-weight: 600;
                      }
                    }

                    .checkout-btn {
                      color: white;
                      background: #A28A00;
                    }

                    .checkout-btn,
                    .send-gift-btn {
                      font-size: 16px;
                      height: 48px;
                      border-radius: 35px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }

                    .send-gift-btn {
                      gap: 15px;
                      margin-top: 12px;
                      color: #A28A00;
                      box-sizing: border-box;

                      img {
                        width: 20px;
                        height: 20px;
                        vertical-align: top;
                      }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1028px) {
    .cart-page {
        .container {
            padding: 0 16px;
            //padding-top: 118px;
            box-sizing: border-box;
        }
    }
}

@media only screen and (max-width: 768px) {
    .cart-page {

        /*padding-top: 208px;*/

        .mobile {
          display: block;
        }

        .container {
            max-width: 100%;
            //padding: 24px 16px;
            padding-left: 16px;
            padding-right: 16px;
            box-sizing: border-box;

            .cart-main {
                gap: 0;
                flex-direction: column;

                .right,
                .left {
                  width: 100%;
                  flex: 1;
                }

                .right {
                  max-width: unset;

                  .cart-info {
                    .order-total {
                      font-size: 16px;
                    }
                  }
                }
            }
        }
    }
}

//@media only screen and (max-width: 687px) {
//  .cart-page {
//    .container {  
//      padding-top: 44px;
//    }
//  }
//}

@media only screen and (max-width: 480px) {
    .cart-page {
        //padding-top: 108px;
        .cart-item-right {
            display: flex;
            //margin-left: auto;
            //margin-right: 12px;
            span {
                font-size: 20px;
                padding-right: 16px;
            }
        }
    }
    //.cart-page .container .cart-main .left .cart-item {
    //    justify-content: space-around
    //}

}
