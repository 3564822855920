@import "../../styles/helpers";
.title {
    margin-right: auto;
    @include body-1; }
.referWrapper {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    //width: 712px
    margin: 40px auto;
    //padding: 50px 0px
    @include m {
        //width: 95%
        padding: 40px 20px; }

    h2 {
        @include font-lato;
        font-weight: 700;
        font-size: 30px;
        line-height: 34px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin-bottom: 15px;
        &.dflexCenter {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%; } }
    p {
        @include font-lato;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #8A8A8A;
        //width: 80%
        margin: 20px auto;
        @include m {
            width: 100%; } }
    label {
        @include font-lato;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: left;
        color: #8A8A8A; }

    .referCopy {
        width: 100%;
        height: 50px;
        //border: 1px solid #000000
        margin-bottom: 20px;
        display: flex;
        input {
            border: none;
            outline: none;
            width: 80%;
            background: #ffffff;
            height: 48px;
            @include font-lato;
            font-weight: 600;
            font-size: 24px;
            text-align: left;
            color: #3F3C3C;
            padding: 10px 20px;
            @include m {
                width: 60%; } }

        button {
            @include font-lato;
            width: 20%;
            background: #DB3A78;
            height: 48px;
            padding: 8px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            font-weight: 700;
            font-size: 18px;
            &:hover {
                background: #000000; }
            @include m {
                width: 40%; } } } }

.walletContainer {
    border-bottom: 1px solid #9F9F9F;

    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        font-size: 15px;
        margin: 7px 0px; } }

.profileAndImage {

    display: flex;
    align-items: center;
    column-gap: 3px;
    @include m {
        flex-direction: column; } }

.profilePicture {
    display: flex;
    margin: 0px;
    width: 18px;
    height: 17px;
    img {
        border-radius: 50%;
        height: 100%;
        width: 100%; } }

.address {

    display: flex;
    max-width: 120px;
    width: 100%;
    align-items: center; }
.balanceArea {
    margin-top: 30px;
    text-align: center;
    height: 100%;
    background-color: #F6F6F6;
    padding: 20px 0px;

    p {
        margin: 0px auto; }
    h2 {
        margin-top: 20px;
        margin-bottom: 0px;
        &.m0 {
            margin: 0; } } }

.transferButton {
    width: 100%;
    margin: 20px 0px;
    background-color: #DB3A78;
    color: white;
    padding: 12px;
    font-weight: 700;

    &:hover {
        background-color: #000000; } }

.modalDialog {
    overflow: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.kyc {
    background-color: white;
    border-radius: 4px;
    max-width: 437px;
    padding: 10px 10px;
    text-align: center;
    @include m {
        max-width: 100%;
        margin: 10px; }

    .closeButton {
        text-align: right;
        margin-bottom: 20px;
        cursor: pointer;
        display: flex;
        justify-content: flex-end; }
    .kycContainer {
        padding: 10px 50px 50px 50px; }

    hr {
        margin: 20px 0px;
        border: 1px solid #9F9F9F; }



    p {

        color: #3F3C3C;
        font-weight: 700; }
    h2 {
        color: black; }


    .verifyButton {
        margin-top: 20px;
        background: #DB3A78;
        color: white;
        border-radius: 24px;
        font-weight: 600;
        padding: 10px 20px;
        &:hover {
            background: #000000; } }

    .inputArea {
        background-color: #F6F6F6;
        text-align: left;
        padding: 10px;

        .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0px;
            h3 {
                color: black;
                font-size: 18px; } }
        .price {
            color: #8A8A8A;
            margin: 20px 0px 10px 0px; }
        .error {
            width: 100%;
            display: block;
            color: #f00;
            font-size: 12px;
            margin-top: 3px; }
        .textbox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #FFFFFF;
            border: 1px solid #000000;
            padding: 5px;
            input {
                font-weight: 600;
                margin-left: 2px;
                width: 100%; } } } }

.loaderContent {
    width: 100%;
    position: fixed;
    height: 100vh;
    background: #d5d5d5e8;
    z-index: 9999;
    top: 0;
    left: 0;
    // bottom: 0
    // right: 0
    display: flex;
    align-items: center;
    justify-content: center; }
.wrapper {
    height: 200px;
    padding: 30px 0px; }

.truliooBg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000075;
    z-index: 9;
    width: 100%;
    height: 100vh;

    img {
        margin: 20px 10px;
        cursor: pointer; } }


.kycVerified {
    margin-left: 7px;
    max-width: 42px; }

.dflex {
    display: flex;
    align-items: center;
    width: 50%; }


