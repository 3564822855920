@import "../../styles/helpers";

.section {
    padding: 18px;
    scroll-behavior: smooth;
    @include x {
        padding: 20px 0; }
    @include m {
        padding: 24px 0; } }

.orderDetailsPage {
    //padding-top: 88px !important
    width: unset;

    .thumbnailImage {
        width: 100%;
        aspect-ratio: 1;
        height: auto;
        object-fit: contain; } }

.viewBtnGroup {
    display: flex;
    justify-content: center;
    align-items: center;

    .viewBtn {
        background-color: #A28A00;
        padding: 7px 15px;
        border-radius: 5px;
        font-weight: 400; } }


.orderitem {
    border-radius: 6px;
    border: 1px solid #ccc;
    margin: 18px 0px;
    display: flex;
    flex-wrap: wrap;
    @include s {
      justify-content: center; }
    .playerwrapper {
        width: 10%;
        min-width: 98px;
        height: 110px;

        margin-top: inherit;
        img {
            height: 100%;
            border-radius: 10px; } }

    .itemdetails {
        padding-left: 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        @include m {
            font-size: 10px;
            padding-left: 1px;
            min-width: 270px;
            button, a {
                height: 30px;
                padding: 0px 10px;
                font-size: 10px; }
            button {
              @include s {
                position: absolute;
                bottom: -15px;
                right: -15px;
                width: 100px; } }
            a {
              @include s {
                position: absolute;
                left: 0;
                margin: 0px;
                bottom: 0px;
                right: unset;
                width: 140px; } } }
        @include s {
            position: relative; }
        h4:first-of-type {
            width: 100px; }
        .itemdetailsrow {
            display: flex;
            justify-content: space-between;
            padding: 15px 10px;
            flex-wrap: wrap;

            a {
              @include t {
                margin-left: auto !important; } }
            div {
              text-align: center;

              @include s {
                flex-basis: 50%;
                margin-bottom: 15px; } }


            div:nth-child(1),div:nth-child(2) {
              padding-bottom: 10px; } }

        .itemdetailsrow:first-of-type {
            border-bottom: 1px solid #ccc; } } }

.pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    list-style: none;
    margin-top: 48px;
    margin-bottom: 24px;

    a {
        padding: 10px;
        border: 2px solid #E6E8EC;
        color: #000;
        font-weight: 500;
        border-radius: 10px;
        cursor: pointer;
        border-radius: 30px;
        &.pagination__link__previous {
            padding: 10px 25px; }
        &.pagination__link__next {
            padding: 10px 25px; } }

    li {
        display: flex;
        align-items: center; } }

.pagination__link {
    font-weight: bold; }

.pagination_page {
    a {
        box-sizing: border-box;
        min-width: 40px;
        height: 40px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px; } }

.pagination__link__active a {
    color: #A28A00;
    border-color: #A28A00;
    cursor: pointer; }

.pagination__link__disabled a {
  color: #ccc;
  border: 2px solid #ccc;
  cursor: context-menu; }

.bold {
  font-weight:bold!important {} }

.button {
  color: $newblack;
  border: 2px solid $newyello !important;
  background-color: $newyello;
  border-radius: 25px;
  &:hover {
    background-color: $newblack;
    color: $newyello;
    border: 2px solid $newblack !important; } }
.loaderContent {
    width: 100%;
    position: fixed;
    height: 100vh;
    background: #d5d5d5e8;
    z-index: 9999;
    top: 0;
    left: 0;
    // bottom: 0
    // right: 0
    display: flex;
    align-items: center;
    justify-content: center; }

.orderList {
    //margin-top: 50px

    .orderListItem {
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        gap: 15px;
        background: #FCFCFD;
        border-radius: 15px;
        margin-bottom: 20px;
        cursor: pointer;

        &:hover {
            background: #F4F5F6; }

        .thumbnail {
            width: 64px;
            height: 64px;

            img {
                object-fit: contain;
                border-radius: 15px;
                aspect-ratio: 1;
                background: white; } }

        .info {
            .name {
                font-size: 16px;
                font-weight: 600; }
            .price {
                font-size: 14px;
                font-weight: 500; }
            .time {
                font-size: 12px;
                color: #777E90; } }
        .viewIcon {
            margin-left: auto;
            display: flex;
            align-items: center;
            svg {
                height: 34px;
                width: 34px;
                fill: #777E90; } } } }

.orderStatus {
    margin-right: 12px;
    padding: 4px 12px;
    border-radius: 30px;
    color: white; }

.ordersTable {
    overflow-x: auto;
    table {
        border-spacing: 0 20px;
        border-collapse: unset; }

    thead {
        background: #FCFCFD;

        th {
            //padding: 12px
            //font-size: 14px
            //font-weight: 500
            //border-radius: 10px
            //font-style: normal
            //color: #212529
            color: var(--color-gray-900, #212529);
            font-family: 'Source Sans 3';
            font-size: 18px;
            font-weight: 700;
            line-height: 150%;
            padding: 13px 30px;
            background: #E9ECEF;
            background: var(--color-gray-200, #E9ECEF); } }

    tbody {
        tr {
            cursor: pointer; }

        th {
            padding: 12px;
            font-weight: 600;
            border-radius: 10px;
            vertical-align: middle; }

        .orderId {
            font-size: 16px;
            padding-left: 12px; }

        td {
            padding: 38px 12px;
            font-size: 12px;
            font-weight: 500;
            vertical-align: middle;
            background: #F4F5F6;

            &:first-child {
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px; }

            &:last-child {
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px; }

            > div {
                display: flex;
                align-item: center; } } }

    .flexLeft {
        text-align: left; }

    .flexRight {
        text-align: right; }

    .flexCenter {
        text-align: center; }

    .orderAsset {
        padding: 2px 0;
        min-width: 100px;
        text-transform: uppercase; } }

@media only screen and (max-width: 480px) {
    .orderitem {
        .itemdetails {
            justify-content: center;
            .itemdetailsrow {
                justify-content: center;
                bottom: 0;
                right: unset; }
            button {
                bottom: 0;
                right: unset; } } }

    .orderDetailsPage {
        padding-top: 136px !important; } }
