@media only screen and (max-width: "610px") {
    .hero-container .hero-title {
        font-weight: 400;
        font-size: 24px;
 } }        //line-height: 32px

//
// Mobile and Tablet View - 1024 pixels or less
//
@media only screen and (max-width: $desktop-min) {

    main.item-main section.property-information div.max-width div.property-information .about-section p {
        padding: 10px 50px 25px 50px; }

    main.item-main section.property-information div.max-width div.property-information .about-section div.about-content > div {
        padding: 10px 50px 25px 50px; }


    main.item-main.about section.property-information div.max-width .property-information .vision-mission p {
        padding-left: 25px;
        padding-right: 25px; }

    main.item-main section.property-information div.max-width div.property-information .about-section2 p {
        padding-left: 25px;
        padding-right: 25px; }

    main.item-main section.property-information div.max-width div.property-information .about-section h3 {
        font-size: 24px; }

    //main.item-main section.property-information div.max-width div.property-information .about-section
    //    margin-top: 100px

    main.item-main.about section.property-information div.max-width .property-information h2 {
        font-size: 32px; }

    .cart-page .cart-main.flex-container .title {
        font-size: 13px; }

    main.item-main.about section.property-information div.max-width .property-information .vision-mission {
        //margin: 15px 0px
        //margin: 15px 50px
        margin: 15px 25px;
        //padding: 15px 25px
        padding: 15px 25px 20px 25px;
 }        //padding: 15px 50px 20px 50px

    main.item-main section.property-information div.max-width div.property-information {
        padding: 20px 0px; }

    main.item-main section.property-information div.max-width div.property-information ul {
        margin-left: -40px; }

    header .logo-container {
        padding-left: 30px; }

    main.edit-profile section.form .flex-container {
        //padding: $tablet-padding-top $tablet-padding-left
        padding: 20px 20px;
 }        //margin: 60px 0px

    header .header-container-mobile {
            display: flex;
 }            //padding: 0

    header .header-container-desktop {
            display: none; }

    body main {
        font-size: $tablet-font-size;
        padding-top: $main-padding-top-mobile; }

    #google-maps-wrapper {
        height: calc(100vh - 400px); }

    .filter-section {
        column-gap: 15px;
        row-gap: 15px; }

    #what-is-realsplit-container, #latest-arrivals-wrapper, #start-investing-today-wrapper, #team-wrapper, #item-wrapper, .edit-profile .form .max-width, .property-information .max-width, main > section > div.container.max-width {
        //padding-left: 15px
        padding-left: $tablet-padding-left;
        //padding-right: 15px
        padding-right: $tablet-padding-left; }

    main.item-main > * {
        //padding-left: 15px
        //padding-right: 15px
        padding-left: 25px;
        padding-right: 25px; } }

//
// Mobile view - screens with a width of 480 pixels or less.
//
@media only screen and (max-width: $mobile-max) {

    main.item-main section.property-information div.max-width div.property-information .about-section .about-section-circle {
        border: rgba(2, 91, 201, 0.25) 1px solid;
        box-shadow: var(--color-gray-400) 0px 2px 4px 0px; }

    main.item-main section.property-information div.max-width div.property-information .about-section p {
        padding: 10px 25px 25px 25px; }

    main.item-main section.property-information div.max-width div.property-information .about-section div.about-content > div {
        padding: 10px 25px 25px 25px; }

    main.item-main section.property-information div.max-width div.property-information .about-section h3 {
        font-size: 20px; }

    main.item-main.about section.property-information div.max-width .property-information h2 {
        font-size: 28px; }

    //main.item-main section.property-information div.max-width div.property-information .about-section.about-vision-mission
    main.item-main section.property-information div.max-width div.property-information .about-section {
        border: 0px;
 }        //margin-top: 100px

    .about-asset-management-container {
        padding: 0px; }

    .search-results .assets-container .asset-container div.preview-image {
        width: 100%; }

    .search-results .assets-container h4.no-results {
        width: 100%;
        min-width: 200px; }

    .edit-profile .form .heading {
        padding-left: 0px; }

    .edit-profile .form .subtitle {
        padding-left: 0px;
        padding-right: 0px; }

    //main.edit-profile section.form .flex-container
    //    padding: 10px 0px

    //main.item-main section.property-information ol
    //    margin-left: -40px

    main.item-main section.property-information ol li.ql-indent-1 {
        margin-left: 20px; }

    main.item-main.about section.property-information div.max-width .property-information .vision-mission {
        margin: 15px 0px;
        //padding: 15px 20px
        padding: 15px 25px 20px 25px; }

    main.item-main section.property-information div.item-images div.item-images-container {
        gap: 20px; }

    main.item-main section.property-information div.item-images div.item-image {
        flex-basis: unset; }

    main.edit-profile section.form form .flex-container {
        margin: 30px 0px; }

    //body main
    //    padding-top: $main-padding-top
    //  //padding-top: $main-padding-top-mobile
    .edit-profile .form .heading {
        padding-top: 0px; }

    .edit-profile .form .subtitle {
        padding-top: 20px; }

    //main.edit-profile section.form
    //.form

    header .logo-container {
        padding: 20px 0px 20px 20px; }

    //main.edit-profile section.form .flex-container > div
    //    flex: 1 1 100%
    //  //flex-basis: 50%

    //#what-is-realsplit-container, #latest-arrivals-wrapper, #start-investing-today-wrapper, #team-wrapper, #item-wrapper, .edit-profile .form .max-width, .property-information .max-width
    //#what-is-realsplit-container, #latest-arrivals-wrapper, #start-investing-today-wrapper, #team-wrapper, .edit-profile .form .max-width, .property-information .max-width
    #what-is-realsplit-container, #latest-arrivals-wrapper, #start-investing-today-wrapper, #team-wrapper, .edit-profile .form .max-width, main > section > div.container.max-width {
        padding-left: $mobile-padding-left;
        padding-right: $mobile-padding-left; }

    section.video-section > div.container.max-width {
        padding-left: 0px;
        padding-right: 0px; }

    section.video-section > div.container h2 {
        padding-left: $mobile-padding-left;
        padding-right: $mobile-padding-left; }

    #item-wrapper, .property-information .max-width {
        padding-left: 0px;
        padding-right: 0px; }

    main.edit-profile section.form form .flex-container {
        padding: $mobile-padding-top $mobile-padding-left; }

    header .header-container-mobile {
            display: flex;
            flex-wrap: nowrap;
 }            //padding: 0

    header .header-container-mobile > div {
        flex-basis: 50%; }

    header .header-content.header-right .mobile-nav-button:last-child {
        padding-right: 20px; }

    header .header-content.header-right .mobile-nav-button {
        padding: 0px 10px; }

    header .header-container-desktop {
            display: none; }

    footer div.container > div {
        width: 100%;
 }        //flex-basis: 100vw

    footer div.container div.footer-links {
        padding-left: 40px;
        padding-right: 40px; }

    footer div.max-width div.social-icons img {
        padding-left: 20px;
        padding-right: 20px; }

    body main {
        font-size: $mobile-font-size; }

    main section div.max-width h1 {
        font-size: $mobile-font-size-h1; }

    main section div.max-width h2 {
        font-size: $mobile-font-size-h2; }

    div:where(.swal2-container) .swal2-popup h2:where(.swal2-title) {
        //font-size: 18px
        font-size: $mobile-dialog-font-size-h2; }

    // Hide the Google maps in mobile mode.
    //#google-maps-wrapper, #radius-range
    //    display: none

    #google-maps-wrapper {
        height: calc(100vh - 400px); }
    //    display: none
    //    visibility: hidden
    //    width: 1px
    //    height: 1px
    //    flex-basis: unset

    // Moved to the media query above (max-width $desktop-min) to capture both mobile and tablet views.
    //#what-is-realsplit-container, #latest-arrivals-wrapper, #start-investing-today-wrapper
    //    padding-left: 15px
    //    padding-right: 15px

    header.light {
        //border-bottom: #A28A00 2px solid
        border-bottom: 1px solid var(--theme-border, #DEE2E6); }

    .responsive-container {
        border: red 2px solid;
 }        //.max-width: $mobile-max

    main.home section.top-section {
        background-position: center center;
        //background-image: url(../assets/images/top-section-background.png)
        //background-image: url(../assets/images/hero-image.jpg)
        //background-image: url(../assets/images/backgrounds/Hero-Background-Mobile.png)
        background-image: url(../assets/images/deals/Hillcrest/Hillcrest-main.jpg);
        height: 100vh;
 }        //background-image: url(/images/backgrounds/Hero-Background-Mobile.png)

    //#filter-section-wrapper
    //    margin-left: 15px
    //    margin-right: 15px

    body main.search #filter-section-wrapper #filter-section .filter-section .title {
        display: none; }

    .filter-section {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px; }

    .filter-section > div {
        //flex-grow: 1
        max-width: unset; }

    //.filter-section div.title
    //    flex-basis: 50%
    //    text-align: left
    //  //margin-bottom: 18px

    //.filter-section div.search
    //    margin-top: 18px
    //    margin-bottom: 18px

    //.filter-section div#select-property-type
    //    margin-top: 18px

    .hero-heading {
        //font-family: 'Montserrat'
        //font-style: normal
        font-size: 20px;
        line-height: 131%;
        text-align: center; }

    .hero-container {
        width: 100%;
        //padding-left: 20px
        //padding-right: 20px
        //padding-top: 80px
        margin-top: 20px;
        //margin-bottom: 80px
        padding-bottom: 80px;
        //background-image: url(../assets/images/top-section-background-mobile.png)
        //background-overflow: visible
        //background-position: center bottom
        //background-repeat: no-repeat
        //padding: 0 20px;
        padding: 0 $mobile-padding-left;

        box-sizing: border-box;
        //background-image: url(../assets/images/mobile-hero.jpg)
        //background-image: url(../assets/images/backgrounds/hero-background-mobile.jpg)
        //background-position: bottom
 }        //background-size: cover

    //.hero-container .hero-title
    //  //font-weight: 400
    //  //font-size: 18px
    //  //line-height: 24px

    .hero-container .table {
        //margin-left: 0
        //margin-left: 20px
        //margin-right: 0
        //margin-right: 20px
        max-width: 400px;
        //padding-bottom: 160px
 }        //margin-bottom: 130px

    .hero-container .table .row button {
        width: 100%; }

    //main.home section.top-section
    //    background-position: center center
    //    background-image: url(../assets/images/top-section-background-mobile.png)

    .Hero {
        padding-bottom: 0px;
        padding-top: 20px; }

    main > section {
        padding: 0px 0px 20px 0px; }

    main.home section.top-section {
        padding: 0px; }

    //main .form > div
    //    padding: 0px 20px

    //main.home > section
    //    padding: 45px 50px

    //main > section
    //  //padding: 0px 8px

    .home button.gold {
        background-color: var(--color-gold);
        border-radius: 50px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center; } }



// Mobile view - small screens 400 pixels or less.

@media only screen and (max-width: 360px) {

    header .header-content.header-right .mobile-nav-button {
        padding: 0px 8px; } }



// Tablet and Desktop view - 480px or more

@media only screen and (min-width: $mobile-max) {

    //#google-maps-wrapper
    //    visibility: visible
    //    flex-basis: 50%
    //  //height: 50vh
 }    //    height: calc(100vh - 200px)


// Desktop view - 1024px or more

@media only screen and (min-width: $desktop-min) {

    .cart-page .cart-main.flex-container .title {
        font-size: 15px; }

    #what-is-realsplit-container #sample-past-deals div.table div.cell figure {
        padding: 25px 50px; }

    header .header-container-desktop .logo-container {
        padding-left: 30px; }

    header .header-container-desktop .header-right {
        padding-right: 40px; }

    //main.edit-profile section.form form .flex-container
    main.edit-profile section.form .flex-container {
        padding: $desktop-padding-top $desktop-padding-left; }

    #what-is-realsplit-container, #latest-arrivals-wrapper, #start-investing-today-wrapper, #team-wrapper, #item-wrapper, .edit-profile .form .max-width, .property-information .max-width, main > section > div.container.max-width {
        padding-left: $padding-left;
        padding-right: $padding-left; }

    body {
        //font-size: 20px
        font-size: $desktop-font-size; }

    //#google-maps-wrapper
    //    display: block
    //    height: calc(100vh - 200px)

    .responsive-container {
        border: blue 2px solid;
 }        //.max-width: $desktop-min

    main.home section.top-section {
        background-position: center center;
        //background-image: url(../assets/images/top-section-background.png)
        //background-image: url(../assets/images/hero-image.jpg)
        background-image: url(../assets/images/deals/Hillcrest/Hillcrest-main.jpg);
        height: 100vh; }

    main.home section.top-section div.Hero {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center; }

    .hero-heading {
        //font-family: 'Montserrat'
        //font-style: normal
        //font-weight: 700
        font-size: 64px;
        //line-height: 131%
        //text-transform: uppercase
        //color: #FFFFFF
 }        //padding: 10px 0px

    //.hero-container
    //  //max-width: 48%
    //.hero-container .table
    //  //margin-left: 80px
    //  //max-width: 400px

    .Hero {
 }        //padding-bottom: 40px;

    body main.search {
        padding-top: $paddingTop; }

    main > section {
        padding: 0px 0px 2px 0px; }

    main.search > section {
        padding-top: 0px;
        padding-bottom: 0px; }

    .filter-section {
        //flex-direction: column
        //padding-left: 15px
        //padding-right: 15px
        padding-top: 20px;
        padding-bottom: 20px; }

    main.item-main section.property-information {
        background: var(--color-gray-100, #F8F9FA); }

    div:where(.swal2-container) .swal2-popup h2:where(.swal2-title) {
        //font-size: 18px
        //font-size: $tablet-font-size-h2
        font-size: $desktop-dialog-font-size-h2; } }


// Tablet - between 480px and 1024px

@media only screen and (min-width: $mobile-max) and (max-width: $tablet-max) {

    .edit-profile .form .heading {
        padding-left: 20px; }

    .edit-profile .form .subtitle {
        padding-left: 20px;
        padding-right: 20px; }

    #what-is-realsplit-container, #latest-arrivals-wrapper, #start-investing-today-wrapper, #team-wrapper, #item-wrapper, .edit-profile .form .max-width, .property-information .max-width, main > section > div.container.max-width {
        //padding-left: $padding-left
        //padding-right: $padding-left
        //padding-left: 0px
        //padding-right: 0px
        padding-left: $tablet-padding-left;
        padding-right: $tablet-padding-left; }

    main.edit-profile section.form form .flex-container {
        margin: 45px 0px; }

    footer div.container > div {
        width: 100%;
        flex-basis: 100vw; }

    footer div.container div.footer-links {
        padding-left: 80px;
        padding-right: 80px; }

    footer div.max-width div.social-icons img {
        padding-left: 20px;
        padding-right: 20px; }

    //#google-maps-wrapper
    //    display: block
    //    height: calc(100vh - 200px)

    #what-is-realsplit-container, #latest-arrivals-wrapper {
        padding-left: 15px;
        padding-right: 15px; }

    .responsive-container {
        border: green 2px solid;
        //.min-width: $tablet-min
 }        //.max-width: $tablet-max

    .hero-heading {
        font-size: 44px; }

    //.hero-container .table
    //  //margin-left: 80px
    //  //max-width: 400px
    //  //margin-right: 80px

    .hero-container {
        //max-width: 48%
        //padding: 0 20px
        padding: 0 $tablet-padding-left;
 }        //background-image: url(../assets/images/top-section-background-mobile.png)

    .hero-container .hero-title {
        font-weight: 500;
        font-size: 36px;
 }        //line-height: 30px

    .filter-section {
        //flex-direction: column
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px; }

    //.filter-section div.title
    //    flex-basis: 50%
    //    text-align: left
    //  //margin-bottom: 18px

    //.filter-section div.search
    //    margin-top: 18px
    //    margin-bottom: 18px

    //.filter-section div#select-property-type
    //    margin-top: 18px

    main.home section.top-section {
        background-position: center center;
        //background-image: url(../assets/images/top-section-background.png)
        //background-image: url(../assets/images/hero-image.jpg)
        background-image: url(../assets/images/deals/Hillcrest/Hillcrest-main.jpg);
        height: 100vh;
 }        //margin-top: 80px

    body main.search {
        //padding-top: 174px
        padding-top: 110px; }

    main > section {
        padding: 0px 0px 20px 0px; }

    main.home section.top-section {
        padding: 0px; }

    //main .form > div
    //    padding: 0px 40px

    //main.home > section
    //    padding: 45px 50px

    main section div.max-width h1 {
        font-size: $tablet-font-size-h1; }

    main section div.max-width h2 {
        font-size: $tablet-font-size-h2; }

    div:where(.swal2-container) .swal2-popup h2:where(.swal2-title) {
        //font-size: 18px
        //font-size: $tablet-font-size-h2
        font-size: $mobile-dialog-font-size-h2; } }

//@media only screen and (min-width: "688px")
//    body main.search
//        padding-top: 174px

//@media only screen and (min-width: "1044px")
//    body main.search
//        padding-top: 110px

//@media only screen and (min-width: $tablet-min)
//    main.home section.top-section
//        background-position: center center
//        background-image: url(../assets/images/top-section-background.png)
//        background-size: contain

