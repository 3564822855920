@import "../../styles/helpers";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');

.header {
    width: 100%;
    margin: auto; }

.header {
    > div {
        //width: 100%
        margin: auto;
        //max-width: $max-width
        //max-width: 1130px
        max-width: $maxWidth;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        //padding: 20px 0px
        @include t {
            justify-content: space-around; }
        @include s {
            justify-content: space-around; } }
    .headerContainerMobile {
        display: none; } }

@media (orientation: portrait) {
    .header {
        > div {
            justify-content: space-evenly; } } }

@media only screen and (max-width: 687px) {
    .header {
        .headerContainerDesktop {
            display: none; }
        .headerContainerMobile {
            display: block;
            padding: 0;
            .nav {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 30px 30px;
 } } } }                //height: 106px


//.header
//    > div
//        nav
//            padding: 20px 0px

//.header
//    > div
//        > div
//            padding: 20px 0px


.nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //width: 100%
    > div {
        font-family: 'Poppins';
        //margin-left: 20px
        margin: auto 10px;
        //color: #fff
        text-decoration: none;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px; } }

.nav {
    a {
        text-decoration: none;
        color: #FFF;
        font-family: 'Source Sans 3';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; } }

.header {
    button {
        //font-family: Poppins
        font-family: 'Source Sans 3';
        color: #fff;
        text-decoration: none;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        //background-color: rgba(0, 0, 255, 1)
        background-color: var(--color-gray-500);
        @include s {
            border: none !important; } } }

.loginModal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 48px;
    background: rgba(23, 27, 30, 0.9);
    overflow: auto;
    z-index: 99;
    align-items: center;
    justify-content: center;

    .loginModalContent {
        width: 448px;
        height: 456px;
        background: $white;
        padding: 40px;
        min-height: 200px;
        border-radius: 10px;
        text-align: center;
        position: relative;
        @include m {
            width: 100%;
            padding: 20px;
            min-height: 165px; }
        h2 {
            color: #000;
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px; }
        button {
            &:disabled {
                background: #ddd; } }

        .loginModalClose {
            position: absolute;
            top: 32px;
            right: 8%;
            cursor: pointer;
            height: 40px;
            width: 40px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px;
            gap: 10px;
            width: 40px;
            height: 40px;
            border: 2px solid #E6E8EC;
            border-radius: 48px;
            color: black;
            font-size: 20px; }
        .socialBtn {
            width: 200px;
            color: #000;
            margin: 10px auto;
            text-align: center;
            border: 1px solid #ddd;
            border-radius: 30px;
            padding: 5px;
            display: flex;
            align-items: center;
            font-size: 16px;
            justify-content: flex-start;
            font-weight: 600;
            img {
                width: 35px;
                margin-right: 20px; } }
        .loginBtn {
            background-color: $newyello;
            width: 200px;
            &:hover {
                background-color: $newblack; } }
        .formGroup {
            width: 100%;
            justify-content: flex-end;
            display: flex;
            align-items: center;
            flex-direction: column;
            h2 {
              margin-bottom: 30px; }
            height: 100%;
            label {
                font-size: 13px;
                color: #555;
                display: block;
                text-align: left;
                margin-bottom: 5px;
                &.error {
                    color: #f00; } }

            .formInput {
                width: 100%;
                padding: 15px;
                border: 1px solid #ddd;
                border-radius: 6px; } }
        .divided {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0px;
            hr {
                width: 40%;
                border-top: 1px solid #ddd; }
            span {
                color: #555; } } } }

.links {
    color: $newyello;
    display: block;
    text-align: center;
    margin-top: 10px;
    cursor: pointer; }
.LoaderBg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000071;
    z-index: 9999; }

.MuiCircularProgress-colorPrimary {
    color: #ffffff; }
.isLogin {
 }    //margin-left: 150px
.connectBtn {
  background-color: $newyello;
  width: 100%;
  height: 50px;
  border-radius: 90px;
  border: none;
  color: #000;
  margin-top: 10px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); } }
.cancelBtn {
  background-color: white;
  color: black;
  width: 100%;
  height: 50px;
  border-radius: 90px;
  border: none;
  color: #000;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid #E6E8EC;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); } }

.walletIcon {
    width: 80px;
    height: 80px;
    background: #A28A00;
    border-radius: 80px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 32px;


    img {
 } }      // background-color: blue

.navMobileBtn {
    cursor: pointer;
    img {
        width: 32px;
        height: 32px; } }

.right {
    display: flex;
    gap: 15px;
    align-items: center; }
