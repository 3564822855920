@import "../../styles/helpers";

.section {
    padding: 50px 0px 10px 0;
    @include x {
        padding: 120px 0; }
    @include m {
        padding: 50px 12px 10px 12px; } }

.container {
    box-sizing: border-box;
    padding: 0; }

.bg {
    position: relative;
    flex-grow: 1;
    margin-right: 96px;
    @include x {
        margin-right: 64px; }
    @include d {
        margin-right: 32px; }
    @include t {
        margin: 0 0 32px; } }

.details {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 384px;
    @include d {
        width: 336px; }
    @include t {
        width: 100%; } }

.preview {
    position: relative;
    img {
        width: 100%;
        border-radius: 16px;
        max-width: 560px;
        box-shadow: 1px 0px 8px rgba(0, 0, 0, .2); } }

.categories {
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0 0 -8px; }

.category {
    margin: 8px 0 0 8px; }

.options {
    position: absolute;
    top: auto;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%); }

.title {
    margin-right: auto;
    @include body-1;
    @include t {
 } }        // display: none

.wrapper {
    flex-grow: 1;
    width: 100%;
    padding: 20px 15px;
    box-sizing: border-box;
    @include t {
        padding-left: 0; }
    @include s {
        padding-left: 15px; }
    @include m {
        padding: 20px 0px; } }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;

    @include m {
        display: block;
        margin: 0; } }

.card {
    flex: 0 0 calc(33.333% - 32px);
    max-width: calc(33.333% - 32px);
    margin: 32px 16px 0;
    padding: 15px;
    // box-shadow: 0px 32px 32px rgba(31, 47, 70, 0.12)
    border-radius: 25px;
    transition: transform 0.1s;

    &:hover {
        padding: 14px;
        border: 1px solid $green; }
    @include r(1279) {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px); }
    @include m {
        max-width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 32px; } }
    @include dark {
       box-shadow: 0px 0px 14px 0px #00000040;

       background: white; } }

.detailsCheckout {
    width: 100%;
    border: 1px solid #cccccc;
    box-shadow: 0px 0px 3px #cccccc;
    border-radius: 6px;
    margin-top: 20px;
    @include m {
        overflow: scroll;
        >div {
            padding-bottom: 10px;
            border-bottom: 1px solid #b8b8b8; } } }

.singleDetail, .singleDetail1 {
    display: inline-block;
    width: 50%;
    font-size: 14px;
    padding: 5px;
    min-width: 290px;
    @include s {
        width: 100%;
        min-width: 100%;
        word-break: break-all; } }

.singleDetail1 {
    width: 20%;
    min-width: 100px;
    @include s {
        width: 100%;
        min-width: 100%; } }

.pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    list-style: none;
    margin-top: 48px;
    margin-bottom: 24px;

    a {
        padding: 10px;
        border: 2px solid #E6E8EC;
        color: #000;
        font-weight: 500;
        border-radius: 10px;
        cursor: pointer;
        border-radius: 30px;

        &.pagination__link__previous {
            padding: 10px 25px; }

        &.pagination__link__next {
            padding: 10px 25px; } }

    li {
        display: flex;
        align-items: center; } }

.pagination__link {
    font-weight: bold; }

.pagination_page {
    a {
        box-sizing: border-box;
        min-width: 40px;
        height: 40px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px; } }

.pagination__link__active a {
    color: #A28A00;
    border-color: #A28A00;
    cursor: pointer; }

.pagination__link__disabled a {
    color: #ccc;
    border: 2px solid #ccc;
    cursor: context-menu; }

.resallPopupModal {
    // display: flex
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 48px;
    background: rgba(23, 27, 30, 0.9);
    overflow: auto;
    z-index: 999;
    // align-items: center
    // justify-content: center
    @include s {
        padding: 48px 20px; }

    .resallContent {
        width: 600px;
        background: $white;
        padding: 40px;
        min-height: 200px;
        border-radius: 10px;
        text-align: center;
        position: relative;
        margin: auto;
        @include m {
            width: 100%;
            padding: 20px; }
        p {
            color: #000;
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 40px; }
        .resellAmount {
            width: 100%;
            // background: #ddd
            padding: 8px;
            color: #000;
            // font-weight: 600
            font-size: 15px;
            text-align: center;
            display: flex;
            margin: 0px auto;
            border: 1px solid #ddd;
            border-radius: 6px;
            input {
                background: transparent;
                color: #000;
                // font-weight: 600
                font-size: 15px;
                margin-left: 5px;
                width: 100%; }
            .dollarValue {
                width: 70%;
                display: flex;
                align-items: center;
                @include s {
                    width: 55%; } }

            .maticValue {
                width: 30%;
                border-left: 1px solid #dddddd;
                color: #7e7e7e;
                @include s {
                    width: 45%; } } }
        .resellCloseIcon {
            position: absolute;
            top: 5px;
            right: 10px;
            cursor: pointer;
            color: #000000;
            font-size: 20px; }
        .resellBtn {
            width: 180px;
            color: #ffffff;
            background: #EE1F78;
            margin: 10px auto;
            text-align: center;
            border-radius: 30px;
            &:hover {
                color: $white;
                background: $newblack; } }
        .resellItem {
            border: 1px solid #ddd;
            border-radius: 6px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include s {
                flex-wrap: wrap; }
            .resellImg {
                width: 250px;
                height: 120px;
                @include s {
                    margin: auto; } }
            .resellInfo {
                width: 60%;
                padding-left: 10px;
                @include s {
                    width: 100%;
                    padding: 10px; }
                h2 {
                  color: #000;
                  text-align: left;
                  word-break: break-all; }
                p {
                  color: #000;
                  text-align: left;
                  word-break: break-all;
                  font-size: 14px;
                  line-height: 25px; }
                .readMore {
                    color: #F31B4F;
                    cursor: pointer; } } } } }

.resaleLabel {
    display: block;
    color: #000000;
    text-align: left;
    margin: 5px 0px; }

.resaleSelect {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    background: transparent;
    color: #000000;
    font-size: 15px;
    border-radius: 6px; }
.resaleHead {
    margin-bottom: 15px;
    color: #000;
    font-size: 24px; }

.commissionReduction {
    margin: 10px 0px;

    ul {
        border: 1px solid #ddd;
        border-radius: 6px;
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px;
            color: #000;
            &:last-child {
                border-top: 1px solid #ddd;
                span {
                    font-weight: 600; } }
            span {
                &:last-child {
                    font-weight: 600; } } } } }
.resaleRow {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .resaleCol {
        width: 49%;
        @include m {
           width: 100%; } } }
.loaderContent {
    width: 100%;
    position: fixed;
    height: 100vh;
    background: #d5d5d5e8;
    z-index: 9999;
    top: 0;
    left: 0;
    // bottom: 0
    // right: 0
    display: flex;
    align-items: center;
    justify-content: center; }

input[type='number'] {
   &::-webkit-inner-spin-button,&::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; } }
.fractionNumbers {
    width: 100%;
    padding: 12px;
    color: #000;
    font-size: 15px;
    text-align: left;
    display: flex;
    margin: 0px auto;
    border: 1px solid #ddd;
    border-radius: 6px; }

.subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 24px; }

.card {
    box-sizing: border-box; }

.assetContainer {
    width: 25%;
    box-sizing: border-box;
    padding: 12px;
    box-sizing: border-box;

    > div {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        overflow: hidden; }
    @include t {
        width: 50%; }
    @include s {
        width: 100%; }

    img {
        width: 100%;
        //max-width: 250px
        object-fit: contain;
        aspect-ratio: 1;

        @include s {
            margin: auto; } } }

