@import "../../styles/helpers";

//.modalWrapped
.modal {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: showModal .4s forwards;
  z-index: 999;
  background: rgba($neutrals1,.9);
  opacity: 0; }
@keyframes showModal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

//.modal
.modalWrapped {
  //display: flex
  flex-basis: 100%;
  //position: relative
  //background: rgba($neutrals1,.9)
  //background: $neutrals8
  //background: rgba(255, 255, 255, .9)
  width: 100%;
  //height: 100%
  //opacity: 0
  //animation: showModal .4s forwards
  //max-width: 448px
  overflow: auto;
  margin: 30px;
  margin-top: auto;
  margin-bottom: auto;
  //color: white
  //z-index: 9999
  //border-radius: 16px
  //+m
  //  padding: 32px 16px
  //+dark
 }  //  background: rgba($neutrals2,.9)

.outer {
  //position: relative
  //width: 100%
  min-width: 280px;
  max-width: 448px;
  margin: auto;
  padding: 30px;
  background: $neutrals8;
  //background: rgba(255, 255, 255, .9)
  border-radius: 16px;
  //z-index: 2
  //color: white
  //+dark
  //    background: $neutrals1
 }  //    box-shadow: inset 0 0 0 1px $neutrals3

.container {}

.close {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 40px;
  height: 40px;
  background: $neutrals8;
  border: 2px solid $neutrals6;
  border-radius: 50%;
  font-size: 0;
  svg {
    fill: $neutrals2;
    transition: transform .2s; }
  @include dark {
    border-color: $neutrals3;
    background: $neutrals1;
    svg {
      fill: $neutrals8; } }
  &:hover {
    svg {
      transform: rotate(90deg); } } }
