.cart-checkout-page {

    //padding-top: 88px;
    //font-size: 14px;

    .d-flex {
        display: flex;
        padding-bottom: 10px;
    }

    .btn {
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 7px 0px rgba(128, 128, 128, 0.236);
      }
    }

    .cart-item-right {
      display: flex;
      margin-left: auto;
      margin-right: 12px;

      span {
        font-size: 20px;
        padding-right: 36px;
        white-space: nowrap;
      }
    }

    .container {

        //padding: 42px 0;

        .back-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            //max-width: 162px;
            gap: 10px;
            //padding: 8px 20px 8px 16px;
            padding: 4px 14px 4px 8px;
            border-radius: 35px;
            //border: 2px solid #E6E8EC;
            border: 2px solid #0104F9;
            color: #0104F9;
            cursor: pointer;
            margin-bottom: 20px;

            &:hover {
                box-shadow: 0 0 7px 0px rgba(128, 128, 128, 0.236);
            }

            img {
                padding-bottom: 1px;
                vertical-align: top;
            }

            p {
                font-size: 17px;
                font-weight: 700;
                line-height: 25px;
                margin-bottom: -1px;
            }
      }

      .cart-title {
        font-size: 48px;
        font-weight: 500;
      }

      .cart-description {
        color: #777E91;
        margin-bottom: 24px;
      }

        .cart-main {

            display: flex;
            gap: 20px;

            .left {
                flex: 1;
                width: 60%;

                hr {
                    margin-bottom: 24px;
                }

                .checkout-note {
                    margin: 24px 0;
                    font-size: 14px;
                    color: #777E91;
                }

                .checkout-address {
                    font-weight: 600;
                    text-transform: capitalize;
                    margin-bottom: 12px;
                }

                .add-address {
                    display: flex;
                    gap: 10px;
                    margin: 2px 0 16px 0;
                    color: #A28A00;
                    align-items: center;
                    font-weight: 500;
                    cursor: pointer;

                    img {
                      width: 14px;
                      height: 14px;
                    }
                }

                .item-type {
                  font-size: 16px;
                  margin: 12px 0;

                  b {
                    font-weight: 600;
                  }

                  p {
                    color: #919191;
                  }
              }

              .cart-item {
                  display: flex;
                  align-items: center;
                  gap: 15px;
                  //padding: 18px 16px;
                  margin-bottom: 20px;
                  border-radius: 15px;
                  //background-color: #FCFCFD;
                  background-color: var(--color-gray-50);
                  transition: 0.3s;

                  &:hover {
                    box-shadow: 0 0 10px 0px rgba(128, 128, 128, 0.236);
                    background-color: var(--color-gray-100);
                  }

                  .item-img {
                      height: 64px;
                      width: 64px;
                      border-radius: 15px;
                      overflow: hidden;

                      img {
                          width: 100%;
                          height: auto;
                          vertical-align: top;
                          object-fit: contain;
                      }
                  }

                  .item-info {
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      padding: 8px 0px;

                      //.item-name {
                      //  font-size: 16px;
                      //  font-weight: 500;
                      //}

                      .item-price {
                        font-weight: 500;
                      }

                      .item-date {
                        font-size: 12px;
                        color: #777E91;
                      }
                  }

                  .delete-item-btn {
                      //width: 30px;
                      //height: 30px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      justify-self: flex-end;
                      margin-left: auto;
                      cursor: pointer;

                      svg {
                        height: 20px;
                        width: 20px;
                      }

                      &:hover {
                          box-shadow: unset;

                          svg {
                              transition: 0.3s;
                              //fill: #A28A00;
                              fill: blue;
                          }
                      }

                      img {
                        height: 30px;
                        width: 30px;
                      }
                  }
              }
          }

          .right {
              width: 40%;
              max-width: 363px;

              .cart-info {
                  padding: 10px 20px;
                  background-color: #FCFCFD;
                  background-color: var(--color-gray-50);
                  border-radius: 15px;
                  display: flex;
                  flex-direction: column;
                  gap: 15px;
                  // position: sticky;
                  // top: 100px;

                  .summary-text {
                    font-size: 24px;
                    font-weight: 600;
                  }

                  .cart-note {
                    color: #777E91;
                  }

                  .order-total {
                    font-size: 20px;
                    display: flex;
                    justify-content: space-between;

                    p {
                      font-weight: 500;
                    }

                    b {
                      font-weight: 600;
                    }
                  }

                  .checkout-btn {
                    color: white;
                    background: #A28A00;
                  }

                  .checkout-btn:disabled {
                    background: #777E91;
                    cursor: not-allowed;
                  }

                  .checkout-btn,
                  .send-gift-btn {
                    font-size: 16px;
                    height: 48px;
                    border-radius: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  .send-gift-btn {
                    gap: 15px;
                    border: 2px solid #E6E8EC;
                    margin-top: 12px;

                    img {
                      width: 20px;
                      height: 20px;
                      vertical-align: top;
                    }
                  }
                }
            }
        }
    }

    .checkout-input {

        margin-bottom: 24px;
        max-width: 640px;

        .label {
          font-size: 12px;
          text-transform: uppercase;
          color: #B1B5C4;
          margin-bottom: 8px;

          b {
            font-weight: 600;
          }
        }

        textarea {
          font-size: 14px;
          width: 100%;
          outline: none;
          padding: 12px 16px;
          color: #777E91;
          box-sizing: border-box;
          border: 2px solid #E6E8EC;
          border-radius: 12px;

          &::placeholder {
            color: #777E90;
          }

          &:focus {
            box-shadow: 0 0 7px 0px rgba(128, 128, 128, 0.236);
          }
        }
    }
}

@media only screen and (max-width: 1028px) {
    .cart-checkout-page {
        .container {
            //padding-top: 118px;
            padding: 0 16px;
            box-sizing: border-box;
        }
    }
}

@media only screen and (max-width: 768px) {

    .cart-checkout-page {

        //padding-top: 208px;

        .container {

            max-width: 100%;
            //padding: 24px 16px;
            box-sizing: border-box;

            .cart-main {
                gap: 0;
                flex-direction: column;

                .right,
                .left {
                  width: 100%;
                  flex: 1;
                }

                .right {
                    max-width: unset;

                    .cart-info {
                        .order-total {
                          font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .cart-checkout-page {

        //padding-top: 108px;

        .cart-item-right {
            display: flex;
            margin-left: auto;
            margin-right: 12px;

            span {
                font-size: 20px;
                padding-right: 16px;
            }
        }
    }
}
