/* @font-face {
  font-family: "Neue Haas Grotesk Display Pro 45 Light";
  font-style: normal;
  font-weight: normal;
  src: local("Neue Haas Grotesk Display Pro 45 Light"),
    url("fonts/NeueHaasDisplayLight.woff") format("woff");
}
@font-face {
  font-family: "Neue Haas Grotesk Display Pro 75 Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Neue Haas Grotesk Display Pro 75 Bold"),
    url("fonts/NeueHaasDisplayBold.woff") format("woff");
}
@font-face {
  font-family: "Neue Haas Grotesk Display Pro 65 Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Neue Haas Grotesk Display Pro 65 Medium"),
    url("fonts/NeueHaasDisplayMediu.woff") format("woff");
} */
/* body {
  font-size: 18px;
  background-color: #000;
  padding: 0;
  margin: 0;
  color: #fff;
} */

.logo img {
  width: 560px;
  margin-top: 4%;
}

/* Dermot removed this global selector
a {
  color: #F31B4F;
}
*/

.content {
  /* font-family: "Neue Haas Grotesk Display Pro 65 Medium"; */
  font-size: 21px;
  color: #fff;
  text-align: center;
  margin-top: 4%;
  width: 80%;
}

.content-TermsCondition {
  font-size: 21px;
  color: #fff;
  text-align: center;
  margin-top: 4% !important;
  margin: 0 auto;
}

.content-top {
  margin: 2% 0 0 0;
}
.first-drop {
  height: 44.59px;
  width: 301.97px;
  color: #F31B4F;
  font-size: 37.48px;
  letter-spacing: 0;
  line-height: 44.59px;
  margin-left: 48%;
  margin-top: -11%;
}

sup {
  font-size: 24px !important;
}
.content-bottom {
  /* font-family: "Neue Haas Grotesk Display Pro 65 Medium"; */
  width: 100%;
  margin-left: 10%;
  float: left;
  font-weight: normal;
}

.content-bottom-content {
  width: 80%;
  margin: 0 auto;
  font-weight: normal;
}

.content-footer img {
  width: 137px;
}
.content-footer {
  padding-right: 10%;
  margin-top: 2%;
}

.policy {
  text-align: center;
  width: 80%;
  margin-top: 1%;
}
.policyheading {
  text-align: left;
  margin-top: 5%;
  text-decoration: underline;
  font-weight: bold;
}
.points {
  text-align: justify;
}
/* td {
  padding: 12px 0;
} */
.odd p {
  text-align: left;
}