@import "../../../styles/helpers";


.notification_button {
    button {
        border: none !important;
 } }        //margin-inline: 20px

.user {
    position: relative;
    @include m {
        position: static; } }

.head {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px 16px 4px 4px;
    border-radius: 20px;
    box-shadow: inset 0 0 0 2px $neutrals6;
    @include button-2;
    cursor: pointer;
    transition: box-shadow .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals1; }
    @include m {
        padding: 2px 8px 2px 2px;
        border-radius: 20px; }
    @include vs {
        padding: 2px; }
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3; }
    &:hover {
        box-shadow: inset 0 0 0 2px $pink;
        @include m {
            box-shadow: none; } } }

.avatar {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%; } }

.wallet {
    margin-left: 5px;
    @include m {
        // display: none
        font-size: 11px; } }

.rateHide {
    display: block;
    @include vs {
        display: none; } }

.currency {}

.body {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    width: 256px;
    margin-left: -128px;
    padding: 16px;
    border-radius: 12px;
    background: $neutrals8!important;
    opacity: 0;
    transform: scale(.9);
    transform-origin: 50% 50px;
    will-change: transform;
    animation: showUser .4s forwards;
    @include t {
        left: auto;
        right: 0; }
    @include m {
        left: 16px;
        right: 16px;
        top: calc(100% + 16px);
        width: auto;
        margin: 0; }
    @include s {
        left: 0;
        right: 0;
        top: calc(100% + 16px);
        width: 80%;
        margin: auto; }
    &:before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 32px;
        height: 13px;
        transform: translateX(-50%);
        //background: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A") no-repeat 100% 50% / 100% auto
        @include m {
            display: none; } }
    @include dark {
        background: #141414;
        &:before {
 } } }            //background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%2323262F'/%3E%3C/svg%3E%0A")

.subBody {
    // position: absolute
    // top: calc(100% + 20px)
    // left: 50%
    // width: 256px
    // margin-left: -128px
    // padding: 32px 16px 20px
    // box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4)
    position: relative;
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    background: $neutrals8;
    opacity: 0;
    transform: scale(.9);
    transform-origin: 50% 50px;
    will-change: transform;
    animation: showUser .4s forwards;
    @include t {
        left: auto;
        right: 0; }
    @include m {
        left: 16px;
        right: 16px;
        top: calc(100% + 16px);
        width: auto;
        margin: 0; }
    &:before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 32px;
        height: 13px;
        transform: translateX(-50%);
        background: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A") no-repeat 100% 50% / 100% auto;
        @include m {
            display: none; } }
    @include dark {
        // background: $neutrals2
        // background: #141414
        background: none;
        border-radius: 0px 0px 10px 10px;
        &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%2323262F'/%3E%3C/svg%3E%0A"); } }

    @include a {
        position: static;
        top: 0;
        left: 0;
        transform: none;
        z-index: 9999; } }

.subBody {
    // left: 150%
    left: 0px;
    top: 0px;
    &:before {
        display: none; }
    // +dark
 }    //     background: #141414

.searchBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 5px;
    cursor: pointer; }

@keyframes showUser {
    0% {
        opacity: 0;
        transform: scale(.9); }
    100% {
        opacity: 1;
        transform: scale(1); } }

.name {
    @include body-bold-1; }

.code {
    display: flex;
    align-items: center;
    margin-bottom: 8px; }

.number {
    font-weight: 500;
    color: $neutrals4; }

.copy {
    margin-left: 8px;
    svg {
        fill: $blue;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: darken($blue, 10); } } }

.wrap {
    margin-bottom: 7px;
    padding: 8px;
    border-radius: 16px;
    box-shadow: 0px 24px 24px -8px rgba(15, 15, 15, 0.2); }

.line {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }

.preview {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%; } }

.info {
    @include caption-2;
    color: $neutrals4; }

.price {
    @include body-bold-1; }

.button {
    width: 100%;
    height: 32px;
    padding: 0 10px;
    font-size: 14px; }

.item {
    display: flex;
    align-items: center;
    padding: 17px 0 12px;
    @include button-2;
    color: $neutrals4;
    &:not(:last-child) {
        border-bottom: 1px solid $neutrals6;
        @include dark {
            border-color: $neutrals3; } }
    &[href] {
        &:hover {
            color: $blue;
            svg {
                fill: $blue; } } } }

.icon {
    flex-shrink: 0;
    width: 20px;
    margin-right: 8px;
    svg {
        fill: $neutrals4;
        transition: fill .2s; } }

.text {
    margin: auto;
    @include dark {
        color: $neutrals5; } }

.textLink, .activeLink {
    color: #1a1a1a;
    display: flex;
    justify-content: space-between;
    //+dark
    //    color: #dfdfdf
    &:hover {
        background: #0E69FF;
        color: #fff;
        padding: 0px 10px;
        border-radius: 5px; } }

.menuScroll {
    max-height: 400px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; } }

.activeLink {
    color: #fff;
    background: #0E69FF;
    padding: 0px 10px 0px 10px;
    border-radius: 5px;
    position: relative; }
.dispBlock {
    display: none;
    @include vs {
        display: block;
        text-align: center;
        border-bottom: 1px solid #353945;
        padding: 10px 0 12px;
        font-size: 14px;
        line-height: 1.14286;
        color: #B1B5C3; } }

.error {
    color: white; }
.iconDropDown {
    margin-left: 10px; }
