@import "../../styles/helpers";

.container {
    padding: 20px 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; }

.title {
    flex-grow: 3; }

.form {
    position: relative;
    width: 40%;
    min-width: 300px;
    div {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative; } }

.input {
    width: 60%;
    min-width: 100px;
    height: 42px;
    padding: 0 48px 0 14px;
    border-radius: 5px;
    background: none;
    border: 2px solid $neutrals5;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    &:focus {
        border-color: $neutrals3; }
    @include placeholder {
        color: $neutrals4; } }

.btn {
    position: absolute;
    top: 5px;
    right: 8px;
    bottom: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: $neutrals8;
    transition: background .2s;
    svg {
        fill: $neutrals8; }
    &:hover {
        background: darken($blue, 10); } }

.priceField {
    font-size: 15px; }
