body {

    .modal-overlay {
        top: 0;
        left: 0;
        margin-left: 0;
        z-index: 4;
    }

  .mobile-user-header-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    max-width: 266px;
    background-color: white;
    z-index: 5;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    transform: translateX(100%);
    transition: .4s;
  }

  .mobile-user-header-container.show {
    transform: translateX(0%);
  }

  /*
  .close {
    margin-left: auto;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 103;
    margin-top: 20px;
    margin-right: 20px;
  }
  */

  .mobile-user-header-content {
    padding: 20px;
    padding-top: 10px;
    box-sizing: border-box;

    .input-search {
      display: flex;
      position: relative;

      /*
      .search-btn {
        position: absolute;
        right: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 10px;

        svg {
          position: unset;
          fill: #777E90;
        }
      }
      */
      
    }

    input {
      width: 100%;
      margin-top: 60px;
      margin-bottom: 12px;
      border: solid 2px;
      border-radius: 8px;
      padding: 10px 14px;
      border-color: #E6E8EC;

      &::placeholder {
        color: #777E90;
        ;
      }
    }

    .mobile-user-header-item {
      color: #777E90;
      font-size: 14px;
      padding: 12px 0;
      display: flex;
      align-items: center;
      max-width: 120px;
      justify-content: space-between;
      transition: 0.3s;
      cursor: pointer;
    }

    .dropdown-btn {
      &.active {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown {
      max-height: 0;
      overflow: hidden;
      transition: 0.4s;

      &.active {
        max-height: 400px;
      }

      .mobile-user-header-item {
        font-weight: 400;
        padding: 10px;
        max-width: unset;
      }
    }

    .connect-wallet {
      margin-top: 12px;
      background-color: #a28a00;
    }
  }
}

@media only screen and (max-width: 480px) {
  .mobile-user-header-container {
    overflow-y: auto;
    height: 100%;
  }

  .mobile-user-header-content {
    // overflow-y: auto;
    // height: 100%;
  }
}
