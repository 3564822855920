@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');

//[class^="button"]
//    // display:
//    justify-content: center
//    align-items: center
//    // height: 48px
//    padding: 12px 24px
//    margin: 15px
//    background: $newyello
//    display: flex
//    flex-direction: row
//    border-radius: 24px
//    // border: 1px solid
//    border-image-slice: 1 !important
//    border-width: 2px
//    transition: 0.6s
//    cursor: pointer
//    +button-1
//    text-align: center
//    color: #fff
//    transition: all .2s
//    &:hover
//        color: #ffffff
//        background: #000000
//        // background: linear-gradient(to top, #326eed, #e1225d)
//    &.disabled
//        opacity: .5
//        pointer-events: none
//    &.done
//        background: $neutrals5
//    &.loading
//        background: darken($blue, 15)
//        font-size: 0
//    &.error
//        box-shadow: 0 0 0 2px $neutrals6 inset
//        background: none
//        color: $pink
//        +dark-common
//            box-shadow: 0 0 0 2px $neutrals4 inset
//    svg
//        fill: $neutrals8
//    &:not([class^="button-circle"])
//        svg
//            &:first-child
//                margin-right: 15px
//            &:last-child
//                margin-left: 15px

// .button-stroke
//     background: none
//     // box-shadow: 0 0 0 2px $secondary inset
//     border: 2px solid
//     border-image-slice: 1 !important
//     border-width: 2px
//     transition: 0.6s
//     border-image-source: linear-gradient(to top, #326eed, #e1225d)
//     color: $neutrals2
//     svg
//         fill: $neutrals2
//         transition: fill .2s
//     &:hover
//         // background: $secondary
//         background: linear-gradient(to top, #326eed, #e1225d)
//         border: 2px solid
//         border-image-slice: 1 !important
//         border-width: 2px
//         transition: 0.6s
//         border-image-source: linear-gradient(to top, #326eed, #e1225d)
//         // box-shadow: 0 0 0 2px $secondary inset
//         color: $neutrals8
//         svg
//             fill: $neutrals8
//     +dark-common
//         // box-shadow: 0 0 0 2px $secondary inset
//         color: $neutrals8
//         svg
//             fill: $neutrals8
//         &:hover
//             // box-shadow: 0 0 0 2px $secondary inset
//  style={{borderRadius:25,border:"1px solid",fontFamily:',fontWeight:400,fontSize:10}}
.button-wallet {
    border: 1px solid;
    border-radius: 25px !important;
    font-weight: 400;
    font-size: 10px !important;
    color: $newyello;
    &:hover {
        border: 1px solid $newyello;
        background-color: $newyello;
        color: $newblack; } }

.button-green {
    background: #377E40;
    border-radius: 25px;
    border: none;
    &:hover {
        background: #204f26; } }

.buttonBlack {
    border: 1px solid #000000;
    border-radius: 32px;
    background: none;
    color: #000000; }

.button-pink {
    background: $pink;
    &:hover {
        background: darken($pink, 12); } }

.button-small {
    height: 40px;
    border-radius: 35px;
    padding: 0 16px;
    font-size: 14px; }

.button-circle-stroke {
    flex: 0 0 48px;
    width: 48px;
    height: 48px;
    padding: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 2px $neutrals6 inset;
    background: transparent;
    transition: all .2s;
    svg {
            fill: $neutrals4;
            transition: fill .2s; }
    &:hover {
            background: $blue;
            box-shadow: 0 0 0 2px $blue inset;
            svg {
                    fill: $neutrals8; } }
    @include dark-common {
        box-shadow: 0 0 0 2px $neutrals3 inset;
        &:hover {
            background: $neutrals3; } } }

.button-circle-stroke.button-small {
    flex: 0 0 40px;
    width: 40px;
    height: 40px; }



