@import "../../styles/helpers";

.section {
    padding-top: 100px;
    padding-bottom: 100px; }


.title {
    font-size: 48px;
    font-weight: 500; }

.subtitle {
    color: $neutrals4; }

.noti_wrapper {
    margin-top: 40px; }

.marked_read_btn {
    background: $gold;
    padding: 20px 50px;
    border-radius: 5px;
    margin-top: 15px;

    &:hover {
        filter: brightness(85%);
        transition: all .2s; } }

